<template>
  <div class="container-offer">

    <div v-if="orderItems.length">
      <div v-if="order && orderItems">
        <CRow class="grid">
          <h1 class="text-center mb-4 fz-24">
            Формирование коммерческого предложения
          </h1>
        </CRow>

        <CRow class="mt-2 mb-4">
          <CCol sm="7">
            <h5 class="card-title mb-0">
              <span class="text-step">1.</span> Проверьте, при необходимости,
              дополните персональные данные заказчика
            </h5>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="4">
            <CInput label="Имя для обращения в КП (н-р Вера Анатольевна)" v-model="order.client_name_offer"
              @input="requestOrderUpdate">
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="4">
            <CInput label="Адресат для КП (раздел «Кому» — Ивановой Вере Анатольевне)"
              v-model="order.client_name_dative" @input="requestOrderUpdate">
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="4">
            <CInput label="Телефон заказчика для КП (раздел «Кому»)" v-model="order.client_phone"
              @input="requestOrderUpdate">
            </CInput>
          </CCol>
        </CRow>
        <CRow class="mb-4">
          <CCol sm="4">
            <CInput label="Почта заказчика для КП (раздел «Кому»)" v-model="order.client_email"
              @input="requestOrderUpdate">
            </CInput>
          </CCol>
        </CRow>
        <CRow class="d-block mt-5">
          <h5 class="mb-3">
            <span class="text-step">2.</span> Формирование 3D визуализации
            расстановки оборудования*
          </h5>
          <img v-if="order.render" :src="order.render" alt="Order render" class="mb-3"
            style="width: 50%; border: 1px #d8dbe0 solid; border-radius: 0.25rem" />
        </CRow>
        <CRow>
          <CButton color="dark" @click="handleAddRender">{{ order.render ? "Сгенерировать заново" : "Сгенерировать" }}
          </CButton>
        </CRow>
 

        <CRow class="d-block mt-5 page-breaker" v-if="hasUniqueItems">
          <h5 class="mb-3">
            <span class="text-step">3.</span> Формирование 3D-визуализаций
            индивидуальных комплексов*
          </h5>
        </CRow>
        <CRow>
          <CCol style="max-width: 30%" v-for="(orderItem, index) in getUniqueOrderItems().filter(
            (orderItem) => orderItem.isUnique
          )" :key="index">
            <CRow class="mb-1"> Комплекс — {{ orderItem.code }} </CRow>
            <CRow>

              <img v-if="orderItem.render" :src="orderItem.render" alt="Order item render" class="mb-3"
                style="width: 75%; border: 1px #d8dbe0 solid; border-radius: 0.25rem" />

            </CRow>
            <CRow>
              <CButton color="dark" @click="handleAddOrderItemRender(orderItem)">{{
                  orderItem.render ? "Сгенерировать заново" : "Сгенерировать"
              }}
              </CButton>
            </CRow>
          </CCol>
        </CRow>

        <CRow class="d-block mt-5">
          <h5 class="mb-3">
            <span class="text-step">4.</span> Формирование 2D визуализации плана
            расстановки оборудования*
          </h5>

          <img v-if="order.scheme" :src="order.scheme" alt="Order scheme" class="mb-3"
            style="width: 50%; border: 1px #d8dbe0 solid; border-radius: 0.25rem" />
        </CRow>
        <CRow>
          <CButton class="mr-30" color="dark" @click="$emit('addScheme', orderItemsCodesVisible)">{{ order.scheme ? "Сгенерировать заново" : "Сгенерировать"
          }}
          </CButton>
                  <div role="group" >
                    <span>Показывать коды комплексов</span>
                    <CSwitch class="ml-3" color="primary" style="vertical-align: bottom"
                      :checked.sync="orderItemsCodesVisible"
                       />
                  </div>
        </CRow>
      </div>
      <CRow class="d-block mt-5">
        <ModalPlanSnapshot v-if="modalPlanSnapshotVisible" ref="modalPlanSnapshot" :order="order"
          :orderItems="orderItems" :orderPlatforms="orderPlatforms" :equipmentItems="equipmentItems"
          :equipmentParts="equipmentParts" :colors="colors" :coverings="coverings" :foundations="foundations"
          :mountTypes="mountTypes" :sputterings="sputterings" :user="user"
          :orderItemId="orderItem ? orderItem.id : null" @complete="handleRenderComplete" />
        <br />

        <h5 class="mb-3">
          <span class="text-step">5.</span> Формирование готового коммерческого
          предложения
        </h5>

        <CButton color="success" @click="downloadDocx">Выгрузить КП в Word
        </CButton>
      </CRow>

      <CRow class="d-block mt-5">

        <h5 class="mb-3">
          <span class="text-step">6.</span> Предпросмотр готового коммерческого
          предложения
        </h5>
        <CRow class="d-block mt-3" v-if="order && order.pdf">
          <iframe :src="order.pdf" width="100%" height="600px"></iframe>
        </CRow>

        <CButton v-if="order && !order.pdf" color="dark" @click="showPreview" target="_blank">Показать КП
        </CButton>
        <CButton v-if="order && order.pdf" color="dark" @click="showPreview" target="_blank">Обновить превью КП
        </CButton>
      </CRow>
      <CRow  class="mt-3">
      <p>* Если нужно просто сохранить полученное изображение на компьютер: <br> 
          — нажмите на него правой клавишей мыши и в выпавшем меню выберите «Сохранить как».</p>
      </CRow>
    </div>
    <div v-else>
      Добавьте в заказ комплексы в разделе «План», после этого будет доступно
      формирование коммерческого предложения.
    </div>
  </div>
</template>

<style scoped>
.text-step {
  font-size: 18px;
}

CInput label {
  font-size: 18px;
}
</style>

<script>
import ModalPlanSnapshot from "./ModalPlanSnapshot";
import config from "config";
import { debounce } from "lodash";
import { mapActions } from "vuex";
import { ordersService } from '../_services'

export default {
  name: "Offer",
  props: {
    user: Object,
    order: Object,
    orderItems: Array,
    orderPlatforms: Array,
    equipmentItems: Array,
    equipmentParts: Array,
    colors: Array,
    coverings: Array,
    foundations: Array,
    mountTypes: Array,
    sputterings: Array,
  },
  emits: ["addScheme", "renderComplete", "renderOrderItemComplete"],
  components: {
    ModalPlanSnapshot,
  },

  data() {
    return {
      modalPlanSnapshotVisible: false,
      orderItem: null,
      apiUrl: config.apiUrl,
      orderItemsCodesVisible: true,
    };
  },
  computed: {
    hasUniqueItems() {
      for (const orderItem of this.getUniqueOrderItems()) {
        if (orderItem.isUnique) {
          return true;
        }
      }
      return false;
    },
    pdfUrl() {
      return `${this.apiUrl}/media/Kenguru.PRO_offer-${this.order.id}.pdf`
    }
  },
  methods: {
    ...mapActions(["orders/update"]),
    getColorById(id) {
      for (const color of this.colors) {
        if (color.id === id) {
          return color;
        }
      }
      return null;
    },

    getOrderColor(tag) {
      const colorPropertyName = `${tag}_color`;
      return this.getColorById(this.order[colorPropertyName]);
    },

    getOrderBarColor() {
      return this.getOrderColor("bar");
    },

    getOrderCapColor() {
      return this.getOrderColor("cap");
    },

    getOrderClampColor() {
      return this.getOrderColor("clamp");
    },

    getOrderBarColorRALCode() {
      const color = this.getOrderBarColor();
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    getOrderCapColorRALCode() {
      const color = this.getOrderCapColor();
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    getOrderClampColorRALCode() {
      const color = this.getOrderClampColor();
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    calculatePlatformsArea() {
      let platformsArea = 0.0;
      for (const orderPlatform of this.orderPlatforms) {
        platformsArea += parseFloat(orderPlatform.area);
      }
      return platformsArea;
    },
    getOrderItemRender(orderItem) {
      for (const equipmentItem of this.equipmentItems) {
        if (equipmentItem.id === orderItem.item) {
          return equipmentItem.model_preview;
        }
      }
    },

    makeOrderItemPartFlags(orderItem) {
      orderItem.isUnique = false;
      orderItem.hasInactiveParts = false;
      orderItem.hasAdditionalParts = false;
      orderItem.inactiveParts = [];
      orderItem.additionalParts = [];
      for (const orderItemEquipmentPart of orderItem.equipment_parts) {
        if (
          !orderItemEquipmentPart.active &&
          orderItemEquipmentPart.type === "own"
        ) {
          orderItem.hasInactiveParts = true;
          orderItem.inactiveParts.push(orderItemEquipmentPart);
        }
        if (orderItemEquipmentPart.type === "additional") {
          orderItem.hasAdditionalParts = true;
          orderItem.additionalParts.push(orderItemEquipmentPart);
        }
      }
      orderItem.isUnique =
        orderItem.hasInactiveParts || orderItem.hasAdditionalParts;
    },

    getUniqueOrderItems() {
      const uniqueOrderItems = [];
      const addedOrderItemCodes = [];
      for (const orderItem of this.orderItems) {
        this.makeOrderItemPartFlags(orderItem);
        if (
          !addedOrderItemCodes.includes(orderItem.equipmentItem.code) ||
          orderItem.isUnique
        ) {
          if (!orderItem.isUnique) {
            addedOrderItemCodes.push(orderItem.equipmentItem.code);
          }
          uniqueOrderItems.push(orderItem);
        }
      }
      return uniqueOrderItems;
    },

    handleAddRender() {
      this.modalPlanSnapshotVisible = true;
      this.orderItem = null;
      this.$nextTick(() => {
        this.$refs.modalPlanSnapshot.orderItemId = null;
        this.$refs.modalPlanSnapshot.show();
      });
    },

    handleRenderComplete(dataUrl) {
      if (this.orderItem) {
        this.$emit("renderOrderItemComplete", dataUrl, this.orderItem.id);
        this.orderItem.render = dataUrl;
      } else {
        this.$emit("renderComplete", dataUrl);
      }
      this.modalPlanSnapshotVisible = false;
    },

    handleAddOrderItemRender(orderItem) {
      this.modalPlanSnapshotVisible = true;
      this.orderItem = orderItem;
      this.$nextTick(() => {
        this.$refs.modalPlanSnapshot.orderItemId = orderItem.id;
        this.$refs.modalPlanSnapshot.show();
      });
    },

    downloadDocx() {
      window.location = 
      config.apiUrl + `/api/orders/${this.order.id}/get-offer-docx/`;
    },

    showPreview() {
      ordersService.viewOfferPDF(this.order.id).then((data) => {
        this.order.pdf = data.pdf
      })
    },

    requestOrderUpdate: debounce(function () {
      this.updateOrder();
    }, 1500),

    updateOrder() {
      this["orders/update"](this.order);
    },
  },
};
</script>
