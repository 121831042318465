<template>
  <div>
    <CCard :style="[view === 'plan' ? { 'max-width': 'none' } : {}]">
      <CCardHeader>
        <CRow>
          <CCol>
            <CButtonGroup>
              <CButton @click="changeView('plan')" color="success">План
              </CButton>
              <CButton @click="changeView('estimate')" color="info">Детали заказа
              </CButton>
              <CButton @click="changeView('warehouse-order')" color="primary">Требование на склад
              </CButton>
              <CButton @click="changeView('offer')" color="danger">Коммерческое предложение
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow v-if="!loading">
          <CCol>
            <Plan v-if="view === 'plan' && colors.length" ref="plan" :order="order" :orderItems="orderItems"
              :orderPlatforms="orderPlatforms" :equipmentItems="equipmentItems" :equipmentParts="equipmentParts"
              :colors="colors" :coverings="coverings" :foundations="foundations" :mountTypes="mountTypes"
              :sputterings="sputterings" :user="user"></Plan>
            <Estimate v-if="view === 'estimate'" :order="order" :orderItems="orderItems"
              :orderPlatforms="orderPlatforms" :equipmentItems="equipmentItems" :coverings="coverings"
              :foundations="foundations" :mountTypes="mountTypes" :sputterings="sputterings" :user="user" :settings="settings"></Estimate>
            <WarehouseOrder v-if="view === 'warehouse-order'" :order="order" :orderItems="orderItems"
              :orderPlatforms="orderPlatforms" :orderDetails="orderDetails" :equipmentItems="equipmentItems"
              :user="user"></WarehouseOrder>
            <Offer v-if="view === 'offer'" :order="order" :orderItems="orderItems" :orderPlatforms="orderPlatforms"
              :equipmentItems="equipmentItems" :equipmentParts="equipmentParts" :colors="colors" :coverings="coverings"
              :foundations="foundations" :mountTypes="mountTypes" :sputterings="sputterings" :user="user"
              @addScheme="handleAddScheme" @renderComplete="handleRenderComplete"
              @renderOrderItemComplete="handleRenderOrderItemComplete"></Offer>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <div class="loading-overlay" v-if="loading || orderUpdateInProcess">
      <CSpinner color="success" />
    </div>
  </div>
</template>

<script>
import { router } from "../../router/index";
import Plan from "../../components/Plan.vue";
import Estimate from "../../components/Estimate.vue";
import WarehouseOrder from "../../components/WarehouseOrder.vue";
import Offer from "../../components/Offer.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Order",
  components: {
    Plan,
    Estimate,
    WarehouseOrder,
    Offer,
  },
  data() {
    return {
      user: null,
      order: null,
      orderId: null,
      orderItems: null,
      orderDetails: null,
      orderPlatforms: null,
      storeSubscription: null,
      settings: null,
      loading: false,
      orderUpdateInProcess: false, // Сохранение схемы или рендера
    };
  },
  created() {
    this.loading = true;

    this.orderId = this.$route.params.id;
    this.view = this.$route.params.view;
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "orders/getByIdSuccess") {
        this.order = this.orders[this.orderId];
        this.orderUpdateInProcess = false;
      }
      if (mutation.type === "orderItems/getByOrderIdSuccess") {
        this.orderItems = this.orderItemsByOrder[this.$route.params.id];
      }
      if (mutation.type === "orderPlatforms/getByOrderIdSuccess") {
        this.orderPlatforms = this.orderPlatformsByOrder[this.$route.params.id];
      }
      if (mutation.type === "orderDetails/getByOrderIdSuccess") {
        this.orderDetails = this.orderDetailsByOrder[this.$route.params.id];
      }
      if (mutation.type === "users/getCurrentSuccess") {
        this.user = mutation.payload;
      }
      if (mutation.type === "orders/getSettingsSuccess") {
        this.settings = mutation.payload;
      }
    });

    Promise.all([
      this["orders/getById"](this.orderId),
      this["equipmentItems/getAll"](),
      this["equipmentParts/getAll"](),
      this["dictionaryColors/getAll"](),
      this["dictionarySputterings/getAll"](),
      this["dictionaryCoverings/getAll"](),
      this["dictionaryFoundations/getAll"](),
      this["dictionaryMountTypes/getAll"](),
      this["orderItems/getByOrderId"](this.orderId),
      this["orderPlatforms/getByOrderId"](this.orderId),
      this["orderDetails/getByOrderId"](this.orderId),
      this["users/getCurrent"](),
      this["orders/getSettings"](),
    ]).then(() => {
      this.loading = false;
    });
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.open,
      equipmentItems: (state) => state.equipmentItems.all,
      equipmentParts: (state) => state.equipmentParts.all,
      colors: (state) => state.dictionaryColors.all,
      coverings: (state) => state.dictionaryCoverings.all,
      foundations: (state) => state.dictionaryFoundations.all,
      sputterings: (state) => state.dictionarySputterings.all,
      mountTypes: (state) => state.dictionaryMountTypes.all,
      orderItemsByOrder: (state) => state.orderItems.byOrder,
      orderPlatformsByOrder: (state) => state.orderPlatforms.byOrder,
      orderDetailsByOrder: (state) => state.orderDetails.byOrder,
    }),
  },

  methods: {
    ...mapActions([
      "users/getCurrent",
      "orders/getById",
      "orders/saveScheme",
      "orders/saveRender",
      "orderItems/getByOrderId",
      "orderItems/saveRender",
      "orderPlatforms/getByOrderId",
      "orderDetails/getByOrderId",
      "equipmentItems/getAll",
      "equipmentParts/getAll",
      "dictionaryColors/getAll",
      "dictionaryCoverings/getAll",
      "dictionaryFoundations/getAll",
      "dictionarySputterings/getAll",
      "dictionaryMountTypes/getAll",
      "orders/getSettings",
    ]),
    changeView(view) {
      this["orderItems/getByOrderId"](this.orderId);
      if (this.view === view) return;
      router.push(`/orders/${this.orderId}/${view}`);
    },

    handleAddScheme(showOrderItemCodes) {
      // todo костыльное обращение через document временно.
      let dataUrl
      if (!document.planComponent) {
        this.orderUpdateInProcess = true;
        router.push(`/orders/${this.orderId}/plan`);
        setTimeout(() => {
          router.push(`/orders/${this.orderId}/offer`);
          setTimeout(() => {
            const plan = document.planComponent;
            dataUrl = plan.renderScheme(showOrderItemCodes);
            this["orders/saveScheme"]({ orderId: this.order.id, dataUrl });
          }, 3000)
        }, 10000)
      } else {
        const plan = document.planComponent;
        dataUrl = plan.renderScheme(showOrderItemCodes);
        this.orderUpdateInProcess = true;
        this["orders/saveScheme"]({ orderId: this.order.id, dataUrl });
      }


    },

    handleRenderComplete(dataUrl) {
      this.orderUpdateInProcess = true;
      this["orders/saveRender"]({ orderId: this.order.id, dataUrl });
    },

    handleRenderOrderItemComplete(dataUrl, orderItemId) {
      this.orderUpdateInProcess = true;
      this["orderItems/saveRender"]({ orderId: this.order.id, orderItemId: orderItemId, dataUrl });
    },

    // Иницализируем ссылки на EquipmentItem в OrderItem`ах
    initOrderEquipmentItems() {
      if (!this.equipmentItems || !this.orderItems) return;
      for (const orderItem of this.orderItems) {
        orderItem.equipmentItem = this.equipmentItems.find(
          (item) => item.id === orderItem.item
        );
      }
    },

    fillCounterparty(entry) {
      this.order.counterparty_name = `${entry.first_name} ${entry.last_name}`;
      this.order.counterparty_name_dative = `${entry.first_name_dative} ${entry.last_name_dative}`;
      this.order.counterparty_email = entry.email;
      this.order.counterparty_phone = entry.phone;
      this.requestOrderUpdate();
    },
  },

  watch: {
    orderItems() {
      this.initOrderEquipmentItems();
    },
    equipmentItems() {
      this.initOrderEquipmentItems();
    },
  },
};
</script>
