<template>
  <CDataTable :items="displayedOrderItems" :fields="fields" hover border>
    <template #code="{ item }">
      <td class="td">
        <div v-if="(item.hasPillar||item.hasFlange) && order.sputtering == 2">{{ item.code }}-Zn</div>
        <div v-else> {{ item.code }} </div>
      </td>
    </template>
    <template #amount="{ item }">
      <td class="td">
        <div
          class="item-right text-nowrap"
          v-if="item.type === 'platform_base_area'"
        >
          {{ calculatePlatformsArea() }} {{ item.measure }}
        </div>
        <div
          class="item-right text-nowrap"
          v-else-if="item.type === 'platform_coating_area'"
        >
          {{ calculatePlatformsArea() }} {{ item.measure }}
        </div>
        <div class="item-right text-nowrap" v-else-if="item.amount">
          {{ item.amount }} {{ item.measure }}
        </div>
        <div v-else></div>
      </td>
    </template>
    <template #equipment_price="{ item }">
      <td class="td">
        <div
          class="item-right text-nowrap"
          v-if="item.type === 'platform_base_area'"
        >
          {{moneyFormat(calculateVAT(order.platform_base_square_meter_price)) }}
        </div>
        <div
          class="item-right text-nowrap"
          v-else-if="item.type === 'platform_coating_area'"
        >
          {{ moneyFormat(calculateVAT(order.platform_coating_square_meter_price)) }}
        </div>
        <div class="item-right text-nowrap" v-else-if="item.equipment_price">
            {{ moneyFormat(calculateVAT(item.equipment_price)) }}
        </div>
        <div v-else></div>
      </td>
    </template>
    <template #total_for_equipment="{ item }">
      <td class="td">
        <div class="item-right" v-if="item.type === 'total_equipment_cost'">
          {{ moneyFormat(calculateEquipmentTotalCost()) }}
        </div>
        <div class="item-right" v-if="item.type === 'platform_base_area'">
          {{ moneyFormat(calculateBaseTotalCost()) }}
        </div>
        <div
          class="item-right"
          v-else-if="item.type === 'platform_coating_area'"
        >
          {{ moneyFormat(calculateCoatingTotalCost()) }}
        </div>
        <div class="item-right" v-else-if="item.type === 'total_quipment_cost'">
          {{ moneyFormat(calculateEquipmentTotalCost()) }} <!-- зачем-то вторая строка итого оборудование -->
        </div>
        <div class="item-right" v-else-if="item.amount">
          {{ moneyFormat(calculateEquipmentCost(item)) }}
        </div>
        <div v-else></div>
      </td>
    </template>
    <template #installation_price="{ item }">
      <td class="td">
        <div class="item-right" v-if="item.type === 'total_installation_cost'">
          {{ moneyFormat(calculateInstallationTotalCost()) }}
        </div>
        <div class="item-right" v-else-if="item.installation_price">
          {{ moneyFormat(calculateInstallationCost(item)) }}
        </div>
        <div v-else class="item-right"></div>
      </td>
    </template>
    <template #discount_value="{ item }">
      <td class="td">
        <div class="item-right" v-if="item.amount">
          {{ moneyFormat(calculateEquipmentItemDiscount(item)) }}
        </div>
        <div v-else class="item-right"></div>
      </td>
    </template>
    <template #discount_for_equipment="{ item }">
      <td class="td">
        <div
          class="item-right"
          v-if="item.type === 'total_equipment_cost_with_discount'"
        >
          {{ moneyFormat(calculateEquipmentTotalCostWithDiscount()) }}
        </div>
        <div class="item-right" v-if="item.amount">
          {{ moneyFormat(calculateEquipmentItemCostWithDiscount(item)) }}
        </div>
        <div v-else class="item-right"></div>
      </td>
    </template>

    <template #total_cost="{ item }">
      <td class="td">
        <div class="item-right" v-if="item.type === 'overheads'">
          {{ moneyFormat(calculateOverheads()) }}
        </div>

        <div
          class="item-right"
          v-else-if="item.type === 'equipment_painting_cost'"
        >
          {{ moneyFormat(calculateEquipmentPaintigCost()) }}
        </div>

        <div class="item-right" v-else-if="item.type === 'delivery_cost'">
          {{ moneyFormat(calculateDeliveryCost()) }}
        </div>

        <div
          class="item-right"
          v-else-if="item.type === 'total_equipment_and_installation_cost'"
        >
          {{ moneyFormat(calculateWithoutBaseTotalCost()) }}
        </div>

        <div class="item-right" v-else-if="item.type === 'platform_base_area'">
          {{ moneyFormat(calculateBaseTotalCost()) }}
        </div>
        <div
          class="item-right"
          v-else-if="item.type === 'platform_coating_area'"
        >
          {{ moneyFormat(calculateCoatingTotalCost()) }}
        </div>
        <div
          class="item-right"
          v-else-if="item.type === 'total_platform_base_and_coating_cost'"
        >
          {{ moneyFormat(calculateBaseAndCoatingTotalCost()) }}
        </div>

        <div class="item-right" v-else-if="item.amount && !order.discount">
          {{ moneyFormat(calculateEquipmentAndInstallationCost(item)) }}
        </div>

        <div class="item-right" v-else-if="item.amount && order.discount">
          {{
            moneyFormat(calculateEquipmentAndInstallationCostWithDiscount(item))
          }}
        </div>

        <div class="item-right" v-else-if="item.type === 'total_cost'">
          {{ moneyFormat(calculateTotalCost()) }}
        </div>

        <div class="item-right" v-else-if="item.type === 'total_vat_cost' && order.has_vat">
          <i>{{ moneyFormat((calculateTotalCost()/(1 + parseFloat(order.vat))) * parseFloat(order.vat)) }}</i>
        </div>
      </td>
    </template>
  </CDataTable>
</template>

<script>
import { moneyFormat } from "../utils.js";

const fields = [
  {
    key: "item_id",
    label: "№",
  },
  {
    key: "code",
    label: "Артикул",
  },
  {
    key: "name",
    label: "Наименование товара, услуги",
  },
  {
    key: "amount",
    label: "Кол-во",
  },
  {
    key: "equipment_price",
    label: "Цена",
  },
  {
    key: "total_for_equipment",
    label: "Итого за оборудование",
  },  
  {
    key: "discount_value",
    label: "Скидка",
  },
  {
    key: "discount_for_equipment",
    label: "Оборудование со скидкой",
  },
  {
    key: "installation_price",
    label: "Монтаж",
  },
  {
    key: "total_cost",
    label: "Общая стоимость",
  },
];

const dummyEstimateRow = {
  item_id: "",
  code: "",
  name: "",
  description: "",
  equipment_price: "",
  installation_price: "",
  installation_percent: "",
  installation_percent_concrete: "",
  amount: "",
  measure: "",
  type: "",
};

const estimateFooterRows = [
  {
    name: "Итого оборудование",
    type: "total_equipment_cost",
  },
  {
    name: "Итого оборудование со скидкой",
    type: "total_equipment_cost_with_discount",
  },
  {
    name: "Итого монтаж",
    type: "total_installation_cost",
  },
  {
    name: "Покраска оборудования в цвет заказчика",
    type: "equipment_painting_cost",
  },
  {
    name: "Доставка",
    type: "delivery_cost",
  },
  {
    name: "Накладные расходы",
    type: "overheads",
  },
  {
    name: "Всего за оборудование, монтаж, доставку и накладные расходы",
    type: "total_equipment_and_installation_cost",
  },
  {
    name: "Подготовка основания",
    type: "platform_base_area",
    measure: "м²",
  },
  {
    name: "Укладка травмобезопасного покрытия",
    type: "platform_coating_area",
    measure: "м²",
  },
  {
    name: "Всего за подготовку основания и укладку травмобезопасного покрытия",
    type: "total_platform_base_and_coating_cost",
  },
  {
    name: "Всего по проекту",
    type: "total_cost",
  },
  {
    name: "В том числе НДС",
    type: "total_vat_cost",
  },
];

export default {
  name: "KOfferTable",
  props: {
    order: Object,
    orderItems: Array,
    orderPlatforms: Array,
    sputtering: Object,
  },

  data() {
    return {
      fields: null,
      displayedOrderItems: null,
      moneyFormat: moneyFormat,
    };
  },
  computed: {},
  created() {
    this.render();
  },
  beforeDestroy() {},
  methods: {
    makeOrderItemPartFlags(orderItem) {
      orderItem.isUnique = false;
      orderItem.hasInactiveParts = false;
      orderItem.hasAdditionalParts = false;
      orderItem.hasPillar = false;
      orderItem.hasFlange = false;
      orderItem.inactiveParts = [];
      orderItem.additionalParts = [];
      for (const orderItemEquipmentPart of orderItem.equipment_parts) {
        if (
          !orderItemEquipmentPart.active &&
          orderItemEquipmentPart.type === "own"
        ) {
          orderItem.hasInactiveParts = true;
          orderItem.inactiveParts.push(orderItemEquipmentPart);
        }
        if (orderItemEquipmentPart.type === "additional") {
          orderItem.hasAdditionalParts = true;
          orderItem.additionalParts.push(orderItemEquipmentPart);
        }
        if (orderItemEquipmentPart.key.startsWith("V-")) {
          orderItem.hasPillar = true;
        }
        if (orderItemEquipmentPart.key.startsWith("FV-")) {
          orderItem.hasFlange = true;
        }
      }
      orderItem.isUnique =
        orderItem.hasInactiveParts || orderItem.hasAdditionalParts;
    },

    render() {
      this.fields = [];
      const discount = parseFloat(this.order.discount);
      for (const field of fields) {
        if (discount === 0) {
          if (field.key === "discount_value") {
            continue;
          }
          if (field.key === "discount_for_equipment") {
            continue;
          }
        } else {
          if (field.key === "discount_value") {
            field.label = `Скидка, ${discount}%`;
          }
        }
        if (field.key === "installation_price" && !this.order.installation) {
          continue;
        }
        this.fields.push(field);
      }
      const addedOrderItemCodes = [];
      const displayedOrderItems = [];
      this.orderItems.forEach((orderItem, index) => {
        this.makeOrderItemPartFlags(orderItem);
        if (
          !addedOrderItemCodes.includes(orderItem.equipmentItem.code) ||
          orderItem.isUnique
        ) {
          if (!orderItem.isUnique) {
            addedOrderItemCodes.push(orderItem.equipmentItem.code);
          }
          orderItem.count = 1;
          orderItem.item_id = index + 1;
          orderItem.measure = "шт.";
          orderItem.amount = 1;
          orderItem.type = "equipment-item";
          displayedOrderItems.push(orderItem);
        } else {
          for (const uniqueOrderItem of displayedOrderItems) {
            if (uniqueOrderItem.isUnique) {
              continue;
            }
            if (
              uniqueOrderItem.equipmentItem.code ===
              orderItem.equipmentItem.code
            ) {
              uniqueOrderItem.amount++;
              uniqueOrderItem.count++;
              break;
            }
          }
        }
      });

      let noBaseCost = false;
      let noCoatingCost = false;
      for (const footerRow of estimateFooterRows) {
        let newRow = {};
        if (footerRow.type === "overheads" && this.calculateOverheads() === 0) {
          continue;
        }
        if (
          footerRow.type === "equipment_painting_cost" &&
          this.calculateEquipmentPaintigCost() === 0
        ) {
          continue;
        }
        if (
          footerRow.type === "delivery_cost" &&
          this.calculateDeliveryCost() === 0
        ) {
          continue;
        }
        if (
          footerRow.type === "platform_base_area" &&
          this.calculateBaseTotalCost() === 0
        ) {
          noBaseCost = true;
          continue;
        }
        if (
          footerRow.type === "platform_coating_area" &&
          this.calculateCoatingTotalCost() === 0
        ) {
          noCoatingCost = true;
          continue;
        }
        if (
          footerRow.type === "total_platform_base_and_coating_cost" &&
          noBaseCost &&
          noCoatingCost
        ) {
          continue;
        }
        if (
          footerRow.type === "total_installation_cost" &&
          !this.order.installation
        ) {
          continue;
        }
                if (
          footerRow.type === "total_equipment_cost_with_discount"
          
        ) {
          console.log(this.order.discount)
        }
        if (
          footerRow.type === "total_equipment_cost_with_discount" &&
          parseFloat(this.order.discount) === 0
        ) {
          continue;
        }
        Object.assign(newRow, dummyEstimateRow);
        for (const footerRowPropertyName in footerRow) {
          newRow[footerRowPropertyName] = footerRow[footerRowPropertyName];
        }

        if (footerRow.type === "total_equipment_and_installation_cost") {
          newRow.name = "Всего за оборудование";
        }
        if (
          footerRow.type === "total_equipment_and_installation_cost" &&
          this.calculateDeliveryCost() > 0
        ) {
          newRow.name = newRow.name + ", доставку";
        }
        if (
          footerRow.type === "total_equipment_and_installation_cost" &&
          this.calculateEquipmentPaintigCost() > 0
        ) {
          newRow.name = newRow.name + ", покраску";
        }
        if (
          footerRow.type === "total_equipment_and_installation_cost" &&
          this.order.installation
        ) {
          newRow.name = newRow.name + ", монтаж";
        }
        if (
          footerRow.type === "total_equipment_and_installation_cost" &&
          this.calculateOverheads() > 0
        ) {
          newRow.name = newRow.name + ", накладные расходы";
        }

        displayedOrderItems.push(newRow);
      }

      // Чистим от метаинформации (three.js объекты). Они содержат рекурсию, из-за которой ломается CDataTable
      this.displayedOrderItems = displayedOrderItems.map((item) => {
        // eslint-disable-next-line no-unused-vars
        const { meta, parts, ...data } = item;
        return data;
      });
    },

    calculateVAT(price) {
      price = parseFloat(price || 0)
      if (this.order.has_vat) {
        price = parseFloat((this.order.vat * price + price) || 0)
      }
      return price
    },

    calculateOverheads() {
      return this.calculateVAT(this.order.overheads);
    },

    calculateEquipmentPaintigCost() {
      return this.calculateVAT(this.order.item_painting_price);
    },

    calculateDeliveryCost() {
      return  this.calculateVAT(this.order.delivery_cost);
    },

    calculateEquipmentAndInstallationCost(item) {
      let equipmentAndInstallationCost = 0;
      equipmentAndInstallationCost += this.calculateEquipmentCost(item);
      equipmentAndInstallationCost += this.calculateInstallationCost(item);
      equipmentAndInstallationCost += this.calculateAdditionalCost(item);
      return equipmentAndInstallationCost;
    },

    calculateEquipmentCost(item) {
      return item.amount * this.calculateVAT(item.equipment_price);
    },

    calculateEquipmentTotalCost() {
      let equipmentTotalCost = 0;
      this.orderItems.forEach((item) => {
        if (item.amount) {
          equipmentTotalCost += item.amount * this.calculateVAT(item.equipment_price);
        }
      });
      return equipmentTotalCost;
    },

    calculateInstallationCost(item) {

      if (!this.order.installation) {
        return 0;
      }


      let coef = item.equipmentItem.installation_percent * 0.01;



      if (this.order.installation && this.order.foundation == 4) {
        coef = item.equipmentItem.installation_percent_concrete * 0.01;
      }

      return item.amount * this.calculateVAT(item.equipment_price) * coef;
    },

    calculateInstallationTotalCost() {
      let installationTotalCost = 0;
      const saveThis = this;
      this.orderItems.forEach(function (item) {
        if (item.amount) {
          installationTotalCost += saveThis.calculateInstallationCost(item);
        }
      });
      return installationTotalCost;
    },

    calculateAdditionalCost(item) {
      return item.amount * this.calculateVAT(item.additional_price);
    },

    calculateAdditionalTotalCost() {
      let additionalTotalCost = 0;
      const saveThis = this;
      this.orderItems.forEach(function (item) {
        if (item.amount) {
          additionalTotalCost += saveThis.calculateAdditionalCost(item);
        }
      });
      return additionalTotalCost;
    },

    calculateEquipmentAndInstallationTotalCost() {
      let equipmentAndInstallationTotalCost = 0;
      equipmentAndInstallationTotalCost += this.calculateEquipmentTotalCost();
      equipmentAndInstallationTotalCost +=
        this.calculateInstallationTotalCost();
      equipmentAndInstallationTotalCost += this.calculateOverheads();
      equipmentAndInstallationTotalCost += this.calculateDeliveryCost();
      return equipmentAndInstallationTotalCost;
    },

    calculatePlatformsArea() {
      let platformsArea = 0.0;
      for (const orderPlatform of this.orderPlatforms) {
        platformsArea += parseFloat(orderPlatform.area);
      }
      return platformsArea;
    },

    calculateBaseTotalCost() {
      const platformsArea = this.calculatePlatformsArea();
      return platformsArea *  this.calculateVAT(this.order.platform_base_square_meter_price);
    },

    calculateCoatingTotalCost() {
      const platformsArea = this.calculatePlatformsArea();
      return platformsArea * this.calculateVAT(this.order.platform_coating_square_meter_price);
    },

    calculateBaseAndCoatingTotalCost() {
      let baseAndCoatingTotalCost = 0;
      baseAndCoatingTotalCost += this.calculateBaseTotalCost();
      baseAndCoatingTotalCost += this.calculateCoatingTotalCost();
      return baseAndCoatingTotalCost;
    },

    calculateOverheadsWithDiscount() {
      return this.calculateOverheads();
    },

    calculateEquipmentAndInstallationCostWithDiscount(item) {
      const eqipmentItemTotalCost =
        item.amount *
        this.calculateVAT(item.equipment_price) *
        (1 - parseFloat(this.order.discount || 0) / 100);
      const equipmentItemInstallationTotalCost =
        this.calculateInstallationCost(item);
      return eqipmentItemTotalCost + equipmentItemInstallationTotalCost;
    },

    calculateEquipmentItemDiscount(item) {
      return (
        item.amount *
        this.calculateVAT(item.equipment_price) *
        (parseFloat(this.order.discount || 0) / 100)
      );
    },

    calculateEquipmentItemCostWithDiscount(item) {
      return (
        item.amount *
        this.calculateVAT(item.equipment_price) *
        (1 - parseFloat(this.order.discount || 0) / 100)
      );
    },

    calculateEquipmentTotalCostWithDiscount() {
      let equipmentTotalCost = 0;
      const saveThis = this;
      this.orderItems.forEach(function (item) {
        if (item.amount) {
          equipmentTotalCost +=
            saveThis.calculateEquipmentItemCostWithDiscount(item);
        }
      });
      return equipmentTotalCost;
    },

    calculateInstallationTotalCostWithDiscount() {
      let installationTotalCost = 0;
      const saveThis = this;
      this.orderItems.forEach(function (item) {
        if (item.amount) {
          installationTotalCost += saveThis.calculateInstallationCost(item);
        }
      });
      return installationTotalCost;
    },

    calculateDeliveryCostWithDiscount() {
      return this.calculateDeliveryCost();
    },

    calculateWithoutBaseTotalCost() {
      let equipmentDiscount = 0;
      let equipment = 0;
      if (this.order.discount) {
        equipmentDiscount = this.calculateEquipmentTotalCostWithDiscount();
      } else {
        equipment = this.calculateEquipmentTotalCost();
      }
      const installation = this.calculateInstallationTotalCost();
      const overheads = this.calculateOverheads();
      const paintig = this.calculateEquipmentPaintigCost();
      const delivery = this.calculateDeliveryCost();
      const totalCost =
        equipmentDiscount +
        equipment +
        installation +
        overheads +
        paintig +
        delivery;
      return totalCost;
    },

    calculateEquipmentAndInstallationTotalCostWithDiscount() {
      const equipmentTotalCostWithDiscount =
        this.calculateEquipmentTotalCostWithDiscount();
      const installationTotalCostWithDiscount =
        this.calculateInstallationTotalCostWithDiscount();
      return equipmentTotalCostWithDiscount + installationTotalCostWithDiscount;
    },

    calculateBaseTotalCostWithDiscount() {
      return this.calculateBaseTotalCost();
    },
    calculateCoatingTotalCostWithDiscount() {
      return this.calculateCoatingTotalCost();
    },

    calculateBaseAndCoatingTotalCostWithDiscount() {
      let baseAndCoatingTotalCost = 0;
      baseAndCoatingTotalCost += this.calculateBaseTotalCostWithDiscount();
      baseAndCoatingTotalCost += this.calculateCoatingTotalCostWithDiscount();
      return baseAndCoatingTotalCost;
    },

    calculateTotalCost() {
      let withoutBaseTotalCost = this.calculateWithoutBaseTotalCost();
      let baseAndCoatingTotalCost = this.calculateBaseAndCoatingTotalCost();
      const totalCost = withoutBaseTotalCost + baseAndCoatingTotalCost;
      return totalCost;
    },

    calculateTotalCostWithDiscount() {
      let equipmentAndInstallationTotalCost =
        this.calculateEquipmentAndInstallationTotalCostWithDiscount();
      let baseAndCoatingTotalCost =
        this.calculateBaseAndCoatingTotalCostWithDiscount();
      return equipmentAndInstallationTotalCost + baseAndCoatingTotalCost;
    },
     mathCell(value) {
      console.log("value "+ value)
      let valueRound = 0;
      valueRound = Math.round(value)
      console.log(" valueRound "+  valueRound)
      return valueRound
     }
  },
  watch: {
    order() {
      this.render();
    },
  },
};
</script>
