<template>
  <div class="contaner-warehouse-order">
    <CRow>
      <h1 class="font-size-20 font-weight-bold">
        Требование на склад № {{ order.warehouse_order_number }} от
        {{ dateFormat(order.warehouse_order_date) }}.
      </h1>
    </CRow>

    <hr />

    <CRow class="mt-4 mb-4">
      <table>
        <tbody>
          <tr>
            <td>Организация:</td>
            <td class="font-weight-bold">КЕНГУРУ.ПРО ООО</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Контрагент:</td>
            <td class="font-weight-bold">{{ order.counterparty_name }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Дата запуска:</td>
            <td class="font-weight-bold">
              {{ dateFormat(order.warehouse_order_initial_date) }}
            </td>
            <td>Подразделение:</td>
            <td class="font-weight-bold">Цех комплектующих</td>
          </tr>
          <tr>
            <td>Срок отгрузки:</td>
            <td class="font-weight-bold">
              {{ dateFormat(order.warehouse_order_supply_date) }}
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </CRow>

    <CRow>
      <h4 class="font-size-16 font-weight-bold mb-3">Продукция, работа</h4>
    </CRow>
    <CRow class="mb-4">
      <table class="table">
        <thead>
          <th>№</th>
          <th>Наименование, характеристика продукции</th>
          <th>Код</th>
          <th>Тип</th>
          <th class="text-right">Кол-во</th>
          <th>Ед.</th>
        </thead>

        <tbody v-for="(orderItem, index) in getUniqueOrderItems()" :key="orderItem.id">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ orderItem.name }} 
              <span v-if="orderItem.isUnique"> (индивидуальный) </span>
            </td>
            <td>{{ orderItem.code }}</td>
            <td>Запас</td>
            <td class="text-right">{{ orderItem.count }}</td>
            <td>шт</td>
          </tr>
        </tbody>
      </table>
    </CRow>
    <CRow>
      <h4 class="font-size-16 font-weight-bold mb-3 mt-2">
        Потребность в материалах
      </h4>
    </CRow>

    <CRow class="mb-4">
      <table class="table">
        <thead>
          <th>№</th>
          <th>Наименование, характеристика продукции</th>
          <th>Код</th>
          <th>Тип</th>
          <th class="text-right">Кол-во</th>
          <th>Ед.</th>
        </thead>

        <tbody
          v-for="(orderDetail, index) in orderDetails"
          :key="orderDetail.id"
        >
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ orderDetail.description }}</td>
            <td>{{ orderDetail.code }}</td>
            <td>Запас</td>
            <td class="text-right">{{ getAmount(orderDetail) }}</td>
            <td>{{ getMeasure(orderDetail) }}</td>
          </tr>
        </tbody>
      </table>
    </CRow>
    <CRow class="mb-4">
      <tr>
        <td>Комментарий:</td>
        <td class="pl-2">{{ order.comment }}</td>
      </tr>
    </CRow>
    <CRow class="mb-4">
      <tbody class="text-center">
        <tr>
          <td class="font-weight-bold">Исполнитель</td>
          <td class="w-50px"></td>
          <td></td>
          <td class="w-20px"></td>
          <td>
            {{ user.first_name }} {{ user.last_name }} {{ user.patronymic }}
          </td>
        </tr>
        <tr>
          <td class="pt-pb-0"></td>
          <td class="pt-pb-0"></td>
          <td class="sign-line sign-line-short pt-pb-0"></td>
          <td class="pt-pb-0"></td>
          <td class="sign-line sign-line-long pt-pb-0"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td><sup>подпись</sup></td>
          <td></td>
          <td><sup>расшифровка подписи</sup></td>
        </tr>
      </tbody>
    </CRow>
  </div>
</template>


<script>
import { dateFormat } from "../utils";
export default {
  name: "WarehouseOrder",
  props: {
    user: Object,
    order: Object,

    orderItems: Array,
    orderPlatforms: Array,
    orderDetails: Array,
  },
  methods: {
    getAmount(orderDetail) {
      if (orderDetail.measure === "millimeter") {
        return (
          Math.round((orderDetail.amount / 1000 + Number.EPSILON) * 1000) / 1000
        )
          .toString()
          .replace(".", ",");
      } else {
        return orderDetail.amount;
      }
    },
    getMeasure(orderDetail) {
      if (orderDetail.measure === "millimeter") {
        return "м";
      } else {
        return "шт";
      }
    },
    dateFormat(value) {
      return dateFormat(value);
    },

    makeOrderItemPartFlags(orderItem) {
      orderItem.isUnique = false;
      orderItem.hasInactiveParts = false;
      orderItem.hasAdditionalParts = false;
      orderItem.inactiveParts = [];
      orderItem.additionalParts = [];
      for (const orderItemEquipmentPart of orderItem.equipment_parts) {
        if (
          !orderItemEquipmentPart.active &&
          orderItemEquipmentPart.type === "own"
        ) {
          orderItem.hasInactiveParts = true;
          orderItem.inactiveParts.push(orderItemEquipmentPart);
        }
        if (orderItemEquipmentPart.type === "additional") {
          orderItem.hasAdditionalParts = true;
          orderItem.additionalParts.push(orderItemEquipmentPart);
        }
      }
      orderItem.isUnique =
        orderItem.hasInactiveParts || orderItem.hasAdditionalParts;
    },

    getUniqueOrderItems() {
      const uniqueOrderItems = [];
      const addedOrderItemCodes = [];
      for (const orderItem of this.orderItems) {
        this.makeOrderItemPartFlags(orderItem);
        if (
          !addedOrderItemCodes.includes(orderItem.equipmentItem.code) ||
          orderItem.isUnique
        ) {
          if (!orderItem.isUnique) {
            addedOrderItemCodes.push(orderItem.equipmentItem.code);
          }
          orderItem.count = 1;
          uniqueOrderItems.push(orderItem);
        } else {
          for (const uniqueOrderItem of uniqueOrderItems) {
            if (uniqueOrderItem.isUnique) {
              continue;
            }
            if (uniqueOrderItem.equipmentItem.code === orderItem.equipmentItem.code) {
              uniqueOrderItem.count++;
              break;
            }
          }
        }
      }
      return uniqueOrderItems;
    },
  },
};
</script>


