<template>
  <div class="container-estimate">
    <div v-if="orderItems.length">
      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">
            Номер заказа, статус заказа и покупатель
          </h5>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol sm="4">
          <CInput
            label="Номер заказа"
            v-model="order.code"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol sm="4">
          <CInput
            label="Статус заказа"
            v-model="order.status"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol sm="4">
          <CInput
            label="Покупатель"
            v-model="order.client"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>
      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Требование на склад</h5>
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol sm="4">
          <CInput
            label="Номер требования на склад"
            v-model="order.warehouse_order_number"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol tag="label" sm="2" class="col-form-label pt-0"
          >Дата требования на склад</CCol
        >
        <CCol sm="2">
          <input
            type="date"
            v-model="order.warehouse_order_date"
            @input="requestOrderUpdate"
            class="form-control"
          />
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol tag="label" sm="2" class="col-form-label pt-0"
          >Дата запуска требования на склад</CCol
        >
        <CCol sm="2">
          <input
            type="date"
            v-model="order.warehouse_order_initial_date"
            @input="requestOrderUpdate"
            class="form-control"
          />
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol tag="label" sm="2" class="col-form-label pt-0"
          >Срок отгрузки требования на склад</CCol
        >
        <CCol sm="2">
          <input
            type="date"
            v-model="order.warehouse_order_supply_date"
            @input="requestOrderUpdate"
            class="form-control"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol tag="label" sm="5" class="col-form-label pt-0"
          >Комментарий к требованию на склад</CCol
        >
      </CRow>

      <CRow>
        <CCol sm="5">
          <CTextarea
            v-model="order.comment"
            @input="requestOrderUpdate"
            rows="4"
          >
          </CTextarea>
        </CCol>
      </CRow>

      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">НДС</h5>
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <div class="form-check pl-15">
        <label class="form-check-label" for="input-vat">
          Включая НДС, {{ settings[0].vat * 100}} %
        </label>
        <input
          id="input-vat"
          class="form-check-input ml-2"
          type="checkbox"
          v-model="order.has_vat"
          @change="requestOrderUpdate"
        />
        </div>
      </CRow>



      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Монтаж</h5>
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <div class="form-check pl-15">
          <label class="form-check-label" for="input-installation">
            Устанавливает Кенгуру.Про
          </label>
          <input
            id="input-installation"
            class="form-check-input ml-2"
            type="checkbox"
            value=""
            v-model="order.installation"
            @change="requestOrderUpdate"
          />
          <CRow v-if="order.installation">
            <CRow v-if="foundation && foundation.id == 4" class="mb-4 pt-0">
              <div class="form-check pl-15">
                —
                <i>
                  для основания «Бетон» стоимость монтажа для комплексов со
                  столбами составит <b>25%</b> от стоимости комплекса</i
                >
              </div>
            </CRow>
          </CRow>
        </div>
      </CRow>

      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Подготовка основания</h5>
        </CCol>
      </CRow>
      <CRow
        v-if="
          (!foundation && !covering) ||
          (foundation && covering && foundation.id == 1 && covering.id == 3)
        "
        class="mb-4 pt-0"
      >
        <CCol sm="6">
          <div class="form-check pl-15">
            —
            <i
              >Поля ввода стоимости подготовки основания будут доступны после
              выбора соответствующих данных в разделе «План» во вкладке
              <img
                src="../assets/icons/grid-tab.png"
                style="width: 20px; height: 20px"
              />.</i
            >
          </div>
        </CCol>
      </CRow>

      <CRow v-if="foundation && foundation.code != 'none'" class="mb-4 pt-0">
        <CCol tag="label" sm="2" class="col-form-label">
          1м² основания ({{ foundation.name }}), ₽
        </CCol>
        <CCol sm="2">
          <CInput
            class="text-right"
            type="number"
            v-model="order.platform_base_square_meter_price"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>

      <CRow v-if="covering && covering.code != 'none'" class="mb-4 pt-0">
        <CCol tag="label" sm="2" class="col-form-label">
          1м² травмобезопасного покрытия ({{ covering.name }}), ₽
        </CCol>
        <CCol sm="2">
          <CInput
            class="text-right"
            type="number"
            v-model="order.platform_coating_square_meter_price"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>

      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Покраска комплексов в цвет заказчика, ₽</h5>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="2">
          <CInput
            class="text-right"
            type="number"
            v-model="order.item_painting_price"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>

      <CRow class="mt-2 mb-4">
        <CCol sm="3">
          <h5 class="card-title mb-0">Накладные расходы, ₽</h5>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="2">
          <CInput
            class="text-right"
            type="number"
            v-model="order.overheads"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>

      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Скидка на оборудование, %</h5>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <CInput
            class="text-right"
            type="number"
            v-model="order.discount"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow>

      <!-- <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Распределенная стоимость</h5>
        </CCol>
      </CRow>
      <CRow>
        <CCol
          tag="label"
          sm="5"
          class="col-form-label pt-0"
          style="margin-top: -20px"
        >
          — это затраты, которые будут равномерно распределны на стоимость
          оборудования (т.е. не будут показаны отдельно)
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <CInput
            class="text-right"
            type="number"
            v-model="order.additional_price"
            @input="requestOrderUpdate"
          >
          </CInput>
        </CCol>
      </CRow> -->

      <CRow class="mt-2 mb-3">
        <CCol sm="5">
          <h5 class="card-title mb-0">Доставка</h5>
        </CCol>
      </CRow>

      <div class="position-relative for-spinner">
        <CRow class="mb-4">
          <CCol tag="label" sm="2" class="col-form-label pt-0"
            >Дата отгрузки комплексов со склада «Кенгуру ПРО»</CCol
          >
          <CCol sm="2"
            ><input
              type="date"
              v-model="order.derival_date"
              @input="requestOrderUpdate"
              class="form-control"
          /></CCol>
        </CRow>
        <CRow>
          <CCol tag="label" sm="2" class="col-form-label"
            >Адрес отправления</CCol
          >
          <CCol sm="8">
            <div role="group" class="form-group">
              <dadata-suggestions
                v-model="order.derival_address"
                field-value="unrestricted_value"
              />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol tag="label" sm="2" class="col-form-label">Адрес доставки</CCol>
          <CCol sm="8">
            <div role="group" class="form-group">
              <dadata-suggestions
                v-model="order.arrival_address"
                field-value="unrestricted_value"
              />
            </div>
          </CCol>
        </CRow>

        <div
          v-if="
            order.delivery_info &&
            order.delivery_info.data &&
            !flags.deliveryCostCalculating
          "
        ></div>
        <div
          v-else-if="
            order.delivery_info.errors &&
            order.delivery_info.errors.length > 0 &&
            !flags.deliveryCostCalculating
          "
        ></div>
        <div
          v-else-if="flags.deliveryCostCalculating"
          class="d-flex justify-content-between align-items-center mt-4 mb-4"
        >
          <CElementCover
            :boundaries="[{ sides: ['top', 'left'] }]"
            :opacity="0.7"
          >
            <h4 class="d-inline">Загрузка...</h4>
            <CSpinner size="3xl" color="success" />
          </CElementCover>
        </div>
      </div>

      <div>
        <CRow>
          <CCol tag="label" sm="2" class="col-form-label">
            <h6>Итого за доставку:</h6>
          </CCol>
          <CCol tag="label" sm="3" class="col-form-label">
            <CInput
              class="text-right"
              type="number"
              v-model="order.delivery_cost"
              @input="requestOrderUpdate"
            >
            </CInput>
          </CCol>
        </CRow>
        <!-- <CRow class="mb-3">
          <CCol tag="label" sm="2" class="col-form-label">
            <h6>Дата доставки покупателю:</h6></CCol
          >
          <CCol tag="label" sm="3" class="col-form-label"
            ><h6>
              {{
                dateFormat(
                  calcuteSafeDeliveryDate(
                    order.delivery_info.data.orderDates.derivalFromOspReceiver
                  )
                )
              }}
            </h6></CCol
          >
        </CRow> -->

        <CRow>
          <CCol>
            <CButton @click="collapse = !collapse" color="dark" class="mb-4">
              Детали доставки
            </CButton>
          </CCol>
        </CRow>
      </div>

      <div v-if="order.derival_address && order.arrival_address">
        <div
          class="mb-5"
          v-if="!order.delivery_info.errors && !flags.deliveryCostCalculating"
        >
          <CCollapse :show="collapse">
            <CTabs variant="tabs" :active-tab="0">
              <CTab title="Характеристики груза">
                <CRow>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Мест, шт</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    order.delivery_info.measures.total_places
                  }}</CCol>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Макс. длина, м</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.max_length, 2)
                  }}</CCol>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Макс. ширина, м</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.max_width, 2)
                  }}</CCol>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Макс. высота, м</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.max_height, 2)
                  }}</CCol>
                </CRow>
                <CRow>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Объём, м³</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.total_volume, 2)
                  }}</CCol>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Объём негабаритного груза, м³</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.oversized_volume, 2)
                  }}</CCol>
                  <CCol tag="label" sm="2" class="col-form-label">Вес, кг</CCol>
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.total_weight, 3)
                  }}</CCol>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Вес негабаритного груза, кг</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.oversized_weight, 3)
                  }}</CCol>
                </CRow>
                <CRow>
                  <CCol tag="label" sm="2" class="col-form-label"
                    >Макс. вес, кг</CCol
                  >
                  <CCol tag="label" sm="1" class="col-form-label">{{
                    round(order.delivery_info.measures.max_weight, 3)
                  }}</CCol>
                </CRow>
              </CTab>

              <CTab title="Стоимость доставки">
                <CCol sm="6">
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Доставка от отправителя:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(order.delivery_info.data.derival.price || 0) +
                      " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Межтерминальная перевозка:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(
                        (order.delivery_info.data.intercity &&
                          order.delivery_info.data.intercity.price) ||
                          (order.delivery_info.data.small &&
                            order.delivery_info.data.small.price) ||
                          (order.delivery_info.data.air &&
                            order.delivery_info.data.air.price) ||
                          (order.delivery_info.data.express &&
                            order.delivery_info.data.express.price) ||
                          (order.delivery_info.data.letter &&
                            order.delivery_info.data.letter.price) ||
                          0
                      ) + " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Доставка до получателя:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(order.delivery_info.data.arrival.price || 0) +
                      " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Упаковка:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(
                        calculatePackagesPrice(
                          order.delivery_info.data.packages
                        ) || 0
                      ) + " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Страховка:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(order.delivery_info.data.insurance || 0) +
                      " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Отправка сопроводительных документов:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(
                        order.delivery_info.data.accompanyingDocuments.send
                          .price || 0
                      ) + " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Возврат сопроводительных документов:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(
                        order.delivery_info.data.accompanyingDocuments.return
                          .price || 0
                      ) + " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Информирование о статусе заказа:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(order.delivery_info.data.notify.price || 0) +
                      " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Итого:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(order.delivery_info.data.price || 0) + " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Наценка КЕНГУРУ.ПРО, 10%:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(
                        calculateManufacturerCost(
                          order.delivery_info.data.price
                        )
                      ) + " ₽"
                    }}</CCol>
                  </CRow>
                  <CRow>
                    <CCol tag="label" sm="9" class="col-form-label"
                      >Итого c наценкой КЕНГУРУ.ПРО:</CCol
                    >
                    <CCol tag="label" sm="3" class="col-form-label">{{
                      moneyFormat(order.delivery_cost || 0) + " ₽"
                    }}</CCol>
                  </CRow>
                </CCol>
              </CTab>

              <CTab title="Даты доставки">
                <CCol sm="6" v-if="order.delivery_info.data.orderDates">
                  <CRow v-if="order.delivery_info.data.orderDates.pickup">
                    <CCol tag="label" sm="8" class="col-form-label">
                      Передача груза на адресе отправителя:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(order.delivery_info.data.orderDates.pickup)
                    }}</CCol>
                  </CRow>
                  <CRow
                    v-if="
                      order.delivery_info.data.orderDates.arrivalToOspSender
                    "
                  >
                    <CCol tag="label" sm="8" class="col-form-label"
                      >Прибытие на терминал-отправитель:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(
                        order.delivery_info.data.orderDates.arrivalToOspSender
                      )
                    }}</CCol>
                  </CRow>
                  <CRow
                    v-if="
                      order.delivery_info.data.orderDates.derivalFromOspSender
                    "
                  >
                    <CCol tag="label" sm="8" class="col-form-label"
                      >Отправка с терминала-отправителя:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(
                        order.delivery_info.data.orderDates.derivalFromOspSender
                      )
                    }}</CCol>
                  </CRow>
                  <CRow
                    v-if="
                      order.delivery_info.data.orderDates.arrivalToOspReceiver
                    "
                  >
                    <CCol tag="label" sm="8" class="col-form-label"
                      >Прибытие на терминал-получатель:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(
                        order.delivery_info.data.orderDates.arrivalToOspReceiver
                      )
                    }}</CCol>
                  </CRow>
                  <CRow
                    v-if="order.delivery_info.data.orderDates.arrivalToAirport"
                  >
                    <CCol tag="label" sm="8" class="col-form-label"
                      >Прибытие на терминал в аэропорт получателя:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(
                        order.delivery_info.data.orderDates.arrivalToAirport
                      )
                    }}</CCol>
                  </CRow>
                  <CRow
                    v-if="
                      order.delivery_info.data.orderDates.derivalFromOspReceiver
                    "
                  >
                    <CCol tag="label" sm="8" class="col-form-label"
                      >Отправка с терминала-получателя:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(
                        order.delivery_info.data.orderDates
                          .derivalFromOspReceiver
                      )
                    }}</CCol>

                    <CCol tag="label" sm="8" class="col-form-label"
                      >Запас в 5 дней на непредвиденные ситуации:</CCol
                    >
                    <CCol tag="label" sm="4" class="col-form-label">{{
                      dateFormat(
                        calcuteSafeDeliveryDate(
                          order.delivery_info.data.orderDates
                            .derivalFromOspReceiver
                        )
                      )
                    }}</CCol>
                  </CRow>
                </CCol>
              </CTab>
            </CTabs>
          </CCollapse>
        </div>
      </div>

      <CRow class="mt-2 mb-4">
        <CCol sm="5">
          <h5 class="card-title mb-0">Общая смета</h5>
        </CCol>
      </CRow>
      <KOfferTable
        :order="order"
        :orderItems="orderItems"
        :orderPlatforms="orderPlatforms"
        :discount="order.discount"
        :foundation="foundation"
        :flags="flags"
      ></KOfferTable>
    </div>
    <div v-else>
      Добавьте в заказ комплексы в разделе «План», после этого будут доступны
      детали заказа.
    </div>
  </div>
</template>

<style>
.border-none {
  border: none;
}

.pl-15 {
  padding-left: 15px !important;
}

.text-right input {
  text-align: right;
}

.text-right input::placeholder {
  text-align: right;
}

.card {
  margin-bottom: 0;
  margin-top: 0;
}

.c-card-body {
  background-color: #fefefe;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
  text-align: right;
}
</style>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
import KOfferTable from "./KOfferTable.vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import { moneyFormat, dateFormat } from "../utils.js";
import { crmContactsService } from "../_services";
import { getDataObjectById } from "../_helpers";

export default {
  name: "Estimate",
  props: {
    user: Object,
    order: Object,
    settings: Array,
    coverings: Array,
    sputterings: Array,
    foundations: Array,
    mountTypes: Array,
    orderItems: Array,
    orderPlatforms: Array,
    equipmentItems: Array,
  },
  components: {
    KOfferTable,
  },
  data() {
    return {
      flags: {
        deliveryCostCalculating: false,
      },
      collapse: false,
      collapseCounterparty: false,
      hasVAT: false,
    };
  },
  created() {
    this.hasVAT = this.order.vat > 0;
    if (!this.order.counterparty_name) {
      this.order.counterparty_name = this.order.client;
    }
    if (!this.order.counterparty_phone) {
      this.order.counterparty_phone = this.order.client_phone;
    }
    if (!this.order.counterparty_email) {
      this.order.counterparty_email = this.order.client_email;
    }
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "orders/updateRequest") {
        this.flags.deliveryCostCalculating = true;
      }
      if (mutation.type === "orders/updateSuccess") {
        this.flags.deliveryCostCalculating = false;
      }
    });
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  computed: {
    foundation() {
      return getDataObjectById(this.foundations, this.order.foundation);
    },
    setting() {
      return getDataObjectById(this.settings, this.order.setting);
    },
    covering() {
      return getDataObjectById(this.coverings, this.order.platform_covering);
    },
    sputtering() {
      return getDataObjectById(this.sputterings, this.order.sputtering);
    },
    mountType() {
      return getDataObjectById(this.mountTypes, this.order.mount_type);
    },
  },
  methods: {
    ...mapActions(["orders/update"]),

    requestOrderUpdate: debounce(function () {
      if (this.order.discount.trim() == "") {
        this.order.discount = 0;
      }
      if (
        !this.order.additional_price ||
        this.order.additional_price.trim() == ""
      ) {
        this.order.additional_price = 0;
      }
      if (this.order.overheads.trim() == "") {
        this.order.overheads = 0;
      }
      if (this.order.delivery_cost.trim() == "") {
        this.order.delivery_cost = 0;
      }
      if (this.order.delivery_cost.trim() == "") {
        this.order.delivery_cost = 0;
      }
      if (this.order.item_painting_price.trim() == "") {
        this.order.item_painting_price = 0;
      }
      if (this.order.platform_base_square_meter_price.trim() == "") {
        this.order.platform_base_square_meter_price = 0;
      }
      if (this.order.platform_coating_square_meter_price.trim() == "") {
        this.order.platform_coating_square_meter_price = 0;
      }
      if (this.order.platform_coating_square_meter_price.trim() == "") {
        this.order.platform_coating_square_meter_price = 0;
      }
      this.order.vat = this.order.has_vat ? this.settings[0].vat : 0;
      this["orders/update"](this.order);
    }, 2000),

    round(value, precision) {
      const precisionMultiplier = Math.pow(10, precision);
      return (
        Math.round((value + Number.EPSILON) * precisionMultiplier) /
        precisionMultiplier
      )
        .toString()
        .replace(".", ",");
    },
    moneyFormat(value) {
      return moneyFormat(value);
    },
    dateFormat(value) {
      return dateFormat(value);
    },
    calculatePackagesPrice(packages) {
      let price = 0;
      for (const packageKey in packages) {
        price += packages[packageKey].price || 0;
      }
      return price;
    },
    calculateManufacturerCost(deliveryCost) {
      return (deliveryCost || 0) * 1.1 - deliveryCost;
    },
    calcuteSafeDeliveryDate(isoDate) {
      const date = new Date(isoDate);
      date.setDate(date.getDate() + 5);
      return date;
    },

    searchCounterparty(input) {
      return crmContactsService.query(input);
    },

    getCounterpartyLookupEntry(entry) {
      if (entry.first_name && entry.last_name) {
        return `${entry.first_name} ${entry.last_name}`;
      }
      return entry.name;
    },
  },

  watch: {
    "order.arrival_address": function () {
      this.requestOrderUpdate();
    },
    "order.derival_address": function () {
      this.requestOrderUpdate();
    },
    "order.delivery_info": function () {},
  },
};
</script>
