<template>
  <div>
    <CRow class="main-navigation"></CRow>

    <CRow>
      <CCol sm="9" style="min-width: 250px; min-height: 600px; max-width: none">
        <div
          @mousemove="handleMouseMove"
          @pointerdown="handlePointerDown"
          id="canvas"
          style="position: absolute; top: 0"
        >
          <CSpinner class="canvas-spinner" v-if="glbLoading" />
        </div>
      </CCol>

      <CCol sm="3" style="min-width: 250px; max-width: 300px">
        <CCard>
          <CCardBody>
            <CTabs @update:activeTab="changeOrderMode">
              <CTab>
                <template #title>
                  <img
                    v-c-tooltip="{
                      content:
                        'Управление площакдкой: размеры, материалы, сетка',
                      placement: 'top',
                      delay: '5',
                    }"
                    src="../assets/icons/grid-tab.png"
                    style="width: 16px; height: 16px"
                  />
                </template>

                <CRow>
                  <CCol class="pt-2 pb-2">
                    <CButton
                      @click="collapseFlat = !collapseFlat"
                      color="dark"
                      style="text-align: left; width: 100%"
                    >
                      <div class="info-icon">i</div>
                      Управление и данные
                    </CButton>
                  </CCol>
                </CRow>

                <CCollapse :show="collapseFlat">
                  <CListGroup sm="12" class="mt-1 c-list-group">
                    <CListGroupItem color="success">
                      Это режим для работы над площадкой
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Зажмите правую кнопку мыши для перетаскивания площадки
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Крутите колесо мыши для прииближения или удаления площадки
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Меняйте размер сетки задав ей ширину и длину в полях ниже
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Зажмите и тащите точку мышкой для изменения рамера
                      площадки
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Ctrl + Правая клавиша мыши на точке — добавит точку после
                      выбранной точки
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Shift + Правая клавиша мыши на точке — удалит точку
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Крупный шаг сетки равен 1м
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Мелкий шаг сетки равен 10см
                    </CListGroupItem>
                    <CListGroupItem color="info">
                      Вид основания площакди влияет на стоимость монтажа
                    </CListGroupItem>
                  </CListGroup>
                </CCollapse>

                <CRow class="mt-4">
                  <CCol sm="12">
                    Площадь площадки, м²: {{ orderPlatforms[0].area }}
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    Площадь сетки, м²: {{ order.max_length * order.max_width }}
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <CInput
                      label="Длина сетки, м"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="order.max_length"
                      @input="resizeGrid"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Ширина сетки, м"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="order.max_width"
                      @input="resizeGrid"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <div class="mb-1">Основание площадки</div>
                    <v-select
                      class="v-select w-100"
                      :options="foundations"
                      label="name"
                      :value="getOrderFoundation()"
                      @input="changeOrderFoundation"
                      placeholder=""
                      :clearSearchOnSelect="true"
                      :closeOnSelect="true"
                    >
                      <template style="width: 100%" #option="{ name }">
                        <div
                          class="list-item-container"
                          style="border-top: 1px solid #d8dbe0; display: flex"
                        >
                          <span>{{ name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol sm="12">
                    <div class="mb-1">Покрытие поверх основания</div>
                    <v-select
                      class="v-select w-100"
                      :options="coverings"
                      label="name"
                      :value="getOrderCovering()"
                      @input="changeOrderCovering"
                      placeholder=""
                      :clearSearchOnSelect="true"
                      :closeOnSelect="true"
                    >
                      <template style="width: 100%" #option="{ name }">
                        <div
                          class="list-item-container"
                          style="border-top: 1px solid #d8dbe0; display: flex"
                        >
                          <span>{{ name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </CRow>
              </CTab>

              <CTab active>
                <template #title>
                  <img
                    v-c-tooltip="{
                      content: 'Выбор комплексов и расстановка на площадке ',
                      placement: 'top',
                      delay: '5',
                    }"
                    src="../assets/icons/platform-tab.png"
                    style="width: 16px; height: 16px"
                  />
                </template>

                <CRow>
                  <CCol class="pt-2 pb-2">
                    <CButton
                      @click="collapseChoiceModel = !collapseChoiceModel"
                      color="dark"
                      style="text-align: left; width: 100%"
                    >
                      <div class="info-icon">i</div>
                      Управление и данные
                    </CButton>
                  </CCol>
                </CRow>
                <CCollapse :show="collapseChoiceModel">
                  <CListGroup sm="12" class="c-list-group">
                    <CListGroupItem color="success">
                      Это режим для расстановки моделей
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Выбраная модель появляется в центре площадки
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Зажмите левую клавишу мышки и перетащите модель
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Крутите колесо мыши для прииближения или удаления площадки
                    </CListGroupItem>
                    <CListGroupItem color="danger">
                      Красная подсветка включается при нарушении зон
                      безопасности расстановки моделей
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Перейдите в режим работы с площадкой для изменения размера
                      и фокуса самой площадки
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Тип крепления влияет на стоимость оборудования
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Тип крепления фланцы используется если нет возможности
                      вкапать столбы
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Цвет комплексов задан по умолчанию, изменение цвета
                      увеличит стоимость
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Цинковое напыление используется для агрессивной влажной
                      среды.
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Для цинковых комплексов нельзя выбрать цвет.
                    </CListGroupItem>
                    <CListGroupItem color="info">
                      Для работы над конкретным комплексом или его удаления
                      перейдите во вкладку с редактированием комплексов
                    </CListGroupItem>
                  </CListGroup>
                </CCollapse>

                <CRow>
                  <CCol sm="12" class="pt-2 pb-2">
                    <div role="group" class="form-group">
                      <v-select
                        class="v-select w-100"
                        label="code"
                        :options="
                          equipmentItems.filter(
                            (equipmentItem) => equipmentItem.visible
                          )
                        "
                        placeholder="Выберите комплекс..."
                        :clearSearchOnSelect="true"
                        :closeOnSelect="true"
                      >
                        <template #option="item" class="w-100">
                          <div
                            class="list-item-container list-equipment-item"
                            style="border-top: 1px solid #d8dbe0"
                            @mousedown="addEquipmentItemToOrder(item)"
                          >
                            <h3>{{ item.code }}</h3>
                            <p>{{ item.name }}</p>
                            <img
                              class="image-scale"
                              alt="equipment-item"
                              :src="item.model_preview"
                            />
                            <button class="c-button">добавить</button>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol sm="12">
                    <div role="group" class="form-group">
                      <div>Крепление</div>
                      <v-select
                        class="v-select"
                        style="width: 100%"
                        label="name"
                        :value="getOrderMountType()"
                        @input="changeOrderMountType"
                        :options="mountTypes"
                        placeholder=""
                        :clearSearchOnSelect="true"
                        :closeOnSelect="true"
                      >
                      </v-select>
                    </div>
                    <div role="group" class="form-group">
                      <div>Напыление</div>
                      <v-select
                        class="v-select"
                        style="width: 100%"
                        label="name"
                        :options="sputterings"
                        :value="getOrderSputtering()"
                        @input="changeOrderSputtering"
                        placeholder=""
                        :clearSearchOnSelect="true"
                        :closeOnSelect="true"
                      >
                      </v-select>
                    </div>
                    <div>Цвета деталей комплекса</div>
                    <div
                      :style="{
                        width: '100%',
                        border: '1px solid Silver',
                        backgroundColor: `${getCurrentOrderBarColorRALHex()}`,
                      }"
                    >
                      <div class="change-color">
                        <div v-if="order.sputtering == 2">
                        <span>&nbsp;</span
                        >{{ getOrderBarColorRALCode() }}-столбы
                      </div>
                        <div v-else>
                        <span>&nbsp;</span
                        >{{ getOrderBarColorRALCode() }}-столбы и перекладины
                      </div>
                      </div>
                    </div>
                    <div
                      :style="{
                        width: '100%',
                        border: '1px solid Silver',
                        backgroundColor: `${getCurrentOrderClampColorRALHex()}`,
                      }"
                    >
                      <div class="change-color">
                        <div v-if="order.sputtering == 2">
                        <span>&nbsp;</span
                        >{{ getOrderClampColorRALCode() }}-хомуты и перекладины
                      </div>
                        <div v-else>
                        <span>&nbsp;</span
                        >{{ getOrderClampColorRALCode() }}-хомуты
                      </div>
                    </div>
                    </div>

                    <div v-if="order.sputtering !== 2"> 
                      <br />
                      <input type="checkbox" id="checkbox" v-model="painting" />
                      <label for="checkbox"
                        ><span>&nbsp;</span>Цвета заказчика</label
                      >
        
                    

                    <div v-if="painting">
                      <CRow>
                        <CCol sm="12">
                          <div role="group" class="form-group">
                            <div>Цвет столбов и перекладин</div>
                            <v-select
                              class="v-select"
                              style="width: 100%"
                              label="ral_code"
                              :value="getOrderBarColorRALCode()"
                              @input="changeOrderBarColor"
                              :options="colors"
                              placeholder=""
                              :clearSearchOnSelect="true"
                              :closeOnSelect="true"
                            >
                              <template
                                style="width: 100%"
                                #option="{ ral_code, hex_code }"
                              >
                                <div
                                  class="list-item-container"
                                  style="
                                    border-top: 1px solid #d8dbe0;
                                    display: flex;
                                  "
                                >
                                  <div style="width: 70px">
                                    <span>{{ ral_code }}</span>
                                  </div>
                                  <div
                                    :style="{
                                      width: '30px',
                                      height: '30px',
                                      backgroundColor: `#${hex_code}`,
                                    }"
                                  ></div>
                                </div>
                              </template>
                            </v-select>
                          </div>
                          <div role="group" class="form-group"></div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="12">
                          <div role="group" class="form-group">
                            <div>Цвет хомутов</div>
                            <v-select
                              class="v-select"
                              style="width: 100%"
                              label="ral_code"
                              :value="getOrderClampColorRALCode()"
                              :options="colors"
                              placeholder=""
                              :clearSearchOnSelect="true"
                              :closeOnSelect="true"
                              @input="changeOrderClampColor"
                            >
                              <template
                                style="width: 100%"
                                #option="{ ral_code, hex_code }"
                              >
                                <div
                                  class="list-item-container"
                                  style="
                                    border-top: 1px solid #d8dbe0;
                                    display: flex;
                                  "
                                >
                                  <div style="width: 70px">
                                    <span>{{ ral_code }}</span>
                                  </div>
                                  <div
                                    :style="{
                                      width: '30px',
                                      height: '30px',
                                      backgroundColor: `#${hex_code}`,
                                    }"
                                  ></div>
                                </div>
                              </template>
                            </v-select>
                          </div>
                          <div role="group" class="form-group"></div>
                        </CCol>
                      </CRow>
                    </div>
                    </div>
                    <div role="group" class="form-group" style="padding-top:15px">
                      <span>Скрыть зоны безопасности</span>
                      <CSwitch
                        class="ml-3"
                        color="primary"
                        style="vertical-align: bottom"
                        :checked.sync="safeZoneMarkersVisible"
                        @update:checked="updateSafeZoneMarkersVisibility"
                      />
                    </div>
                    <div role="group" class="form-group">
                      <span>Скрыть коды комплексов</span>
                      <CSwitch
                        class="ml-3"
                        color="primary"
                        style="vertical-align: bottom"
                        :checked.sync="orderItemCodesVisible"
                        @update:checked="updateOrderItemCodesVisibility"
                      />
                    </div>
                  </CCol>
                </CRow>

                <div class="mt-4" v-if="currentOrderItem">
                  <div>
                    <CButton @click="removeCurrentOrderItem" color="danger">
                      удалить комплекс
                    </CButton>
                  </div>

                  <div class="mt-4 mb-1">Угол поворота</div>
                  <AngleControls
                    v-model="currentOrderItem.meta.modelGroup.rotation.y"
                    @input="requestOrderItemUpdate(currentOrderItem, true)"
                  />
                </div>
              </CTab>

              <CTab>
                <template #title>
                  <svg
                    v-c-tooltip="{
                      content:
                        'Редактирование комплекса: добавление, удаление деталей. Удаление комплекса',
                      placement: 'top',
                    }"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="isolation: isolate; width: 16px; height: 16px"
                    viewBox="0 0 64 64"
                    width="16pt"
                    height="64pt"
                  >
                    <defs>
                      <clipPath id="_clipPath_iCxZZOLpLRQZrC3CogR3PMRSCD3hPvFF">
                        <rect width="64" height="64" />
                      </clipPath>
                    </defs>
                    <g
                      clip-path="url(#_clipPath_iCxZZOLpLRQZrC3CogR3PMRSCD3hPvFF)"
                    >
                      <g>
                        <path
                          d=" M 3 53 L 61 53 L 61 56 L 3 56 L 3 53 Z "
                          fill="rgb(0,0,0)"
                          vector-effect="non-scaling-stroke"
                          stroke-width="0.1"
                          stroke="rgb(0,0,0)"
                          stroke-linejoin="miter"
                          stroke-linecap="square"
                          stroke-miterlimit="3"
                        />
                        <path
                          d=" M 10 52 L 10 22.979 Q 11.441 21.119 12.804 22.979 L 12.804 52 L 10 52 Z  M 30.098 52 L 30.098 9.366 Q 31.539 6.634 32.902 9.366 L 32.902 52 L 30.098 52 Z  M 50.196 52 L 50.196 9.366 Q 51.637 6.634 53 9.366 L 53 52 L 50.196 52 Z  M 30.098 11.745 L 53 11.745 L 53 13.617 L 30.098 13.617 L 30.098 11.745 Z  M 10 24.851 L 32.902 24.851 L 32.902 26.723 L 10 26.723 L 10 24.851 Z "
                          fill-rule="evenodd"
                          fill="rgb(0,0,0)"
                          vector-effect="non-scaling-stroke"
                          stroke-width="0.1"
                          stroke="rgb(0,0,0)"
                          stroke-linejoin="miter"
                          stroke-linecap="square"
                          stroke-miterlimit="3"
                        />
                      </g>
                    </g>
                  </svg>
                </template>

                <CRow>
                  <CCol class="pt-2 pb-2">
                    <CButton
                      @click="collapseChoiceModel = !collapseChoiceModel"
                      color="dark"
                      style="text-align: left; width: 100%"
                    >
                      <div class="info-icon">i</div>
                      Управление и данные
                    </CButton>
                  </CCol>
                </CRow>
                <CCollapse :show="collapseChoiceModel">
                  <CListGroup sm="12" class="c-list-group">
                    <CListGroupItem color="success">
                      Это режим для изменения состава конкретного комплекса.
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Зажмите правую кнопку мыши для перетаскивания площадки к
                      фокусу на нужном комплексе.
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Крутите колесо мыши для прииближения или удаления
                      площадки.
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Зажмите левую клавишу мыши для вращения площадки с
                      комплексом.
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Выделите мышкой любую деталь комплекса, который нужно
                      удалить. Удалите комплекс по кнопке.
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Выделите мышкой конкретную деталь комплекса, которую нужно
                      удалить. Скройте ее через переключатель «Показать».
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Для добавления детали комплекса, выберите деталь из списка
                      деталей.
                    </CListGroupItem>
                    <CListGroupItem color="dark">
                      Зажимтие Shift и стрекли направления движения для быстрого
                      перемещения детали по площадке.
                    </CListGroupItem>
                    <CListGroupItem color="success">
                      Для более точной и медленой подгонки детали используйте
                      стрелочки без Shift.
                    </CListGroupItem>
                    <CListGroupItem color="info">
                      Координата «У» отвечает за перемещение детали вверх-вниз,
                      используйте ее осторожно, так как большинство деталей уже
                      имеет нужную высоту при добавлении.
                    </CListGroupItem>
                  </CListGroup>
                </CCollapse>

                <div role="group" class="form-group mt-4">
                  <v-select
                    class="v-select"
                    style="width: 100%"
                    label="code"
                    :value="currentOrderItemLabel"
                    :options="orderItems"
                    placeholder="Комплексы"
                    @input="changeCurrentOrderItem"
                    :clearSearchOnSelect="true"
                    :closeOnSelect="true"
                  >
                    <template class="w-100" #option="{ code, equipmentItem }">
                      <div class="list-item-container-row">
                        <h6 class="grid-h6-row pt-3">
                          {{ code }}
                        </h6>
                        <img
                          style="
                            grid-row: 1/2;
                            grid-column: 2;
                            width: 100%;
                            alian-self: center;
                            display: grid;
                          "
                          class="image-scale-small"
                          :src="equipmentItem.model_preview"
                        />
                      </div>
                    </template>
                  </v-select>
                </div>
                <div
                  v-if="currentOrderItem"
                  role="group"
                  class="form-group mt-2"
                >
                  <div>
                    <CButton @click="removeCurrentOrderItem" color="danger">
                      удалить комплекс
                    </CButton>
                  </div>

                  <div class="mt-4" v-if="!currentOrderItemPart">
                    <div
                      v-for="axis in ['x', 'y', 'z']"
                      :key="axis"
                      class="mb-2"
                    >
                      <label class="mr-4">{{ axis.toUpperCase() }}</label>
                      <vue-numeric-input
                        v-model="
                          currentOrderItem.meta.modelGroup.position[axis]
                        "
                        :step="0.005"
                        @input="requestOrderItemUpdate(currentOrderItem, true)"
                      />
                    </div>

                    <div class="mb-1">Угол поворота</div>
                    <AngleControls
                      v-model="currentOrderItem.meta.modelGroup.rotation.y"
                      @input="requestOrderItemUpdate(currentOrderItem)"
                    />
                  </div>

                  <div class="mt-5 mb-1">Редактировать деталь</div>
                  <v-select
                    class="v-select"
                    style="width: 100%"
                    label="key"
                    :value="currentOrderItemPartLabel"
                    :options="currentOrderItem.parts"
                    placeholder="Детали"
                    :getOptionKey="(item) => item.key"
                    @input="changeCurrentOrderItemPart"
                    :clearSearchOnSelect="true"
                    :closeOnSelect="true"
                  >
                    <template class="w-100" #option="{ equipmentPart }">
                      <div class="list-item-container-row">
                        <h6 class="grid-h6-row pt-3">
                          {{ equipmentPart.code }}
                        </h6>
                        <p class="grid-p-row">
                          {{ equipmentPart.description }}
                        </p>
                        <img
                          class="grid-image-row"
                          :src="equipmentPart.model_preview"
                        />
                      </div>
                    </template>
                  </v-select>
                </div>

                <div v-if="currentOrderItemPart" class="mt-4">
                  <div v-for="axis in ['x', 'y', 'z']" :key="axis" class="mb-2">
                    <label class="mr-4">{{ axis.toUpperCase() }}</label>
                    <vue-numeric-input
                      v-model="currentOrderItemPart.meshGroup.position[axis]"
                      :step="0.005"
                      @input="requestCurrentOrderItemPartUpdate(true)"
                    />
                  </div>

                  <div class="mt-3 mb-1">Угол поворота</div>
                  <AngleControls
                    v-model="currentOrderItemPart.meshGroup.rotation.y"
                    @input="requestCurrentOrderItemPartUpdate(true)"
                  />

                  <div class="mt-4">
                    <span>{{
                      currentOrderItemPart.visible ? "Удалить" : "Вернуть"
                    }}</span>
                    <CSwitch
                      class="ml-3"
                      color="primary"
                      style="vertical-align: bottom"
                      :checked.sync="currentOrderItemPart.visible"
                      @update:checked="changeCurrentOrderItemPartVisibility"
                    />
                  </div>
                </div>

                <div
                  v-if="currentOrderItem"
                  role="group"
                  class="form-group mt-4"
                >
                  <div class="mb-1">Добавить деталь</div>
                  <v-select
                    class="v-select"
                    style="width: 100%"
                    label="code"
                    :options="equipmentParts.filter((item) => !item.virtual)"
                    placeholder="Детали"
                    :getOptionKey="(item) => item.code"
                    @input="addEquipmentPart"
                    :clearSearchOnSelect="true"
                    :closeOnSelect="true"
                  >
                    <template
                      class="w-100"
                      #option="{ code, description, model_preview }"
                    >
                      <div class="list-item-container-row">
                        <h6 class="grid-h6-row pt-3 green">
                          {{ code }}
                        </h6>
                        <p class="grid-p-row">
                          {{ description }}
                        </p>
                        <img class="grid-image-row" :src="model_preview" />
                      </div>
                    </template>
                  </v-select>
                </div>
                <div>
                  <CButton
                    v-if="locationHistory.length > 0"
                    @click="undoLastLocationChange"
                    color="danger"
                  >
                    Отменить последнее перемещение
                  </CButton>
                </div>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<style lang="scss">
.change-color {
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0.5px black;
  filter: invert(1);
  background-color: inherit;
}

html,
body {
  margin: 0;
}

.style-chooser .vs__state-active-bg {
  background: red;
}

#canvas {
  display: block;
  min-width: 500px;
  min-height: 80vh;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.canvas-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.list-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content 50px;
  justify-content: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px;
  column-gap: 5px;
}

.c-button {
  min-width: min-content;
  appearance: none;
  border: 0;
  background: #4d4d4d;
  color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0.25rem;
}

.c-button:hover {
  background: #000;
}

.c-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.3);
}

.image-showcase {
  grid-row: 1/5;
  grid-column: 1;

  width: 150px;
}

.v-select .vs__dropdown-option--highlight {
  background: #fff;
  color: #4d4d4d;
}

.list-equipment-item {
  h3 {
    margin: 0;
    grid-row: 1;
    grid-column: 1;
    font-size: 18px;
    grid-auto-rows: minmax(50px, auto);
  }

  p {
    grid-row: 2;
    grid-column: 1/3;
    white-space: normal;
    grid-auto-rows: minmax(50px, auto);
    font-size: 10px;
    margin-bottom: 5px;
  }

  img {
    grid-row: 1/2;
    grid-column: 2;
    width: 100%;
    display: grid;
  }

  button {
    padding: 3px 5px;
    grid-row: 3;
    grid-column: 1/3;
    font-size: 14px;
  }
}
</style>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import {
  CSS2DObject,
  CSS2DRenderer,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { OBB } from "three/examples/jsm/math/OBB.js";
import { mapActions } from "vuex";
import { PlaneHelper } from "../3d/PlaneHelper.js";
import { orderItemsService } from "../_services";

import vSelect from "vue-select";
import { debounce } from "lodash";
import { getDataObjectById } from "../_helpers";
import AngleControls from "./inputs/angle-controls";
import { calculateDistanceBetweenPointAndLine } from "@/utils";
import TextSprite from "@seregpie/three.text-sprite";
import { russianStringToFloat } from "../utils.js";

String.prototype.rsplit = function (sep, maxsplit) {
  let split = this.split(sep);
  return maxsplit
    ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
    : split;
};

const FLAT_CAMERA_DEF_Y = 20;

export default {
  name: "Plan",
  props: {
    user: Object,
    order: Object,
    orderItems: Array,
    orderPlatforms: Array,
    equipmentItems: Array,
    equipmentParts: Array,
    colors: Array,
    coverings: Array,
    foundations: Array,
    mountTypes: Array,
    sputterings: Array,
    dictionaryColor: Array,
  },
  data() {
    return {
      el: null,
      webGLRenderer: null,
      cssRenderer: null,
      raycaster: null,
      gltfLoader: null,
      textureLoader: null,
      scene: null,
      collapseFlat: false,
      collapseChoiceModel: false,
      collapseChangeModel: false,
      painting: false,
      cameras: {
        flat: null,
        perspective: null,
        active: null,
      },
      lights: {
        plan: [],
        view: [],
      },
      controls: {
        flat: null,
        perspective: null,
      },
      mode: "platform-edit", // 'platform-edit' | 'order-item-placement' | 'order-item-edit'
      platformsMeta: [],
      mouse: { x: 0, y: 0 },
      poinerLabel: null,
      grid: null,
      gridCollider: null,
      glbLoading: false,
      flags: {
        canMoveCamera: false,
      },
      loadedFlags: {
        orderItems: false,
        orderPlatforms: false,
      },
      intersectedObject: null,
      keyboardModifiers: {
        shift: false,
        control: false,
        alt: false,
      },
      geometries: {
        platformDot: null,
        gridCollider: null,
      },
      palette: {
        validSafeZoneMarkerPlacementColor: null,
        invalidSafeZoneMarkerPlacementColor: null,
        platformBoundingSphere: null,
        platformDot: null,
        bar: null,
        pillar: null,
        cap: null,
        clamp: null,
        wood: null,
        textile: null,
        zinc: null,
        black: null,
      },
      materials: {
        validSafeZoneMarker: null,
        invalidSafeZoneMarker: null,
        platformDot: null,
        platform: null,
        cap: null,
        bar: null,
        clamp: null,
        wood: null,
        textile: null,
        zinc: null,
        black: null,
      },
      storeSubscription: null,
      currentOrderItem: null,
      currentOrderItemPart: null,
      newOrderItems: [],
      detailMeshes: [],
      animationFrameId: null,

      transformControls: null,
      transformingObject: null,

      destroyed: false,
      locationHistory: [],
      safeZoneMarkersVisible: false,
      orderItemCodesVisible: false,
    };
  },
  components: { AngleControls, vSelect },
  computed: {
    currentOrderItemLabel() {
      if (this.currentOrderItem) {
        return this.currentOrderItem.code;
      }
      return "";
    },
    currentOrderItemPartLabel() {
      if (this.currentOrderItemPart) {
        return this.currentOrderItemPart.key;
      }
      return "";
    },
    sputtering() {
      return getDataObjectById(this.sputterings, this.order.sputtering);
    },
  },
  created() {
    // костыль
    document.planComponent = this;

    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "ui/minimizeSidebar") {
        this.updateCanvasSize();
      } else if (mutation.type === "ui/maximizeSidebar") {
        this.updateCanvasSize();
      } else if (mutation.type === "orderItems/createSuccess") {
        this.newOrderItems.push(mutation.payload.response.id);
      } else if (mutation.type === "orderItems/deletePartSuccess") {
        for (const orderItem of this.orderItems) {
          if (orderItem.id === mutation.payload.response.id) {
            orderItem.equipment_price =
              mutation.payload.response.equipment_price;
            orderItem.weight = mutation.payload.response.weight;
            orderItem.equipment_parts =
              mutation.payload.response.equipment_parts;
            break;
          }
        }
      } else if (mutation.type === "orderItems/addOrUpdatePartSuccess") {
        for (const orderItem of this.orderItems) {
          if (orderItem.id === mutation.payload.response.id) {
            orderItem.equipment_price =
              mutation.payload.response.equipment_price;
            orderItem.weight = mutation.payload.response.weight;
            orderItem.equipment_parts =
              mutation.payload.response.equipment_parts;
            break;
          }
        }
      }
      if (mutation.type === "dictionaryCoverings/getAllSuccess") {
        // this.textureLoader.load(
        //   this.dictionaryCoverings[1].texture,
        //   (texture) => {
        //     texture.repeat.set(1, 1);
        //     texture.wrapS = THREE.RepeatWrapping;
        //     texture.wrapT = THREE.RepeatWrapping;
        //     const material = new THREE.MeshBasicMaterial({
        //       map: texture,
        //       side: THREE.DoubleSide,
        //     });
        //     for (const platformMeta of this.platformsMeta) {
        //       platformMeta.plane.material = material;
        //     }
        //   }
        // );
      }
    });
    window.addEventListener("keydown", this.registerKeyboardModifier);
    window.addEventListener("keyup", this.unregisterKeyboardModifier);
    window.addEventListener("resize", this.updateCanvasSize);
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
    cancelAnimationFrame(this.animationFrameId);
    this.dispose();
    this.destroyed = true;
    window.removeEventListener("keydown", this.registerKeyboardModifier);
    window.removeEventListener("keyup", this.unregisterKeyboardModifier);
    window.removeEventListener("resize", this.updateCanvasSize);
  },
  methods: {
    ...mapActions([
      "orders/update",
      "orderItems/create",
      "orderItems/update",
      "orderItems/delete",
      "orderItems/addOrUpdatePart",
      "orderItems/deletePart",
      "orderPlatforms/update",
      "dictionaryColor/getAll",
      "dictionaryCoverings/getAll",
      "dictionaryMountTypes/getAll",
      "dictionarySputterings/getAll",
    ]),
    handleMouseMove($event) {
      if (this.destroyed) return;

      this.updateMouseCoordinates($event);
      this.raycaster.setFromCamera(this.mouse, this.cameras.active);
      this.intersectedObject = null;
      for (const orderItem of this.orderItems) {
        if (!orderItem.meta.safeZoneMarker) {
          continue;
        }
        const intersections = this.raycaster.intersectObjects([
          orderItem.meta.safeZoneMarker,
        ]);
        if (intersections.length > 0) {
          this.intersectedObject = orderItem;
          break;
        }
      }
      if (this.mode === "platform-edit") {
        const intersections = this.raycaster.intersectObjects([
          this.gridCollider,
        ]);
        if (intersections.length > 0) {
          const intersection = intersections[0];
          this.updatePointerLabelPosition(
            intersection.point.x,
            intersection.point.z
          );
        } else {
          this.pointerLabel.position.set(0.0, 0.3, 0.0);
          this.pointerLabel.userData.element.textContent = "";
        }
      }
    },

    initCanvas() {
      this.el = document.getElementById("canvas");
      this.el.addEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
      this.viewportWidth = this.el.clientWidth;
      this.viewportHeight = this.el.clientHeight;
    },

    updateCanvasSize() {
      this.viewportWidth = this.el.clientWidth;
      this.viewportHeight = this.el.clientHeight;
      this.updateFlatCameraBounds();
      this.cameras.perspective.aspect =
        this.viewportWidth / this.viewportHeight;
      this.cameras.perspective.updateProjectionMatrix();

      this.webGLRenderer.setSize(this.viewportWidth, this.viewportHeight);
      this.cssRenderer.setSize(this.viewportWidth, this.viewportHeight);
    },

    initWebGLRenderer() {
      this.webGLRenderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      this.webGLRenderer.physicallyCorrectLights = true;
      this.webGLRenderer.outputEncoding = THREE.sRGBEncoding;
      this.webGLRenderer.setClearColor(0xf9f9f9);
      this.webGLRenderer.setPixelRatio(window.devicePixelRatio);
      this.webGLRenderer.setSize(this.viewportWidth, this.viewportHeight);
      this.el.appendChild(this.webGLRenderer.domElement);
    },

    initCSSRenderer() {
      this.cssRenderer = new CSS2DRenderer();
      this.cssRenderer.setSize(this.viewportWidth, this.viewportHeight);
      this.cssRenderer.domElement.style.position = "absolute";
      this.cssRenderer.domElement.style.top = "0px";
      this.cssRenderer.domElement.style.pointerEvents = "none";
      this.el.appendChild(this.cssRenderer.domElement);
    },

    initScene() {
      this.scene = new THREE.Scene();
    },

    initLights() {
      const ambientLight = new THREE.AmbientLight(0xffffff);

      const dirLight = new THREE.DirectionalLight(0xffffff, 1);
      dirLight.position.set(1, 1, 1);

      const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemisphereLight.position.set(0, 20, 0);

      this.lights.plan.push(ambientLight);
      this.lights.view.push(dirLight);
      this.lights.view.push(hemisphereLight);

      this.scene.add(...this.lights.plan);
      this.scene.add(...this.lights.view);

      this.lights.plan.forEach((l) => (l.visible = false));
      this.lights.view.forEach((l) => (l.visible = false));
    },

    switchToPlanLights() {
      this.lights.plan.forEach((l) => (l.visible = true));
      this.lights.view.forEach((l) => (l.visible = false));
    },
    switchToViewLights() {
      this.lights.plan.forEach((l) => (l.visible = false));
      this.lights.view.forEach((l) => (l.visible = true));
    },

    initModelLoader() {
      THREE.ImageUtils.crossOrigin = "";
      const saveThis = this;
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function () {};

      loadingManager.onError = function () {
        saveThis.glbLoading = false;
      };

      this.gltfLoader = new GLTFLoader(loadingManager);
    },

    initTextureLoader() {
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function () {};

      loadingManager.onError = function () {};

      this.textureLoader = new THREE.TextureLoader(loadingManager);
    },

    getFlatCameraBounds() {
      const maxWidth = parseFloat(this.order.max_width);
      const maxLength = parseFloat(this.order.max_length);
      const maxSize = maxWidth > maxLength ? maxWidth : maxLength;
      const cameraBounds = (maxSize / 2) * 1.2;

      const width = this.viewportWidth;
      const height = this.viewportHeight;
      const aspectRatio = width / height;
      let left = -cameraBounds;
      let right = cameraBounds;
      let top = cameraBounds;
      let bottom = -cameraBounds;
      if (aspectRatio > 0) {
        left = -cameraBounds * aspectRatio;
        right = cameraBounds * aspectRatio;
      }
      if (aspectRatio < 0) {
        top = cameraBounds * aspectRatio;
        bottom = -cameraBounds * aspectRatio;
      }
      return {
        left,
        right,
        top,
        bottom,
      };
    },

    initFlatCamera() {
      const flatCameraBounds = this.getFlatCameraBounds();

      this.cameras.flat = new THREE.OrthographicCamera(
        flatCameraBounds.left,
        flatCameraBounds.right,
        flatCameraBounds.top,
        flatCameraBounds.bottom,
        0.001,
        100
      );

      this.cameras.flat.position.y = FLAT_CAMERA_DEF_Y;
      this.cameras.flat.lookAt(0, 0, 0);
      this.cameras.flat.updateProjectionMatrix();
    },

    updateFlatCameraBounds() {
      const flatCameraBounds = this.getFlatCameraBounds();
      this.cameras.flat.left = flatCameraBounds.left;
      this.cameras.flat.right = flatCameraBounds.right;
      this.cameras.flat.top = flatCameraBounds.top;
      this.cameras.flat.bottom = flatCameraBounds.bottom;
      this.cameras.flat.position.y = FLAT_CAMERA_DEF_Y;
      this.cameras.flat.lookAt(0, 0, 0);
      this.cameras.flat.updateProjectionMatrix();
    },

    initPerspectiveCamera() {
      const width = this.viewportWidth;
      const height = this.viewportHeight;
      this.cameras.perspective = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.001,
        10000
      );
      this.cameras.perspective.position.y = 1;
      this.cameras.perspective.position.z = 10;
      this.cameras.perspective.updateProjectionMatrix();
    },

    initFlatControls() {
      this.controls.flat = new OrbitControls(
        this.cameras.flat,
        this.webGLRenderer.domElement
      );
      this.controls.flat.enableRotate = false;
      this.controls.flat.update();
    },

    initPerspectiveControls() {
      this.controls.perspective = new OrbitControls(
        this.cameras.perspective,
        this.webGLRenderer.domElement
      );
      this.controls.perspective.minPolarAngle = -Math.PI * 0.49;
      this.controls.perspective.maxPolarAngle = Math.PI * 0.49;
      this.controls.perspective.enabled = false;
      this.controls.perspective.update();
    },

    initGeometries() {
      this.geometries.platformDot = new THREE.SphereGeometry(0.1, 10, 10);
      this.geometries.platformDotHandle = new THREE.SphereGeometry(0.3, 10, 10);
    },

    disposeGeometries() {
      this.geometries.platformDot.dispose();
      this.geometries.platformDotHandle.dispose();
      this.scene.traverse((object) => {
        if (object.geometry) {
          object.geometry.dispose();
        }
      });
    },

    initPalette() {
      this.palette.validSafeZoneMarkerPlacementColor = new THREE.Color();
      this.palette.validSafeZoneMarkerPlacementColor.setHSL(3 / 8, 1, 0.5);

      this.palette.invalidSafeZoneMarkerPlacementColor = new THREE.Color();
      this.palette.invalidSafeZoneMarkerPlacementColor.setHSL(0 / 8, 1, 0.5);

      this.palette.platformDot = new THREE.Color(0xffffff);
      this.palette.platform = new THREE.Color(0xffffff);

      this.palette.bar = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 7016"))
      );
      this.palette.pillar = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 7016"))
      );
      this.palette.cap = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 7016"))
      );
      this.palette.clamp = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 5018"))
      );
      this.palette.zinc = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 9003"))
      );
      this.palette.black = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 9005"))
      );
      this.palette.wood = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 8017"))
      );
      this.palette.textile = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 1015"))
      );
    },

    initMaterials() {
      const textureLoader = new THREE.TextureLoader();
      const sandTexture = textureLoader.load("/textures/sand-1024.jpg");
      sandTexture.wrapS = sandTexture.wrapT = THREE.RepeatWrapping;

      this.materials.validSafeZoneMarker = new THREE.MeshPhongMaterial({
        color: this.palette.validSafeZoneMarkerPlacementColor,
        opacity: 0.5,
        transparent: true,
        emissive: 0xffff00,
        emissiveIntensity: 0,
      });
      this.materials.invalidSafeZoneMarker = new THREE.MeshPhongMaterial({
        color: this.palette.invalidSafeZoneMarkerPlacementColor,
        opacity: 0.5,
        transparent: true,
        emissive: 0xffff00,
        emissiveIntensity: 0,
      });
      this.materials.collider = new THREE.MeshPhongMaterial({
        color: this.palette.platform,
        opacity: 0,
        transparent: true,
      });
      this.materials.testCollider = new THREE.MeshPhongMaterial({
        color: this.palette.platform,
        opacity: 0.5,
        transparent: true,
      });
      this.materials.platformDot = new THREE.MeshBasicMaterial({
        color: this.palette.platformDot,
      });
      this.materials.platform = new THREE.MeshBasicMaterial({
        map: sandTexture,
        side: THREE.DoubleSide,
      });
      this.materials.bar = new THREE.MeshStandardMaterial({
        color: this.palette.bar,
        side: THREE.DoubleSide,
      });
      this.materials.pillar = new THREE.MeshStandardMaterial({
        color: this.palette.pillar,
        side: THREE.DoubleSide,
      });
      this.materials.cap = new THREE.MeshStandardMaterial({
        color: this.palette.cap,
        side: THREE.DoubleSide,
      });
      this.materials.clamp = new THREE.MeshStandardMaterial({
        color: this.palette.clamp,
        side: THREE.DoubleSide,
      });
      this.materials.zinc = new THREE.MeshStandardMaterial({
        color: this.palette.zinc,
        side: THREE.DoubleSide,
      });
      this.materials.black = new THREE.MeshStandardMaterial({
        color: this.palette.black,
        side: THREE.DoubleSide,
      });
      this.materials.wood = new THREE.MeshStandardMaterial({
        color: this.palette.wood,
        side: THREE.DoubleSide,
      });
      this.materials.textile = new THREE.MeshStandardMaterial({
        color: this.palette.textile,
        side: THREE.DoubleSide,
      });
    },

    initOrderColors() {
      if (this.order.bar_color) {
        this.palette.bar.setHex(
          this.colorToHex(this.getColorById(this.order.bar_color))
        );
        this.materials.bar.color = this.palette.bar;
      }
      if (this.order.pillar_color) {
        this.palette.pillar.setHex(
          this.colorToHex(this.getColorById(this.order.pillar_color))
        );
        this.materials.pillar.color = this.palette.pillar;
      }
      if (this.order.cap_color) {
        this.palette.cap.setHex(
          this.colorToHex(this.getColorById(this.order.cap_color))
        );
        this.materials.cap.color = this.palette.cap;
      }
      if (this.order.clamp_color) {
        this.palette.clamp.setHex(
          this.colorToHex(this.getColorById(this.order.clamp_color))
        );
        this.materials.clamp.color = this.palette.clamp;
      }
      if (this.order.wood_color) {
        this.palette.wood.setHex(
          this.colorToHex(this.getColorById(this.order.wood_color))
        );
        this.materials.wood.color = this.palette.wood;
      }
      if (this.order.textile_color) {
        this.palette.textile.setHex(
          this.colorToHex(this.getColorById(this.order.textile_color))
        );
        this.materials.textile.color = this.palette.textile;
      }
    },

    disposeMaterials() {
      this.materials.validSafeZoneMarker.dispose();
      this.materials.invalidSafeZoneMarker.dispose();
      this.materials.collider.dispose();
      this.materials.testCollider.dispose();
      this.materials.platformDot.dispose();
      this.materials.platform.dispose();
      this.materials.bar.dispose();
      this.materials.pillar.dispose();
      this.materials.cap.dispose();
      this.materials.clamp.dispose();
      this.materials.wood.dispose();
      this.materials.textile.dispose();
      this.materials.zinc.dispose();
      this.materials.black.dispose();
    },

    initRaycaser() {
      this.raycaster = new THREE.Raycaster();
    },

    initGrid() {
      const gridWidth = russianStringToFloat(this.order.max_length);
      const gridLength = russianStringToFloat(this.order.max_width);
      this.grid = new PlaneHelper(gridWidth, gridLength);
      this.grid.position.y = 0.01;
      this.scene.add(this.grid);

      const halfWidth = gridWidth / 2;
      const halfLength = gridLength / 2;
      const gridColliderShape = new THREE.Shape();
      gridColliderShape.moveTo(-halfWidth, -halfLength);
      gridColliderShape.lineTo(halfWidth, -halfLength);
      gridColliderShape.lineTo(halfWidth, halfLength);
      gridColliderShape.lineTo(-halfWidth, halfLength);
      gridColliderShape.lineTo(-halfWidth, -halfLength);

      this.geometries.gridCollider = new THREE.ShapeGeometry(gridColliderShape);
      this.gridCollider = new THREE.Mesh(
        this.geometries.gridCollider,
        this.materials.collider
      );
      this.gridCollider.rotation.x = -(Math.PI / 2);
      this.grid.position.y = 0.02;
      this.scene.add(this.gridCollider);
    },

    disposeGrid() {
      if (this.grid) {
        this.scene.remove(this.grid);
        this.grid = null;
      }
      if (this.gridCollider) {
        this.scene.remove(this.gridCollider);
        this.gridCollider = null;
      }
      if (this.geometries.gridCollider) {
        this.geometries.gridCollider.dispose();
        this.geometries.gridCollider = null;
      }
    },

    init() {
      this.initCanvas();
      this.initCSSRenderer();
      this.initWebGLRenderer();
      this.initScene();
      this.initLights();
      this.initModelLoader();
      this.initTextureLoader();
      this.initFlatCamera();
      this.initFlatControls();
      this.initPerspectiveCamera();
      this.initPerspectiveControls();
      this.initGeometries();
      this.initPalette();
      this.initMaterials();
      this.initRaycaser();
      this.initPointerLabel();
      this.initGrid();
      this.initOrderColors();
    },

    dispose() {
      this.disposeGrid();
      this.disposeMaterials();
      this.disposeGeometries();
      this.disposeTransformControls();
    },

    resizeGrid() {
      if (!this.order.max_length || !this.order.max_width) {
        return;
      }
      this.disposeGrid();
      this.initGrid();
      this.updateFlatCameraBounds();
      this.requestOrderUpdate();
    },

    updateMouseCoordinates(event) {
      const rect = this.el.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.mouse.x = (x / this.viewportWidth) * 2 - 1;
      this.mouse.y = -(y / this.viewportHeight) * 2 + 1;
    },

    getActivePlatform() {
      return this.platformsMeta[0];
    },

    removePlatformDot(platformMeta, dotHandle) {
      let dotToRemove = null;
      let dotKeyToRemove = null;
      let dotCount = 0;
      for (const testDotKey in platformMeta.dots) {
        const testDot = platformMeta.dots[testDotKey];
        if (testDot.handle === dotHandle) {
          dotToRemove = testDot;
          dotKeyToRemove = testDotKey;
        }
        dotCount++;
      }
      if (dotCount < 4) {
        return;
      }
      if (!dotToRemove) {
        return;
      }
      this.scene.remove(dotToRemove.dot);
      dotToRemove.dot.geometry.dispose();
      dotToRemove.dot.material.dispose();
      delete dotToRemove.dot;
      this.scene.remove(dotToRemove.handle);
      dotToRemove.handle.geometry.dispose();
      dotToRemove.handle.material.dispose();
      delete dotToRemove.handle;
      platformMeta.dots.splice(dotKeyToRemove, 1);
      this.updatePlatformPlane(platformMeta);
    },

    getPlatformDotIndex(dotsCoordinates, x, z) {
      const testPoint = new THREE.Vector3(x, 0, z);
      let closestDistance;
      let distance;
      let closestIndex = dotsCoordinates.length - 1;
      let startPoint = new THREE.Vector3(
        dotsCoordinates[dotsCoordinates.length - 1].x,
        0,
        dotsCoordinates[dotsCoordinates.length - 1].z
      );
      let endPoint = new THREE.Vector3(
        dotsCoordinates[0].x,
        0,
        dotsCoordinates[0].z
      );
      closestDistance = calculateDistanceBetweenPointAndLine(
        testPoint,
        startPoint,
        endPoint
      );
      for (let i = 0; i < dotsCoordinates.length - 1; ++i) {
        startPoint = new THREE.Vector3(
          dotsCoordinates[i].x,
          0,
          dotsCoordinates[i].z
        );
        endPoint = new THREE.Vector3(
          dotsCoordinates[i + 1].x,
          0,
          dotsCoordinates[i + 1].z
        );
        distance = calculateDistanceBetweenPointAndLine(
          testPoint,
          startPoint,
          endPoint
        );
        if (!closestDistance || distance < closestDistance) {
          closestDistance = distance;
          closestIndex = i;
        }
      }

      return closestIndex + 1;
    },

    updatePlatformDotPosition(dotHandle) {
      const platformMeta = this.getActivePlatform();
      for (const testDotKey in platformMeta.dots) {
        const testDot = platformMeta.dots[testDotKey];
        if (testDot.handle === dotHandle) {
          testDot.dot.position.set(
            dotHandle.position.x,
            dotHandle.position.y,
            dotHandle.position.z
          );
          testDot.x = dotHandle.position.x;
          testDot.y = dotHandle.position.z;
          this.updatePlatformPlane(platformMeta);
          break;
        }
      }
    },

    addPlatformDot(
      platformMeta,
      x,
      z,
      calculateOrder = false,
      afterDotIndex = -1
    ) {
      const key = x + "_" + z;
      const dotsCoordinates = [];
      for (const testDotIndex in platformMeta.dots) {
        const testDot = platformMeta.dots[testDotIndex];
        dotsCoordinates.push({ x: testDot.x, z: testDot.y });
        if (testDot.key === key) {
          return;
        }
      }
      let dotIndex;
      if (calculateOrder) {
        dotIndex = this.getPlatformDotIndex(dotsCoordinates, x, z);
      } else if (afterDotIndex !== -1) {
        dotIndex = afterDotIndex;
      } else {
        dotIndex = dotsCoordinates.length - 1;
      }

      const dot = new THREE.Mesh(
        this.geometries.platformDot,
        this.materials.platformDot
      );
      const dotHandle = new THREE.Mesh(
        this.geometries.platformDotHandle,
        this.materials.testCollider
      );
      dot.name = "dot";
      dotHandle.name = "dotHandle";
      dot.position.x = x;
      dot.position.z = z;
      dot.position.y = 0;
      dotHandle.position.x = x;
      dotHandle.position.z = z;
      dotHandle.position.y = 0;

      this.scene.add(dot);
      this.scene.add(dotHandle);
      const dotMeta = {
        dot: dot,
        handle: dotHandle,
        key: key,
        x: dot.position.x,
        y: dot.position.z,
      };
      platformMeta.dots.splice(dotIndex, 0, dotMeta);
      platformMeta.controls.getObjects().push(dotMeta.handle);
      return dotMeta;
    },

    initPointerLabel() {
      const pointerLabelDiv = document.createElement("div");
      pointerLabelDiv.className = "label";
      pointerLabelDiv.textContent = "";
      this.pointerLabel = new CSS2DObject(pointerLabelDiv);
      this.pointerLabel.userData.element = pointerLabelDiv;
      this.pointerLabel.position.set(0, 0.3, 0);
      this.pointerLabel.visible = false;
      this.scene.add(this.pointerLabel);
    },

    initOrderItemLabel(orderItem) {
      const orderItemLabelDiv = document.createElement("div");
      orderItemLabelDiv.className = "label";
      orderItemLabelDiv.textContent = orderItem.code;
      orderItem.meta.label = new CSS2DObject(orderItemLabelDiv);
      orderItem.meta.label.userData.element = orderItemLabelDiv;
      orderItem.meta.label.position.set(0, 0.3, 0);
      orderItem.meta.label.visible = true;
    },

    updatePointerLabelPosition(x, z) {
      this.pointerLabel.position.set(x, 0.3, z - 0.5);
      const halfWidth = this.order.max_length / 2;
      const halfLength = this.order.max_width / 2;
      const positionX = Math.round((x + halfWidth + Number.EPSILON) * 10) / 10;
      const positionZ = Math.round((z + halfLength + Number.EPSILON) * 10) / 10;
      this.pointerLabel.userData.element.textContent = `${positionX},${positionZ}`;
    },

    handlePointerDown($event) {
      switch (this.mode) {
        case "platform-edit": {
          this.updateMouseCoordinates($event);
          this.raycaster.setFromCamera(this.mouse, this.cameras.active);
          const platformMeta = this.getActivePlatform();
          const dots = [];
          for (const dotKey in platformMeta.dots) {
            dots.push(platformMeta.dots[dotKey].handle);
          }
          if (this.keyboardModifiers.shift) {
            const intersections = this.raycaster.intersectObjects(dots);
            if (intersections.length > 0) {
              this.removePlatformDot(platformMeta, intersections[0].object);
            }
          } else if (this.keyboardModifiers.control) {
            const handleIntersections = this.raycaster.intersectObjects(dots);
            if (handleIntersections.length > 0) {
              const dotHandle = handleIntersections[0].object;
              for (const testDotIndex in platformMeta.dots) {
                const testDot = platformMeta.dots[testDotIndex];
                if (testDot.handle === dotHandle) {
                  const startDot = testDot.dot;
                  const endDotIndex =
                    parseInt(testDotIndex) < platformMeta.dots.length - 1
                      ? parseInt(testDotIndex) + 1
                      : 0;
                  const endDot = platformMeta.dots[endDotIndex].dot;
                  const testLine = new THREE.Line3(
                    startDot.position,
                    endDot.position
                  );
                  let newDotPositionBuffer = new THREE.Vector3();
                  const newDotPosition =
                    testLine.getCenter(newDotPositionBuffer);
                  const x = parseFloat(newDotPosition.x.toFixed(1));
                  const z = parseFloat(newDotPosition.z.toFixed(1));

                  this.addPlatformDot(platformMeta, x, z, false, endDotIndex);
                  this.updatePlatformPlane(platformMeta);
                  break;
                }
              }
            }
          }
          return;
        }

        case "order-item-placement": {
          if (this.intersectedObject) {
            this.activateOrderItem(this.intersectedObject);
          } else {
            this.clearCurrentOrderItem();
          }
          return;
        }

        case "order-item-edit": {
          this.deactivateAllOrderItemParts();
          this.updateMouseCoordinates($event);
          this.raycaster.setFromCamera(this.mouse, this.cameras.active);

          const intersections = this.raycaster.intersectObjects(
            this.detailMeshes
          );
          if (intersections.length > 0) {
            const orderItemPart =
              intersections[0].object.userData.orderItemPart;
            if (orderItemPart) {
              this.activateOrderItemPart(orderItemPart);
              this.currentOrderItem = orderItemPart.orderItem;
              this.currentOrderItemPart = orderItemPart;
            }
          }
          return;
        }

        default:
          throw new Error("Unexpected mode");
      }
    },

    deactivateAllOrderItemParts() {
      for (const orderItem of this.orderItems) {
        for (const orderItemPart of orderItem.parts) {
          orderItemPart.active = false;
          for (const detailMesh of orderItemPart.detailMeshes) {
            detailMesh.material = detailMesh.userData.originalMaterial;
          }
        }
      }
    },

    activateOrderItemPart(orderItemPart) {
      orderItemPart.active = true;
      for (const detailMesh of orderItemPart.detailMeshes) {
        detailMesh.material = this.materials.validSafeZoneMarker.clone();
      }

      if (!this.transformControls) {
        this.transformControls = new TransformControls(
          this.cameras.perspective,
          this.webGLRenderer.domElement
        );
        this.transformControls.space = "local";
        this.transformControls.showY = false;
        this.transformControls.setTranslationSnap(0.05);
        this.scene.add(this.transformControls);

        this.transformControls.addEventListener("mouseDown", () => {
          this.controls.perspective.enabled = false;
        });
        this.transformControls.addEventListener("mouseUp", () => {
          this.controls.perspective.enabled = true;
        });
        this.transformControls.addEventListener("objectChange", () => {
          this.requestCurrentOrderItemPartUpdate(true);
        });
      }

      this.transformControls.attach(orderItemPart.meshGroup);
      this.transformingObject = orderItemPart;
    },

    registerKeyboardModifier($event) {
      let processed = false;
      switch ($event.keyCode) {
        case 16:
          this.keyboardModifiers.shift = true;
          if (this.mode === "order-item-placement" && this.controls.flat) {
            this.enableFlatControls();
          }
          processed = true;
          break;
        case 17:
          this.keyboardModifiers.control = true;
          processed = true;
          break;
        case 18:
          this.keyboardModifiers.alt = true;
          processed = true;
          break;
      }
      if (processed) {
        $event.preventDefault();
        return false;
      }
    },

    unregisterKeyboardModifier($event) {
      switch ($event.keyCode) {
        case 16:
          this.keyboardModifiers.shift = false;
          if (this.mode === "order-item-placement" && this.controls.flat) {
            this.disableFlatControls();
          }
          break;
        case 17:
          this.keyboardModifiers.control = false;
          break;
        case 18:
          this.keyboardModifiers.alt = false;
          break;
      }
    },

    linesIntersect(a, b, c, d, p, q, r, s) {
      let det, gamma, lambda;
      det = (c - a) * (s - q) - (r - p) * (d - b);
      if (det === 0) {
        return false;
      } else {
        lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
        gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
        return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
      }
    },

    checkIntersections() {
      const helpers = [];
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.safeZoneMarker) {
          helpers.push(orderItem.meta.safeZoneMarker);
        }
      }

      const intersectedHelpers = [];
      let i, j;

      for (i = 0; i < helpers.length; ++i) {
        const helper = helpers[i];
        helper.updateMatrix();
        helper.updateMatrixWorld();
        helper.userData.obb.copy(helper.geometry.userData.obb);
        helper.userData.obb.applyMatrix4(helper.matrixWorld);
      }
      for (i = 0; i < helpers.length; ++i) {
        for (j = 0; j < helpers.length; ++j) {
          if (i === j) {
            continue;
          }
          if (helpers[i].userData.obb.intersectsOBB(helpers[j].userData.obb)) {
            if (intersectedHelpers.indexOf(i) === -1) {
              intersectedHelpers.push(i);
            }
            if (intersectedHelpers.indexOf(j) === -1) {
              intersectedHelpers.push(j);
            }
          }
        }
      }
      helpers.forEach((helper, i) => {
        // сохраняем уровень подсветки
        const emissiveIntensity = helpers[i].material.emissiveIntensity;
        if (intersectedHelpers.indexOf(i) > -1) {
          helpers[i].material = this.materials.invalidSafeZoneMarker.clone();
        } else {
          helpers[i].material = this.materials.validSafeZoneMarker.clone();
        }
        helpers[i].material.emissiveIntensity = emissiveIntensity;
      });
    },

    setGridVisibleState(state) {
      if (this.grid) {
        this.grid.visible = state;
      }
    },

    hideGrid() {
      this.setGridVisibleState(false);
    },

    showGrid() {
      this.setGridVisibleState(true);
    },

    setOrderItemSafeZoneMarkersVisibleState(state) {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.safeZoneMarker) {
          orderItem.meta.safeZoneMarker.savedVisibility =
            orderItem.meta.safeZoneMarker.visible;
          orderItem.meta.safeZoneMarker.visible = state;
        }
      }
    },

    restoreOrderItemSafeZoneMarkersVisibleState() {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.safeZoneMarker) {
          orderItem.meta.safeZoneMarker.visible =
            orderItem.meta.safeZoneMarker.savedVisibility ?? true;
        }
      }
    },

    hideOrderItemSafeZoneMarkers() {
      this.setOrderItemSafeZoneMarkersVisibleState(false);
    },

    showOrderItemSafeZoneMarkers() {
      this.setOrderItemSafeZoneMarkersVisibleState(true);
    },

    setOrderItemCodesVisibleState(state) {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.label) {
          orderItem.meta.label.savedVisibility = orderItem.meta.label.visible;
          orderItem.meta.label.visible = state;
        }
      }
    },

    restoreOrderItemCodesVisibleState() {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.label) {
          orderItem.meta.label.visible =
            orderItem.meta.label.savedVisibility ?? true;
        }
      }
    },

    hideOrderItemCodes() {
      this.setOrderItemCodesVisibleState(false);
    },

    showOrderItemCodes() {
      this.setOrderItemCodesVisibleState(true);
    },

    setPlatfromDotsVisibleState(state) {
      this.platformsMeta.forEach((platformMeta) => {
        for (const dotKey in platformMeta.dots) {
          platformMeta.dots[dotKey].dot.visible = state;
          platformMeta.dots[dotKey].handle.visible = state;
        }
      });
    },

    hidePlatformDots() {
      this.setPlatfromDotsVisibleState(false);
    },

    showPlatformDots() {
      this.setPlatfromDotsVisibleState(true);
    },

    setPlatfromLabelsVisibleState(state) {
      this.platformsMeta.forEach((platformMeta) => {
        for (const platformLabel of platformMeta.lineLabels) {
          platformLabel.visible = state;
        }
      });
    },

    hidePlatformLabels() {
      this.setPlatfromLabelsVisibleState(false);
    },

    showPlatformLabels() {
      this.setPlatfromLabelsVisibleState(true);
    },

    setControlsState(controlsType, state) {
      if (this.controls[controlsType]) {
        this.controls[controlsType].enabled = state;
      }
    },

    disableFlatControls() {
      this.setControlsState("flat", false);
    },

    enableFlatControls() {
      this.setControlsState("flat", true);
    },

    disablePerspectiveControls() {
      this.setControlsState("perspective", false);
    },

    enablePerspectiveControls() {
      this.setControlsState("perspective", true);
    },

    switchToFlatCamera() {
      this.switchCamera("flat");
    },

    switchToPerspectiveCamera() {
      this.switchCamera("perspective");
    },

    // Выбираем orderItem в качестве
    activateOrderItem(orderItem) {
      this.clearCurrentOrderItem();
      this.currentOrderItem = orderItem;
      this.currentOrderItem.meta.safeZoneMarker.material.emissiveIntensity = 1;
    },

    clearCurrentOrderItem() {
      this.deactivateAllOrderItemParts();
      if (this.currentOrderItem) {
        this.currentOrderItem.meta.safeZoneMarker.material.emissiveIntensity = 0;
      }
      this.currentOrderItem = null;
      this.currentOrderItemPart = null;
    },

    dimOrderItems() {},

    normalizeOrderItems() {},

    setOrderItemsControlsState(state) {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.controls) {
          if (state) {
            orderItem.meta.controls.activate();
          } else {
            orderItem.meta.controls.deactivate();
          }
        }
      }
    },

    disableOrderItemsControls() {
      this.setOrderItemsControlsState(false);
    },

    enableOrderItemsControls() {
      this.setOrderItemsControlsState(true);
    },

    setPlatformEditControlsToState(state) {
      for (const platformMeta of this.platformsMeta) {
        platformMeta.controls.enabled = state;
      }
    },

    enablePlatformEditControls() {
      this.setPlatformEditControlsToState(true);
    },

    disablePlatformEditControls() {
      this.setPlatformEditControlsToState(false);
    },

    setPlatformEditMode() {
      this.disposeTransformControls();
      this.switchToPlanLights();
      this.switchToFlatCamera();
      this.enableFlatControls();
      this.disablePerspectiveControls();
      this.dimOrderItems();
      this.disableOrderItemsControls();
      this.enablePlatformEditControls();
      this.showGrid();
      this.showPlatformDots();
      this.showPlatformLabels();
      this.showOrderItemSafeZoneMarkers();
      this.clearCurrentOrderItem();
    },

    setOrderItemsPlacementMode() {
      this.disposeTransformControls();
      this.switchToPlanLights();
      this.switchToFlatCamera();
      this.disableFlatControls();
      this.disablePerspectiveControls();
      this.normalizeOrderItems();
      this.enableOrderItemsControls();
      this.disablePlatformEditControls();
      this.showGrid();
      this.hidePlatformDots();
      this.hidePlatformLabels();
      this.showOrderItemSafeZoneMarkers();
      this.clearCurrentOrderItem();
    },

    setOrderItemsEditMode() {
      this.switchToViewLights();
      this.switchToPerspectiveCamera();
      this.disableFlatControls();
      this.enablePerspectiveControls();
      this.dimOrderItems();
      this.disableOrderItemsControls();
      this.disablePlatformEditControls();
      this.hideGrid();
      this.hidePlatformDots();
      this.hidePlatformLabels();
      this.hideOrderItemSafeZoneMarkers();
      this.clearCurrentOrderItem();
    },

    changeOrderMode(mode) {
      if (!this.cameras.flat) {
        return;
      }
      switch (mode) {
        case 0:
          this.setPlatformEditMode();
          this.mode = "platform-edit";
          break;
        case 1:
          this.setOrderItemsPlacementMode();
          this.mode = "order-item-placement";
          break;
        case 2:
          this.setOrderItemsEditMode();
          this.mode = "order-item-edit";
          break;
      }
    },

    switchCamera(cameraType) {
      this.cameras.active = this.cameras[cameraType];
      this.cameras.active.updateProjectionMatrix();
    },

    animate() {
      this.animationFrameId = requestAnimationFrame(this.animate);
      this.cssRenderer.render(this.scene, this.cameras.active);
      this.webGLRenderer.render(this.scene, this.cameras.active);
      if (
        this.el.clientWidth != this.viewportWidth ||
        this.el.clientHeight != this.viewportHeight
      ) {
        this.updateCanvasSize();
      }
    },

    getColorById(id) {
      return getDataObjectById(this.colors, id);
    },

    getColorByRALCode(ralCode) {
      for (const color of this.colors) {
        if (color.ral_code === ralCode) {
          return color;
        }
      }
      return null;
    },

    downloadEquipmentItemModel(orderItem) {
      this.glbLoading = true;
      this.gltfLoader.load(
        orderItem.equipmentItem.model_constructor,
        (gltf) => {
          this.glbLoading = false;
          orderItem.meta.model = gltf.scene || gltf.scenes[0];
          orderItem.meta.model.userData.orderItem = orderItem;
          orderItem.meta.savePosition = {};
          orderItem.meta.saveRotation = {};
          this.updateMeshMaterials(orderItem.meta.model.children);
          orderItem.meta.model.position.x =
            orderItem.equipmentItem.scene_position_x;
          orderItem.meta.model.position.y =
            orderItem.equipmentItem.scene_position_y;
          orderItem.meta.model.position.z =
            orderItem.equipmentItem.scene_position_z;
          orderItem.meta.model.rotation.x =
            orderItem.equipmentItem.scene_rotation_x;
          orderItem.meta.model.rotation.y =
            orderItem.equipmentItem.scene_rotation_y;
          orderItem.meta.model.rotation.z =
            orderItem.equipmentItem.scene_rotation_z;

          orderItem.meta.savePosition.x =
            orderItem.equipmentItem.scene_position_x;
          orderItem.meta.savePosition.y =
            orderItem.equipmentItem.scene_position_y;
          orderItem.meta.savePosition.z =
            orderItem.equipmentItem.scene_position_z;
          orderItem.meta.saveRotation.x =
            orderItem.equipmentItem.scene_rotation_x;
          orderItem.meta.saveRotation.y =
            orderItem.equipmentItem.scene_rotation_y;
          orderItem.meta.saveRotation.z =
            orderItem.equipmentItem.scene_rotation_z;
          this.addOrderItemToScene(orderItem);
        }
      );
    },

    getEquipmentPartByCode(equipmentPartCode) {
      for (const equipmentPart of this.equipmentParts) {
        if (equipmentPartCode == equipmentPart.code) {
          return equipmentPart;
        }
      }
      return null;
    },

    initOrderItemPart(orderItem, equipmentPartKey, equipmentPartType) {
      let orderItemPart = this.getOrderItemPartByKey(
        orderItem,
        equipmentPartKey
      );
      if (orderItemPart) {
        return;
      }
      const equipmentPartCode =
        this.getEquipmentPartCodeFromKey(equipmentPartKey);
      orderItemPart = {
        key: equipmentPartKey,
        code: equipmentPartCode,
        equipmentPart: this.getEquipmentPartByCode(equipmentPartCode),
        detailKeys: [],
        detailMeshes: [],
        controls: null,
        meshGroup: new THREE.Group(),
        orderItem: orderItem,
        visible: true,
        active: false,
        type: equipmentPartType,
      };
      orderItem.meta.modelGroup.add(orderItemPart.meshGroup);
      orderItem.parts.push(orderItemPart);
      return orderItemPart;
    },

    updateMeshMaterials(meshes) {
      for (const mesh of meshes) {
        const meshName = mesh.userData.name;
        if (meshName.startsWith("Q-1")) {
          mesh.material.dispose();
          mesh.material = this.materials.cap;
        } else if (meshName.startsWith("X-108")) {
          mesh.material.dispose();
          mesh.material = this.materials.clamp;
        } else if (meshName.startsWith("V-")) {
          mesh.material.dispose();
          mesh.material = this.materials.pillar;
        } else if (meshName.startsWith("E-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("Z-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("R-2000-Tt")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("R-")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("PE-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("BS-1200-Dt")) {
          mesh.material.dispose();
          mesh.material = this.materials.zinc;
        } else if (meshName.startsWith("BS-1200-DBR")) {
          mesh.material.dispose();
          mesh.material = this.materials.zinc;
        } else if (meshName.startsWith("BS-1200-DN")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("BS-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("EE-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("EW")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("F-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("FV-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("FD-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("DE-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("D-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("B-1500-C")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("B-1500")) {
          mesh.material.dispose();
          mesh.material = this.materials.wood;
        } else if (meshName.startsWith("BB-1300")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("BB-1500")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("SB-1300")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("H-2-D5D9")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("H-2-D7")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("H-2")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        }
      }
    },

    downloadEquipmentPartModel(orderItemPart, orderItemPartMeta) {
      this.gltfLoader.load(
        orderItemPart.equipmentPart.model_constructor,
        (gltf) => {
          this.glbLoading = false;
          orderItemPart.model = gltf.scene || gltf.scenes[0];
          orderItemPart.model.userData.orderItemPart = orderItemPart;

          orderItemPart.model.position.x =
            orderItemPart.equipmentPart.scene_position_x;
          orderItemPart.model.position.y =
            orderItemPart.equipmentPart.scene_position_y;
          orderItemPart.model.position.z =
            orderItemPart.equipmentPart.scene_position_z;
          orderItemPart.model.rotation.x =
            orderItemPart.equipmentPart.scene_rotation_x;
          orderItemPart.model.rotation.y =
            orderItemPart.equipmentPart.scene_rotation_y;
          orderItemPart.model.rotation.z =
            orderItemPart.equipmentPart.scene_rotation_z;

          this.updateMeshMaterials(orderItemPart.model.children);
          this.addOrderItemPartToScene(orderItemPart, orderItemPartMeta);
        }
      );
    },

    addOrderItemPartToScene(orderItemPart, orderItemPartMeta) {
      orderItemPart.orderItem.meta.modelGroup.add(orderItemPart.model);
      this.initOrderItemPartMeshGroup(
        orderItemPart,
        orderItemPartMeta,
        orderItemPart.model
      );
    },

    // Выбираем текущий orderItem (в 3d режиме)
    changeCurrentOrderItem(orderItem) {
      this.currentOrderItem = orderItem;
      if (!this.currentOrderItem) {
        this.currentOrderItemPart = null;
      }
    },

    changeCurrentOrderItemPart(orderItemPart) {
      this.deactivateAllOrderItemParts();
      this.currentOrderItemPart = orderItemPart;
      if (orderItemPart) {
        this.activateOrderItemPart(orderItemPart);
      }
    },

    hideOrderItemPart(orderItemPart) {
      this.changeOrderItemPartVisibility(orderItemPart, false);
    },

    changeOrderItemPartVisibility(orderItemPart, state) {
      orderItemPart.visible = state;
      for (const mesh of orderItemPart.detailMeshes) {
        mesh.visible = state;
      }
    },

    changeCurrentOrderItemPartVisibility(state) {
      this.changeOrderItemPartVisibility(this.currentOrderItemPart, state);
      if (state) {
        this.addOrderItemPartMeshesToGlobalMeshCollection(
          this.currentOrderItemPart
        );
      } else {
        this.removeOrderItemPartMeshesFromGlobalMeshCollection(
          this.currentOrderItemPart
        );
      }
      this.requestCurrentOrderItemPartUpdate(true);
    },

    addEquipmentItemToOrder(equipmentItem) {
      const newOrderItem = {
        orderId: this.$route.params.id,
        orderItem: {
          id: null,
          code: this.generateUniqueOrderItemCode(equipmentItem),
          name: equipmentItem.name,
          description: equipmentItem.description,
          position_x: 0,
          position_y: 0,
          position_z: 0,
          rotation_x: 0,
          rotation_y: 0,
          rotation_z: 0,
          item: equipmentItem.id,
          equipment_price: parseFloat(equipmentItem.equipment_price),
          installation_price: parseFloat(equipmentItem.price),
        },
      };
      //this.equipmentItems = [...this.equipmentItems];
      this["orderItems/create"](newOrderItem);
    },

    addEquipmentPart(equipmentPart) {
      if (!equipmentPart) {
        return;
      }
      const orderItem = this.currentOrderItem;
      const adjustedAdditionalPartKey = this.getNextAvailableOrderPartKey(
        equipmentPart.code,
        orderItem
      );
      orderItemsService
        .addOrUpdatePart(orderItem.id, adjustedAdditionalPartKey, {
          key: adjustedAdditionalPartKey,
          equipment_part: equipmentPart.id,
          type: "additional",
          active: true,
        })
        .then((orderItemPartMeta) => {
          this.glbLoading = true;
          const orderItemPart = this.initOrderItemPart(
            orderItem,
            orderItemPartMeta.key,
            orderItemPartMeta.type
          );
          orderItemPart.equipmentPart = equipmentPart;
          this.downloadEquipmentPartModel(orderItemPart, orderItemPartMeta);
        });
    },

    getNextAvailableOrderPartKey(orderPartCode, orderItem) {
      let keyAvailable = false;
      let currentKeyIndex = 1;
      let currentKey;
      let hasKey;
      while (!keyAvailable) {
        currentKey = `${orderPartCode}_${currentKeyIndex}`;
        hasKey = false;
        for (const orderPart of orderItem.parts) {
          if (currentKey === orderPart.key) {
            hasKey = true;
            break;
          }
        }
        if (!hasKey) {
          keyAvailable = true;
          break;
        }
        currentKeyIndex++;
      }
      return currentKey;
    },

    removeOrderItemFromScene(orderItem) {
      orderItem.meta.safeZoneMarker.geometry.dispose();
      this.scene.remove(orderItem.meta.modelGroup);
      this.scene.remove(orderItem.meta.label);

      // Ждём, когда обновится список orderItems и проверяем пересечения
      setTimeout(() => {
        this.checkIntersections();
      }, 200);
    },

    removeOrderItem(orderItem) {
      this["orderItems/delete"]({
        orderId: this.$route.params.id,
        orderItemId: orderItem.id,
      }).then(() => {
        this.removeOrderItemFromScene(orderItem);
      });
    },

    addPlatform(platform) {
      const platformMeta = {
        area: 0.0,
        dots: [],
        plane: null,
        lineLabels: [],
        spriteLineLabels: [],
        controls: null,
        orderPlatformId: platform.id,
      };

      platformMeta.controls = new DragControls(
        [],
        this.cameras.flat,
        this.webGLRenderer.domElement
      );
      platformMeta.controls.addEventListener("dragstart", (event) => {
        event.object.position.y = 0;
      });
      platformMeta.controls.addEventListener("drag", (event) => {
        this.updatePointerLabelPosition(
          event.object.position.x,
          event.object.position.z
        );
        this.updatePlatformDotPosition(event.object);
        this.updatePlatformPlane(this.getActivePlatform());
        event.object.position.y = 0;
      });

      platformMeta.controls.addEventListener("dragend", (event) => {
        event.object.position.x = parseFloat(
          event.object.position.x.toFixed(1)
        );
        event.object.position.y = 0;
        event.object.position.z = parseFloat(
          event.object.position.z.toFixed(1)
        );
        this.updatePlatformDotPosition(event.object);
      });
      platformMeta.controls.enabled = false;
      for (const dotIndex in platform.dots) {
        const dot = platform.dots[dotIndex];
        this.addPlatformDot(platformMeta, dot.x, dot.y);
      }
      this.addPlatformPlane(platformMeta);
      this.platformsMeta.push(platformMeta);
    },

    updatePlatformPlane(platformMeta) {
      this.removePlatformPlane(platformMeta);
      this.addPlatformPlane(platformMeta);
      this.requestOrderPlatformUpdate(platformMeta);
    },

    removePlatformPlane(platformMeta) {
      const plane = platformMeta.plane;
      plane.geometry.dispose();
      while (platformMeta.lineLabels.length > 0) {
        const lineLabel = platformMeta.lineLabels.pop();
        lineLabel.userData.element.remove();
        this.scene.remove(lineLabel);
      }
      this.scene.remove(platformMeta.plane);
    },

    addPlatformLineLabel(platformMeta, startX, startZ, endX, endZ) {
      const testLine = new THREE.Line3(
        new THREE.Vector3(startX, 0, startZ),
        new THREE.Vector3(endX, 0, endZ)
      );
      const lineLength = testLine.distance();
      let labelPositionBuffer = new THREE.Vector3();
      const labelPosition = testLine.getCenter(labelPositionBuffer);
      const lineLabelDiv = document.createElement("div");
      lineLabelDiv.className = "label";
      lineLabelDiv.textContent =
        Math.round((lineLength + Number.EPSILON) * 100) / 100;
      const lineLabel = new CSS2DObject(lineLabelDiv);
      lineLabel.userData.element = lineLabelDiv;
      lineLabel.position.set(labelPosition.x, 0.3, labelPosition.z);
      platformMeta.lineLabels.push(lineLabel);
      this.scene.add(lineLabel);
    },

    addPlatformSpriteLineLabel(platformMeta, startX, startZ, endX, endZ) {
      const testLine = new THREE.Line3(
        new THREE.Vector3(startX, 0, startZ),
        new THREE.Vector3(endX, 0, endZ)
      );
      const maxSize =
        this.order.max_width > this.order.max_length
          ? this.order.max_width
          : this.order.max_length;
      const fontSize = 0.025 * maxSize;
      const lineLength = testLine.distance();
      let labelPositionBuffer = new THREE.Vector3();
      const labelPosition = testLine.getCenter(labelPositionBuffer);

      const sprite = new TextSprite({
        alignment: "left",
        color: "#000000",
        fontFamily: "sans-serif",
        fontSize,
        text: (
          Math.round((lineLength + Number.EPSILON) * 100) / 100
        ).toString(),
      });
      platformMeta.spriteLineLabels.push(sprite);
      sprite.position.set(labelPosition.x, 5, labelPosition.z);
      sprite.name = "text-sprite";
      this.scene.add(sprite);
    },

    addPlatformPlane(platformMeta) {
      const platformShape = new THREE.Shape();
      const maxDots = 20;
      let i = 0;
      let firstDot = null;
      let previousX;
      let previousZ;
      for (const dotIndex in platformMeta.dots) {
        const dot = platformMeta.dots[dotIndex];
        if (i === 0) {
          firstDot = dot;
          platformShape.moveTo(dot.x, dot.y);
        } else {
          platformShape.lineTo(dot.x, dot.y);

          if(platformMeta.dots.length<maxDots) { //убираем спам размерами линий, образующих круг
            this.addPlatformLineLabel(
                platformMeta,
                dot.x,
                dot.y,
                previousX,
                previousZ
            );
          }

        }
        previousX = dot.x;
        previousZ = dot.y;
        i++;
      }
      platformShape.lineTo(firstDot.x, firstDot.y);

      if(platformMeta.dots.length<maxDots) {
        this.addPlatformLineLabel(
            platformMeta,
            firstDot.x,
            firstDot.y,
            previousX,
            previousZ
        );
      }
      else { //вместо спама размерами выводим 4 обобщенных
        //console.log(platformMeta);
        let box = new THREE.Box2();
        box.setFromPoints(platformMeta.dots);
        
        this.addPlatformLineLabel(
            platformMeta,
            box.min.x,
            box.min.y,
            box.min.x,
            box.max.y
        );

        this.addPlatformLineLabel(
            platformMeta,
            box.max.x,
            box.min.y,
            box.max.x,
            box.max.y
        );

        this.addPlatformLineLabel(
            platformMeta,
            box.min.x,
            box.min.y,
            box.max.x,
            box.min.y
        );

        this.addPlatformLineLabel(
            platformMeta,
            box.min.x,
            box.max.y,
            box.max.x,
            box.max.y
        );
      }


      const geometry = new THREE.ShapeGeometry(platformShape);
      platformMeta.plane = new THREE.Mesh(geometry, this.materials.platform);
      platformMeta.plane.rotation.x = Math.PI / 2;
      platformMeta.area =
        Math.round(
          (THREE.ShapeUtils.area(platformMeta.dots) + Number.EPSILON) * 100
        ) / 100;
      this.scene.add(platformMeta.plane);
    },

    addBoxHelperToOrderItem(orderItem) {
      const safeZoneMarkerBox = new THREE.Box3().setFromObject(
        orderItem.meta.model
      );

      const safeZoneMarkerSize = new THREE.Vector3();
      safeZoneMarkerBox.getSize(safeZoneMarkerSize);

      const safeZoneTop = parseFloat(orderItem.equipmentItem.safe_zone_top);
      const safeZoneRight = parseFloat(orderItem.equipmentItem.safe_zone_right);
      const safeZoneBottom = parseFloat(
        orderItem.equipmentItem.safe_zone_bottom
      );
      const safeZoneLeft = parseFloat(orderItem.equipmentItem.safe_zone_left);

      const offsetX = -((safeZoneLeft - safeZoneRight) / 2);
      const offsetY = (safeZoneMarkerSize.y + 0.5) / 2;
      const offsetZ = -((safeZoneTop - safeZoneBottom) / 2);

      const x = safeZoneLeft + safeZoneRight + safeZoneMarkerSize.x;
      const y = safeZoneMarkerSize.y + 0.5;
      const z = safeZoneTop + safeZoneBottom + safeZoneMarkerSize.z;
      const geometry = new THREE.BoxGeometry(x, y, z);
      orderItem.meta.safeZoneMarker = new THREE.Mesh(
        geometry,
        this.materials.validSafeZoneMarker.clone()
      );
      orderItem.meta.safeZoneMarker.name = "safeZoneMarker";
      orderItem.meta.safeZoneMarker.position.x = offsetX;
      orderItem.meta.safeZoneMarker.position.y = offsetY;
      orderItem.meta.safeZoneMarker.position.z = offsetZ;

      const adjustedSafeZoneMarkerBox = new THREE.Box3().setFromObject(
        orderItem.meta.safeZoneMarker
      );
      const adjustedSafeZoneMarkerSize = new THREE.Vector3();
      adjustedSafeZoneMarkerBox.getSize(adjustedSafeZoneMarkerSize);

      geometry.userData.obb = new OBB();
      geometry.userData.obb.halfSize
        .copy(adjustedSafeZoneMarkerSize)
        .multiplyScalar(0.5);
      orderItem.meta.safeZoneMarker.userData.obb = new OBB();
      orderItem.meta.safeZoneMarker.userData.orderItem = orderItem;
      orderItem.meta.modelGroup.add(orderItem.meta.safeZoneMarker);
      if (this.mode === "order-item-edit") {
        orderItem.meta.safeZoneMarker.visible = false;
      }
      // if (this.safeZoneMarkersVisible) {
      //   orderItem.meta.safeZoneMarker.visible = false;
      // }
    },

    getOrderItemPartByKey(orderItem, equipmentPartKey) {
      for (const orderItemPart of orderItem.parts) {
        if (orderItemPart.key === equipmentPartKey) {
          return orderItemPart;
        }
      }
      return null;
    },

    getMeshesByKeys(orderItemPart) {
      const resultMeshes = [];
      const meshKeys =
        orderItemPart.orderItem.equipmentItem.parts_map[orderItemPart.key];
      if (orderItemPart.orderItem.meta.model.children && meshKeys) {
        for (const modelMesh of orderItemPart.orderItem.meta.model.children) {
          for (const meshKey of meshKeys) {
            if (meshKey === modelMesh.userData.name) {
              resultMeshes.push(modelMesh);
            }
          }
        }
      }
      return resultMeshes;
    },

    initOrderItemPartMeshGroup(orderItemPart, orderItemPartMeta, model = null) {
      if (model) {
        orderItemPart.detailMeshes = [...model.children];
      } else {
        orderItemPart.detailMeshes = this.getMeshesByKeys(orderItemPart);
      }
      for (const mesh of orderItemPart.detailMeshes) {
        orderItemPart.meshGroup.attach(mesh);
        mesh.userData.originalMaterial = mesh.material;
        mesh.userData.orderItemPart = orderItemPart;
        if (orderItemPart.visible) {
          this.addMeshToGlobalMeshCollection(mesh);
        }
      }
      if (!orderItemPart.visible) {
        this.hideOrderItemPart(orderItemPart);
      }
      orderItemPart.meshGroup.position.x = orderItemPartMeta.position_x;
      orderItemPart.meshGroup.position.y = orderItemPartMeta.position_y;
      orderItemPart.meshGroup.position.z = orderItemPartMeta.position_z;
      orderItemPart.meshGroup.rotation.x = orderItemPartMeta.rotation_x;
      orderItemPart.meshGroup.rotation.y = orderItemPartMeta.rotation_y;
      orderItemPart.meshGroup.rotation.z = orderItemPartMeta.rotation_z;
      orderItemPart.savePosition = {
        x: orderItemPartMeta.position_x,
        y: orderItemPartMeta.position_y,
        z: orderItemPartMeta.position_z,
      };
      orderItemPart.saveRotation = {
        x: orderItemPartMeta.rotation_x,
        y: orderItemPartMeta.rotation_y,
        z: orderItemPartMeta.rotation_z,
      };
    },

    getEquipmentPartCodeFromKey(equipmentPartKey) {
      return equipmentPartKey.rsplit("_", 1)[0];
    },

    initOrderItemParts(orderItem, orderItemParts) {
      for (const orderItemPartMeta of orderItemParts) {
        const orderItemPart = this.initOrderItemPart(
          orderItem,
          orderItemPartMeta.key,
          orderItemPartMeta.type
        );
        if (orderItemPartMeta.type === "own") {
          orderItemPart.visible = orderItemPartMeta.active;
          this.initOrderItemPartMeshGroup(orderItemPart, orderItemPartMeta);
        } else {
          this.downloadEquipmentPartModel(orderItemPart, orderItemPartMeta);
        }
      }
    },

    generateUniqueOrderItemCode(equipmentItem) {
      let orderItemCodeIndex = 1;
      let orderItemCode = equipmentItem.code;
      let orderItemCodeUnique = false;
      while (!orderItemCodeUnique) {
        orderItemCodeUnique = true;
        for (const testOrderItem of this.orderItems) {
          if (testOrderItem.code === orderItemCode) {
            orderItemCodeUnique = false;
            break;
          }
        }
        if (!orderItemCodeUnique) {
          orderItemCodeIndex++;
          orderItemCode = equipmentItem.code + " " + orderItemCodeIndex;
        }
      }
      return orderItemCode;
    },

    initOrderItem(orderItem) {
      orderItem.meta = {
        model: null,
        label: null,
        modelGroup: new THREE.Group(),
        controls: null,
        materials: {
          cap: null,
          clamp: null,
          wood: null,
          bar: null,
        },
        edit: false,
        safeZoneMarker: null,
        currentPart: null,
      };
      orderItem.meta.modelGroup.userData.orderItem = orderItem;
      orderItem.parts = [];
      this.initOrderItemLabel(orderItem);
    },

    addOrderItemModelToScene(orderItem) {
      orderItem.meta.modelGroup.position.x = orderItem.position_x;
      orderItem.meta.modelGroup.position.y = orderItem.position_y;
      orderItem.meta.modelGroup.position.z = orderItem.position_z;
      orderItem.meta.modelGroup.rotation.x = orderItem.rotation_x;
      orderItem.meta.modelGroup.rotation.y = orderItem.rotation_y;
      orderItem.meta.modelGroup.rotation.z = orderItem.rotation_z;

      this.scene.add(orderItem.meta.modelGroup);
      const saveThis = this;
      setTimeout(() => {
        saveThis.checkIntersections();
      }, 100);
    },

    updateOrderItemLabelPosition(orderItem) {
      const boundingBox = new THREE.Box3().setFromObject(
        orderItem.meta.modelGroup
      );
      orderItem.meta.label.position.set(
        boundingBox.min.x,
        boundingBox.max.y,
        boundingBox.min.z
      );
    },

    addOrderItemLabelToScene(orderItem) {
      this.updateOrderItemLabelPosition(orderItem);
      this.scene.add(orderItem.meta.label);
    },

    meshExistsInGlobalCollection(mesh) {
      for (const detailMeshIndex in this.detailMeshes) {
        const detailMesh = this.detailMeshes[detailMeshIndex];
        if (detailMesh.uuid === mesh.uuid) {
          return detailMeshIndex;
        }
      }
      return -1;
    },

    addOrderItemMeshesToGlobalMeshCollection(orderItem) {
      for (const detailMesh of orderItem.meta.model.children) {
        if (this.meshExistsInGlobalCollection(detailMesh) === -1) {
          this.detailMeshes.push(detailMesh);
        }
      }
    },

    removeOrderItemMeshesFromGlobalMeshCollection(orderItem) {
      for (const detailMesh of orderItem.meta.model.children) {
        const detailMeshIndex = this.meshExistsInGlobalCollection(detailMesh);
        if (detailMeshIndex !== -1) {
          this.detailMeshes.splice(detailMeshIndex, 1);
        }
      }
    },

    addOrderItemPartMeshesToGlobalMeshCollection(orderItemPart) {
      for (const detailMesh of orderItemPart.detailMeshes) {
        if (this.meshExistsInGlobalCollection(detailMesh) === -1) {
          this.detailMeshes.push(detailMesh);
        }
      }
    },

    removeOrderItemPartMeshesFromGlobalMeshCollection(orderItemPart) {
      for (const detailMesh of orderItemPart.detailMeshes) {
        const detailMeshIndex = this.meshExistsInGlobalCollection(detailMesh);
        if (detailMeshIndex !== -1) {
          this.detailMeshes.splice(detailMeshIndex, 1);
        }
      }
    },

    addMeshToGlobalMeshCollection(mesh) {
      if (this.meshExistsInGlobalCollection(mesh) === -1) {
        this.detailMeshes.push(mesh);
      }
    },

    removeMeshFromGlobalMeshCollection(mesh) {
      const detailMeshIndex = this.meshExistsInGlobalCollection(mesh);
      if (detailMeshIndex !== -1) {
        this.detailMeshes.splice(detailMeshIndex, 1);
      }
    },

    loadOrderItemParts(orderItem) {
      orderItemsService.getParts(orderItem.id).then((parts) => {
        this.initOrderItemParts(orderItem, parts);
      });
    },

    addOrderItemToScene(orderItem) {
      orderItem.meta.modelGroup.add(orderItem.meta.model);
      this.addBoxHelperToOrderItem(orderItem);
      this.addOrderItemModelToScene(orderItem);
      this.addOrderItemLabelToScene(orderItem);
      this.attachFlatDragControlsToOrderItem(orderItem);
      this.loadOrderItemParts(orderItem);
      this.addOrderItemMeshesToGlobalMeshCollection(orderItem);
    },

    attachFlatDragControlsToOrderItem(orderItem) {
      const dragControls = new DragControls(
        [orderItem.meta.modelGroup],
        this.cameras.flat,
        this.webGLRenderer.domElement
      );
      dragControls.transformGroup = true;
      if (this.mode !== "order-item-placement") {
        dragControls.deactivate();
      }

      dragControls.addEventListener("dragstart", (event) => {
        event.object.position.y = 0;
      });

      dragControls.addEventListener("drag", (event) => {
        event.object.position.y = 0;
        this.updateOrderItemLabelPosition(event.object.userData.orderItem);
        this.checkIntersections();
      });

      dragControls.addEventListener("dragend", (event) => {
        event.object.position.y = 0;
        event.object.position.x = parseFloat(
          event.object.position.x.toFixed(1)
        );
        event.object.position.z = parseFloat(
          event.object.position.z.toFixed(1)
        );
        event.object.position.y = 0;

        this.requestOrderItemUpdate(
          event.object.children[0].userData.orderItem,
          true
        );
      });

      // Подсвечиваем меш при наведении (если не выделен)
      dragControls.addEventListener("hoveron", ({ object }) => {
        if (object.material.emissiveIntensity !== 1) {
          object.material.emissiveIntensity = 0.2;
        }
      });

      // убираем подсветку
      dragControls.addEventListener("hoveroff", ({ object }) => {
        if (object.material.emissiveIntensity !== 1) {
          object.material.emissiveIntensity = 0;
        }
      });

      orderItem.meta.controls = dragControls;
    },

    getOrderMountType() {
      let mountTypeId = 1;
      if (this.order.mount_type) {
        mountTypeId = this.order.mount_type;
      }
      return getDataObjectById(this.mountTypes, mountTypeId);
    },

    changeOrderMountType(mountType) {
      let mountTypeId = 1;
      if (mountTypeId) {
        mountTypeId = mountType.id;
      }
      if (this.order.mount_type != mountTypeId) {
        this.order.mount_type = mountTypeId;
        this.requestOrderUpdate();
      }
    },

    getOrderSputtering() {
      let sputteringId = 1;
      if (this.order.sputtering) {
        sputteringId = this.order.sputtering;
      }
      return getDataObjectById(this.sputterings, sputteringId);
    },

    applyZincColors() {
      const zincColor = this.getColorByRALCode("RAL 9003");
      const blackColor = this.getColorByRALCode("RAL 9005");
      this.order.bar_color = blackColor.id;
      this.order.pillar_color = zincColor.id;
      this.order.cap_color = blackColor.id;
      this.order.clamp_color = blackColor.id;
      this.materials.bar.color.setHex(this.colorToHex(blackColor));
      this.materials.cap.color.setHex(this.colorToHex(blackColor));
      this.materials.clamp.color.setHex(this.colorToHex(blackColor));
      this.materials.pillar.color.setHex(this.colorToHex(zincColor));
    },

    applyDefaultColors() {
      const mainColor = this.getColorByRALCode("RAL 7016");
      const clampColor = this.getColorByRALCode("RAL 5018");
      this.order.bar_color = mainColor.id;
      this.order.pillar_color = mainColor.id;
      this.order.cap_color = mainColor.id;
      this.order.clamp_color = clampColor.id;
      this.materials.bar.color.setHex(this.colorToHex(mainColor));
      this.materials.cap.color.setHex(this.colorToHex(mainColor));
      this.materials.clamp.color.setHex(this.colorToHex(clampColor));
      this.materials.pillar.color.setHex(this.colorToHex(mainColor));
    },

    changeOrderSputtering(sputtering) {
      let sputteringId = 1;

      this.changeOrderPainting(sputtering)

      if (sputtering.id) {
        sputteringId = sputtering.id;
      }
      if (this.order.sputtering != sputteringId) {
        //const currentSputtering = this.getOrderSputtering();
        if (sputtering.code === "zinc") {
          this.applyZincColors();
        } else {
          this.applyDefaultColors();
        }
        this.order.sputtering = sputteringId;
        this.requestOrderUpdate();
      }

    },

    changeOrderPainting(){
      this.painting = false
    },

    changeOrderSputteringPainting(painting) {
      if (!painting) {
          this.applyDefaultColors();
          this.requestOrderUpdate();
      }
    },

    getOrderCovering() {
      let coveringId = 3;
      if (this.order.platform_covering) {
        coveringId = this.order.platform_covering;
      }
      return getDataObjectById(this.coverings, coveringId);
    },

    changeOrderCovering(covering) {
      let coveringId = 3;
      if (coveringId) {
        coveringId = covering.id;
      }
      if (this.order.platform_covering != coveringId) {
        this.order.platform_covering = coveringId;
        this.requestOrderUpdate();
      }
    },

    getOrderFoundation() {
      let foundationId = 1;
      if (this.order.foundation) {
        foundationId = this.order.foundation;
      }
      return getDataObjectById(this.foundations, foundationId);
    },

    changeOrderFoundation(foundation) {
      let foundationId = 1;
      if (foundationId) {
        foundationId = foundation.id;
      }
      if (this.order.foundation != foundationId) {
        this.order.foundation = foundationId;
        this.requestOrderUpdate();
      }
    },

    getOrderColor(tag) {
      const colorPropertyName = `${tag}_color`;
      return this.getColorById(this.order[colorPropertyName]);
    },

    getOrderBarColor() {
      return this.getOrderColor("bar");
    },

    getOrderClampColor() {
      return this.getOrderColor("clamp");
    },

    getOrderBarColorRALCode() {
      const color = this.getOrderBarColor();

      if (this.order.sputtering == 2) {
        const color_zink = this.getColorByRALCode("RAL 9003");
        return color_zink.ral_code;
      }

      if (color) {
        return color.ral_code;
      }
      return null;
    },

    getCurrentOrderBarColorRALHex() {
      const color = this.getOrderBarColor();

      if (this.order.sputtering == 2) {
        const color_zink = this.getColorByRALCode("RAL 9003");
        return `#${color_zink.hex_code}`;
      }

      if (color) {
        return `#${color.hex_code}`;
      }
      return null;
    },

    getOrderCapColorRALCode() {
      const color = this.getOrderItemCapColor();

      if (this.order.sputtering == 2) {
        const color_zink = this.getColorByRALCode("RAL 9003");
        return color_zink.ral_code;
      }

      if (color) {
        const color_cap = this.getColorByRALCode("RAL 7016");
        return color_cap.ral_code
      }
      return null;
    },

    getOrderClampColorRALCode() {
      const color = this.getOrderClampColor();
      if (this.order.sputtering == 2) {
        const color_zink = this.getColorByRALCode("RAL 9005");
        return color_zink.ral_code;
      }
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    getCurrentOrderClampColorRALHex() {
      const color = this.getOrderClampColor();

      if (this.order.sputtering == 2) {
        const color_zink = this.getColorByRALCode("RAL 9005");
        return `#${color_zink.hex_code}`;
      }
      if (color) {
        return `#${color.hex_code}`;
      }
      return null;
    },

    colorToHex(color) {
      return parseInt(color.hex_code, 16);
    },

    changeOrderColor(tag, color) {
      this.order[`${tag}_color`] = color.id;
      this.palette[tag].setHex(this.colorToHex(this.getColorById(color.id)));
      this.materials[tag].color = this.palette[tag];
    },

    changeOrderBarColor(color) {
      if (!color) {
        color = this.getColorByRALCode("RAL 7016");
      }
      this.changeOrderColor("bar", color);
      this.changeOrderColor("pillar", color);
      // this.changeOrderColor("cap", color);
      this.requestOrderUpdate();
    },

    changeOrderClampColor(color) {
      if (!color) {
        color = this.getColorByRALCode("RAL 5018");
      }
      this.changeOrderColor("clamp", color);
      this.requestOrderUpdate();
    },

    getOrderItemById(orderItemId) {
      if (this.orderItems && this.orderItems.length) {
        this.orderItems.forEach((orderItem) => {
          if (orderItem.id === orderItemId) {
            return orderItem;
          }
        });
      }
      return null;
    },

    getOrderItemByCode(orderItemCode) {
      if (this.orderItems && this.orderItems.length) {
        for (const orderItem of this.orderItems)
          if (orderItem.code === orderItemCode) {
            return orderItem;
          }
      }
      return null;
    },

    updateOrderItem(orderItem, saveToHistory = false) {
      if (saveToHistory) {
        this.locationHistory.push({
          type: "equipmentItem",
          key: orderItem.code,
          position: {
            x: orderItem.meta.savePosition.x,
            y: orderItem.meta.savePosition.y,
            z: orderItem.meta.savePosition.z,
          },
          rotation: {
            x: orderItem.meta.saveRotation.x,
            y: orderItem.meta.saveRotation.y,
            z: orderItem.meta.saveRotation.z,
          },
        });
      }
      orderItem.position_x = orderItem.meta.modelGroup.position.x;
      orderItem.position_y = orderItem.meta.modelGroup.position.y;
      orderItem.position_z = orderItem.meta.modelGroup.position.z;
      orderItem.rotation_x = orderItem.meta.modelGroup.rotation.x;
      orderItem.rotation_y = orderItem.meta.modelGroup.rotation.y;
      orderItem.rotation_z = orderItem.meta.modelGroup.rotation.z;
      orderItem.meta.savePosition.x = orderItem.meta.modelGroup.position.x;
      orderItem.meta.savePosition.y = orderItem.meta.modelGroup.position.y;
      orderItem.meta.savePosition.z = orderItem.meta.modelGroup.position.z;
      orderItem.meta.saveRotation.x = orderItem.meta.modelGroup.rotation.x;
      orderItem.meta.saveRotation.y = orderItem.meta.modelGroup.rotation.y;
      orderItem.meta.saveRotation.z = orderItem.meta.modelGroup.rotation.z;
      const payload = {
        orderId: this.$route.params.id,
        orderItem: orderItem,
      };
      this["orderItems/update"](payload);
    },

    updateOrderItemPart(orderItemPart, saveToHistory = false) {
      if (saveToHistory) {
        this.locationHistory.push({
          type: "equipmentPart",
          key: orderItemPart.key,
          code: orderItemPart.orderItem.code,
          position: {
            x: orderItemPart.savePosition.x,
            y: orderItemPart.savePosition.y,
            z: orderItemPart.savePosition.z,
          },
          rotation: {
            x: orderItemPart.saveRotation.x,
            y: orderItemPart.saveRotation.y,
            z: orderItemPart.saveRotation.z,
          },
        });
      }
      orderItemPart.savePosition.x = orderItemPart.meshGroup.position.x;
      orderItemPart.savePosition.y = orderItemPart.meshGroup.position.y;
      orderItemPart.savePosition.z = orderItemPart.meshGroup.position.z;
      orderItemPart.saveRotation.x = orderItemPart.meshGroup.rotation.x;
      orderItemPart.saveRotation.y = orderItemPart.meshGroup.rotation.y;
      orderItemPart.saveRotation.z = orderItemPart.meshGroup.rotation.z;
      if (!orderItemPart.visible) {
        this["orderItems/deletePart"]({
          orderItemId: orderItemPart.orderItem.id,
          orderItemPartKey: orderItemPart.key,
        });
      } else {
        this["orderItems/addOrUpdatePart"]({
          orderItemId: orderItemPart.orderItem.id,
          orderItemPartKey: orderItemPart.key,
          orderItemPart: {
            position_x: orderItemPart.meshGroup.position.x,
            position_y: orderItemPart.meshGroup.position.y,
            position_z: orderItemPart.meshGroup.position.z,
            rotation_x: orderItemPart.meshGroup.rotation.x,
            rotation_y: orderItemPart.meshGroup.rotation.y,
            rotation_z: orderItemPart.meshGroup.rotation.z,
            active: orderItemPart.visible,
          },
        });
      }
    },

    updateOrderPlatform(platformMeta) {
      if (this.orderPlatforms && this.orderPlatforms.length) {
        this.orderPlatforms.forEach((orderPlatform) => {
          if (platformMeta.orderPlatformId === orderPlatform.id) {
            const orderPlatformData = {
              dots: [],
              area: 0,
              id: orderPlatform.id,
            };
            for (const dot of platformMeta.dots) {
              orderPlatformData.dots.push({
                x: dot.x,
                y: dot.y,
              });
            }
            orderPlatformData.area = platformMeta.area;
            const payload = {
              orderId: this.$route.params.id,
              orderPlatform: orderPlatformData,
            };
            this["orderPlatforms/update"](payload);
          }
        });
      }
    },

    updateOrder() {
      const saveOrder = Object.assign({}, this.order);
      saveOrder.max_width = russianStringToFloat(saveOrder.max_width);
      saveOrder.max_length = russianStringToFloat(saveOrder.max_length);
      this["orders/update"](saveOrder);
    },

    removeCurrentOrderItem() {
      if (this.currentOrderItem) {
        this.removeOrderItem(this.currentOrderItem);
        this.clearCurrentOrderItem();
      }
    },

    initOrderItems() {
      if (this.loadedFlags.orderItems) {
        return;
      }
      for (const orderItem of this.orderItems) {
        this.initOrderItem(orderItem);
        this.downloadEquipmentItemModel(orderItem);
      }
      this.loadedFlags.orderItems = true;
    },

    updateOrderItems() {
      for (const newOrderItemId of this.newOrderItems) {
        for (const orderItem of this.orderItems) {
          if (orderItem.id === newOrderItemId) {
            this.initOrderItem(orderItem);
            this.downloadEquipmentItemModel(orderItem);
            break;
          }
        }
      }
      this.newOrderItems = [];
    },

    initOrderPlatforms() {
      this.orderPlatforms.forEach((orderPlatform) => {
        this.addPlatform(orderPlatform);
      });
    },

    disposeTransformControls() {
      if (this.transformControls) {
        this.transformControls.detach();
      }
    },

    requestOrderUpdate: debounce(function () {
      this.updateOrder();
    }, 1500),

    requestOrderPlatformUpdate: debounce(function (platformMeta) {
      this.updateOrderPlatform(platformMeta);
    }, 1500),

    requestOrderItemUpdate: debounce(function (
      orderItem,
      saveToHistory = false
    ) {
      this.updateOrderItem(orderItem, saveToHistory);
    },
    1500),

    requestOrderItemPartUpdate: debounce(function (
      orderItemPart,
      saveToHistory = false
    ) {
      this.updateOrderItemPart(orderItemPart, saveToHistory);
    },
    1500),

    requestCurrentOrderItemPartUpdate: debounce(function (
      saveToHistory = false
    ) {
      if (this.currentOrderItemPart) {
        this.updateOrderItemPart(this.currentOrderItemPart, saveToHistory);
      }
    },
    1500),

    undoLastLocationChange() {
      if (this.locationHistory.length === 0) {
        return;
      }
      const lastChange = this.locationHistory.pop();
      let meshGroup = null;
      let subject = null;
      let savePosition = null;
      let saveRotation = null;
      if (lastChange.type === "equipmentItem") {
        subject = this.getOrderItemByCode(lastChange.key);
        if (subject) {
          meshGroup = subject.meta.modelGroup;
          savePosition = subject.meta.savePosition;
          saveRotation = subject.meta.saveRotation;
        }
      }
      if (lastChange.type === "equipmentPart") {
        const orderItem = this.getOrderItemByCode(lastChange.code);
        subject = this.getOrderItemPartByKey(orderItem, lastChange.key);
        if (subject) {
          meshGroup = subject.meshGroup;
          savePosition = subject.savePosition;
          saveRotation = subject.saveRotation;
        }
      }
      if (meshGroup) {
        meshGroup.position.x = lastChange.position.x;
        meshGroup.position.y = lastChange.position.y;
        meshGroup.position.z = lastChange.position.z;
        meshGroup.rotation.x = lastChange.rotation.x;
        meshGroup.rotation.y = lastChange.rotation.y;
        meshGroup.rotation.z = lastChange.rotation.z;
      }
      if (lastChange.type === "equipmentItem") {
        this.updateOrderItem(subject, false);
      }
      if (lastChange.type === "equipmentPart") {
        this.updateOrderItemPart(subject, false);
      }
    },

    // Рендерим схему
    renderScheme(showOrderItemCodes) {
      // Скрываем ненужное. Показывать вновь не нужно, т.к. запрос на рендер делается тогда, когда <Plan> неактивен
      this.scene.traverse((obj) => {
        if (
          ["dot", "dotHandle", "safeZoneMarker", "text-sprite"].includes(
            obj.name
          )
        ) {
          obj.visible = false;
        }
      });

      const maxSize =
        this.order.max_width > this.order.max_length
          ? this.order.max_width
          : this.order.max_length;
      const fontSize = 0.025 * maxSize;

      // Добавляем надписи

      if (showOrderItemCodes) {
        this.orderItems.forEach((orderItem) => {
          const sprite = new TextSprite({
            alignment: "left",
            color: "#000000",
            fontFamily: "sans-serif",
            fontSize,
            text: orderItem.code,
          });
          sprite.position.copy(orderItem.meta.label.position);

          // Придвигаем надпись вплотную к элементу
          sprite.position.x +=
            orderItem.equipmentItem.safe_zone_left - maxSize * 0.02;
          sprite.position.z +=
            orderItem.equipmentItem.safe_zone_top - maxSize * 0.02;

          sprite.position.y = 5;
          sprite.name = "text-sprite";

          this.scene.add(sprite);
        });
      }

      const platformMeta = this.getActivePlatform();
      const platformShape = new THREE.Shape();
      let i = 0;
      let firstDot = null;
      let previousX;
      let previousZ;
      for (const dotIndex in platformMeta.dots) {
        const dot = platformMeta.dots[dotIndex];
        if (i === 0) {
          firstDot = dot;
          platformShape.moveTo(dot.x, dot.y);
        } else {
          platformShape.lineTo(dot.x, dot.y);
          this.addPlatformSpriteLineLabel(
            platformMeta,
            dot.x,
            dot.y,
            previousX,
            previousZ
          );
        }
        previousX = dot.x;
        previousZ = dot.y;
        i++;
      }
      platformShape.lineTo(firstDot.x, firstDot.y);
      this.addPlatformSpriteLineLabel(
        platformMeta,
        firstDot.x,
        firstDot.y,
        previousX,
        previousZ
      );

      this.cameras.flat.zoom = 1.15;
      this.cameras.flat.updateProjectionMatrix();

      this.webGLRenderer.render(this.scene, this.cameras.flat);
      return this.webGLRenderer.domElement.toDataURL();
    },
    updateSafeZoneMarkersVisibility() {
      if (this.safeZoneMarkersVisible) {
        this.hideOrderItemSafeZoneMarkers();
      } else {
        this.restoreOrderItemSafeZoneMarkersVisibleState();
      }
    },
    updateOrderItemCodesVisibility() {
      if (this.orderItemCodesVisible) {
        this.hideOrderItemCodes();
      } else {
        this.restoreOrderItemCodesVisibleState();
      }
    },
  },
  mounted() {
    this.init();
    this.initOrderPlatforms();
    this.initOrderItems();
    this.setPlatformEditMode();
    this.animate();
    this.changeOrderMode(1);
  },
  watch: {
    orderItems() {
      this.updateOrderItems();
    },
  },
};
</script>
