import * as THREE from 'three'

class PlaneHelper extends THREE.LineSegments {
  constructor (
    length = 10,
    width = 10,
    gridStep = 1,
    presiceGridStep = 0.1,
    gridColor = 0x444444,
    presiceGridColor = 0x888888
  ) {
    gridColor = new THREE.Color(gridColor)
    presiceGridColor = new THREE.Color(presiceGridColor)

    const halfSizeX = width / 2
    const halfSizeZ = length / 2
    const presiceDivisionsX = width / presiceGridStep
    const presiceDivisionsZ = length / presiceGridStep
    const gridStepTopresiceGridStepRatio = gridStep / presiceGridStep

    const vertices = []
    const colors = []
    let j = 0
    for (let i = 0, ds = -halfSizeX, pds = -halfSizeX; i <= presiceDivisionsX; i++, ds += gridStep, pds += presiceGridStep) {
      vertices.push(-halfSizeZ, 0, pds, halfSizeZ, 0, pds)

      const color = i % gridStepTopresiceGridStepRatio === 0 ? gridColor : presiceGridColor

      color.toArray(colors, j)
      j += 3
      color.toArray(colors, j)
      j += 3
    }

    for (let i = 0, ds = -halfSizeZ, pds = -halfSizeZ; i <= presiceDivisionsZ; i++, ds += gridStep, pds += presiceGridStep) {
      vertices.push(pds, 0, -halfSizeX, pds, 0, halfSizeX)

      const color = i % gridStepTopresiceGridStepRatio === 0 ? gridColor : presiceGridColor

      color.toArray(colors, j)
      j += 3
      color.toArray(colors, j)
      j += 3
    }

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))

    const material = new THREE.LineBasicMaterial({
      vertexColors: true,
      toneMapped: false
    })

    super(geometry, material)

    this.type = 'PlaneHelper'
  }
}

export { PlaneHelper }
