<script src="../../../../npm_plan/npm_plan_view3d/src/View3dState.ts"></script>
<template>
  <CModal :show.sync="visible" :closeOnBackdrop="false" title="Выберите ракурс и масштаб снимка" size="xl" centered
    class="modal-plan-snapshot">
    <div id="container" class="canvas" />

    <template #footer>
      <CButton color="success" @click="handleSnapshot">Сделать снимок </CButton>
    </template>
  </CModal>
</template>

<style lang="scss">
.modal-plan-snapshot {
  .modal-dialog {
    max-width: 100vh;
  }

  .modal-body {
    padding: 10px;
    background: #eee;
  }

  // соотношение 16:10
  .canvas {
    width: 100%;
    height: 80vh;
    margin: 0 auto 5px;
  }
}
</style>

<script>
import { getDataObjectById } from "../_helpers";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { orderItemsService } from "../_services";
import { OBB } from "three/examples/jsm/math/OBB";
import { mapActions } from "vuex";

String.prototype.rsplit = function (sep, maxsplit) {
  let split = this.split(sep);
  return maxsplit
    ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
    : split;
};

export default {
  props: {
    user: Object,
    order: Object,
    orderItems: Array,
    orderItemId: Number,
    orderPlatforms: Array,
    equipmentItems: Array,
    equipmentParts: Array,
    colors: Array,
    coverings: Array,
    foundations: Array,
    mountTypes: Array,
    sputterings: Array,
  },
  emits: ["complete"],
  data() {
    return {
      visible: false,

      el: null,
      webGLRenderer: null,
      cssRenderer: null,
      raycaster: null,
      gltfLoader: null,
      textureLoader: null,
      scene: null,

      collapseFlat: false,
      collapseChoiceModel: false,
      collapseChangeModel: false,

      cameras: {
        flat: null,
        perspective: null,
        active: null,
      },
      lights: {
        plan: [],
        view: [],
      },
      controls: {
        flat: null,
        perspective: null,
      },
      mode: "platform-edit", // 'platform-edit' | 'order-item-placement' | 'order-item-edit'
      platformsMeta: [],
      mouse: { x: 0, y: 0 },
      poinerLabel: null,
      grid: null,
      gridCollider: null,
      glbLoading: false,
      flags: {
        canMoveCamera: false,
      },
      loadedFlags: {
        orderItems: false,
        orderPlatforms: false,
      },
      intersectedObject: null,
      keyboardModifiers: {
        shift: false,
        control: false,
        alt: false,
      },
      geometries: {
        platformDot: null,
        gridCollider: null,
      },
      palette: {
        validSafeZoneMarkerPlacementColor: null,
        invalidSafeZoneMarkerPlacementColor: null,
        platformBoundingSphere: null,
        platformDot: null,
        bar: null,
        pillar: null,
        cap: null,
        clamp: null,
        wood: null,
        textile: null,
        zinc: null,
        black: null,
      },
      materials: {
        validSafeZoneMarker: null,
        invalidSafeZoneMarker: null,
        platformDot: null,
        platform: null,
        cap: null,
        bar: null,
        clamp: null,
        wood: null,
        textile: null,
        zinc: null,
        black: null,
      },
      storeSubscription: null,
      currentOrderItem: null,
      currentOrderItemPart: null,
      newOrderItems: [],
      detailMeshes: [],
      animationFrameId: null,
    };
  },
  components: {},
  computed: {},

  beforeDestroy() {
    this.dispose();
  },
  methods: {
    ...mapActions([
      "orders/update",
      "orderItems/create",
      "orderItems/update",
      "orderItems/delete",
      "orderPlatforms/update",
      "dictionaryCoverings/getAll",
      "dictionaryMountTypes/getAll",
      "dictionarySputterings/getAll",
    ]),

    initCanvas() {
      this.el = document.getElementById("container");
      this.el.addEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
      this.viewportWidth = this.el.clientWidth;
      this.viewportHeight = this.el.clientHeight;
    },

    updateCanvasSize() {
      this.viewportWidth = this.el.clientWidth;
      this.viewportHeight = this.el.clientHeight;
      this.cameras.perspective.aspect =
        this.viewportWidth / this.viewportHeight;
      this.cameras.perspective.updateProjectionMatrix();

      this.webGLRenderer.setSize(this.viewportWidth, this.viewportHeight);
    },

    initWebGLRenderer() {
      this.webGLRenderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      this.webGLRenderer.physicallyCorrectLights = true;
      this.webGLRenderer.outputEncoding = THREE.sRGBEncoding;
      this.webGLRenderer.shadowMap.enabled = true;
      this.webGLRenderer.shadowMap.type = THREE.PCFSoftShadowMap;

      this.webGLRenderer.setClearColor(0xffffff); // цвет фона 
      this.webGLRenderer.setPixelRatio(window.devicePixelRatio);
      this.webGLRenderer.setSize(this.viewportWidth, this.viewportHeight);
      this.el.appendChild(this.webGLRenderer.domElement);
    },

    initScene() {
      this.scene = new THREE.Scene();
    },

    initLights() {
      const dirLight = new THREE.DirectionalLight(0xffffff, 1.5);

      dirLight.castShadow = true;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 500;
      dirLight.shadow.camera.right = 10;
      dirLight.shadow.camera.left = -10;
      dirLight.shadow.camera.top = 10;
      dirLight.shadow.camera.bottom = -10;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      dirLight.shadow.radius = 0.05;
      dirLight.shadow.bias = -0.0001;
      dirLight.shadow.bias = 0;

      dirLight.position.set(-1, 5, 1);

      const hemisphereLight = new THREE.HemisphereLight(
        0xffffff,
        0x444444,
        1.5
      );
      hemisphereLight.position.set(0, 0.5, 0);

      this.lights.view.push(dirLight);
      this.lights.view.push(hemisphereLight);

      this.scene.add(...this.lights.view);
    },

    initModelLoader() {
      THREE.ImageUtils.crossOrigin = "";
      const saveThis = this;
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function () {};

      loadingManager.onError = function (xhr) {
        saveThis.glbLoading = false;
      };

      this.gltfLoader = new GLTFLoader(loadingManager);
    },

    initTextureLoader() {
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function () {};

      loadingManager.onError = function (xhr) {};

      this.textureLoader = new THREE.TextureLoader(loadingManager);
    },

    initPerspectiveCamera() {
      const width = this.viewportWidth;
      const height = this.viewportHeight;
      this.cameras.perspective = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.001,
        10000
      );
      this.cameras.perspective.position.set(10, 10, 10);
      this.cameras.perspective.lookAt(0, 0, 0);
      this.cameras.perspective.updateProjectionMatrix();
    },

    initPerspectiveControls() {
      this.controls.perspective = new OrbitControls(
        this.cameras.perspective,
        this.webGLRenderer.domElement
      );
      this.controls.perspective.minPolarAngle = -Math.PI * 0.49;
      this.controls.perspective.maxPolarAngle = Math.PI * 0.49;
      this.controls.perspective.enabled = false;
      this.controls.perspective.update();
    },

    initGeometries() {
      this.geometries.platformDot = new THREE.SphereGeometry(0.1, 10, 10);
      this.geometries.platformDotHandle = new THREE.SphereGeometry(0.3, 10, 10);
    },

    disposeGeometries() {
      this.geometries.platformDot.dispose();
      this.geometries.platformDotHandle.dispose();
      this.scene.traverse((object) => {
        if (object.geometry) {
          object.geometry.dispose();
        }
      });
    },

    initPalette() {
      this.palette.validSafeZoneMarkerPlacementColor = new THREE.Color();
      this.palette.validSafeZoneMarkerPlacementColor.setHSL(3 / 8, 1, 0.5);

      this.palette.invalidSafeZoneMarkerPlacementColor = new THREE.Color();
      this.palette.invalidSafeZoneMarkerPlacementColor.setHSL(0 / 8, 1, 0.5);

      this.palette.platformDot = new THREE.Color(0xffffff);
      this.palette.platform = new THREE.Color(0xffffff);

      this.palette.bar = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 7016"))
      );
      this.palette.pillar = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 7016"))
      );
      this.palette.cap = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 7016"))
      );
      this.palette.clamp = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 5018"))
      );
      this.palette.zinc = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 9003"))
      );
      this.palette.black = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 9005"))
      );
      this.palette.wood = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 8017"))
      );
      this.palette.textile = new THREE.Color(
        this.colorToHex(this.getColorByRALCode("RAL 1015"))
      );
    },

    initMaterials() {
      const textureLoader = new THREE.TextureLoader();
      const sandTexture = textureLoader.load("/textures/sand-1024.jpg");
      sandTexture.wrapS = sandTexture.wrapT = THREE.RepeatWrapping;

      this.materials.validSafeZoneMarker = new THREE.MeshPhongMaterial({
        color: this.palette.validSafeZoneMarkerPlacementColor,
        opacity: 0.5,
        transparent: true,
        emissive: 0xffff00,
        emissiveIntensity: 0,
      });
      this.materials.invalidSafeZoneMarker = new THREE.MeshPhongMaterial({
        color: this.palette.invalidSafeZoneMarkerPlacementColor,
        opacity: 0.5,
        transparent: true,
        emissive: 0xffff00,
        emissiveIntensity: 0,
      });
      this.materials.collider = new THREE.MeshPhongMaterial({
        color: this.palette.platform,
        opacity: 0,
        transparent: true,
      });
      this.materials.testCollider = new THREE.MeshPhongMaterial({
        color: this.palette.platform,
        opacity: 0.5,
        transparent: true,
      });
      this.materials.platformDot = new THREE.MeshBasicMaterial({
        color: this.palette.platformDot,
      });
      this.materials.platform = new THREE.MeshBasicMaterial({
        map: sandTexture,
        side: THREE.DoubleSide,
      });
      this.materials.bar = new THREE.MeshStandardMaterial({
        color: this.palette.bar,
        side: THREE.DoubleSide,
      });
      this.materials.pillar = new THREE.MeshStandardMaterial({
        color: this.palette.pillar,
        side: THREE.DoubleSide,
      });
      this.materials.cap = new THREE.MeshStandardMaterial({
        color: this.palette.cap,
        side: THREE.DoubleSide,
      });
      this.materials.clamp = new THREE.MeshStandardMaterial({
        color: this.palette.clamp,
        side: THREE.DoubleSide,
      });
      this.materials.zinc = new THREE.MeshStandardMaterial({
        color: this.palette.zinc,
        side: THREE.DoubleSide,
      });
      this.materials.black = new THREE.MeshStandardMaterial({
        color: this.palette.black,
        side: THREE.DoubleSide,
      });
      this.materials.wood = new THREE.MeshStandardMaterial({
        color: this.palette.wood,
        side: THREE.DoubleSide,
      });
      this.materials.textile = new THREE.MeshStandardMaterial({
        color: this.palette.textile,
        side: THREE.DoubleSide,
      });
    },

    initOrderColors() {
      if (this.order.bar_color) {
        this.palette.bar.setHex(
          this.colorToHex(this.getColorById(this.order.bar_color))
        );
        this.materials.bar.color = this.palette.bar;
      }
      if (this.order.pillar_color) {
        this.palette.pillar.setHex(
          this.colorToHex(this.getColorById(this.order.pillar_color))
        );
        this.materials.pillar.color = this.palette.pillar;
      }
      if (this.order.cap_color) {
        this.palette.cap.setHex(
          this.colorToHex(this.getColorById(this.order.cap_color))
        );
        this.materials.cap.color = this.palette.cap;
      }
      if (this.order.clamp_color) {
        this.palette.clamp.setHex(
          this.colorToHex(this.getColorById(this.order.clamp_color))
        );
        this.materials.clamp.color = this.palette.clamp;
      }
      if (this.order.wood_color) {
        this.palette.wood.setHex(
          this.colorToHex(this.getColorById(this.order.wood_color))
        );
        this.materials.wood.color = this.palette.wood;
      }
      if (this.order.textile_color) {
        this.palette.textile.setHex(
          this.colorToHex(this.getColorById(this.order.textile_color))
        );
        this.materials.textile.color = this.palette.textile;
      }
    },

    disposeMaterials() {
      this.materials.validSafeZoneMarker.dispose();
      this.materials.invalidSafeZoneMarker.dispose();
      this.materials.collider.dispose();
      this.materials.testCollider.dispose();
      this.materials.platformDot.dispose();
      this.materials.platform.dispose();
      this.materials.bar.dispose();
      this.materials.pillar.dispose();
      this.materials.cap.dispose();
      this.materials.clamp.dispose();
      this.materials.wood.dispose();
      this.materials.textile.dispose();
      this.materials.zinc.dispose();
      this.materials.black.dispose();
    },

    init() {
      this.initCanvas();
      this.initWebGLRenderer();
      this.initScene();
      this.initLights();
      this.initModelLoader();
      this.initTextureLoader();
      this.initPerspectiveCamera();
      this.initPerspectiveControls();
      this.initGeometries();
      this.initPalette();
      this.initMaterials();
      this.initOrderColors();
    },

    dispose() {
      this.disposeMaterials();
    },

    getActivePlatform() {
      return this.platformsMeta[0];
    },

    addPlatformDot(
      platformMeta,
      x,
      z,
      calculateOrder = false,
      afterDotIndex = -1
    ) {
      const key = x + "_" + z;
      const dotsCoordinates = [];
      for (const testDotIndex in platformMeta.dots) {
        const testDot = platformMeta.dots[testDotIndex];
        dotsCoordinates.push({ x: testDot.x, z: testDot.y });
        if (testDot.key === key) {
          return;
        }
      }
      let dotIndex;
      if (calculateOrder) {
        dotIndex = this.getPlatformDotIndex(dotsCoordinates, x, z);
      } else if (afterDotIndex !== -1) {
        dotIndex = afterDotIndex;
      } else {
        dotIndex = dotsCoordinates.length - 1;
      }

      const dot = new THREE.Mesh(
        // this.geometries.platformDot,
        new THREE.BoxGeometry(),
        this.materials.platformDot
      );
      const dotHandle = new THREE.Mesh(
        this.geometries.platformDotHandle,
        new THREE.BoxGeometry(),
        this.materials.testCollider
      );
      dot.position.x = x;
      dot.position.z = z;
      dot.position.y = 0;
      dotHandle.position.x = x;
      dotHandle.position.z = z;
      dotHandle.position.y = 0;
      // this.scene.add(dot);
      // this.scene.add(dotHandle);
      const dotMeta = {
        dot: dot,
        handle: dotHandle,
        key: key,
        x: dot.position.x,
        y: dot.position.z,
      };
      platformMeta.dots.splice(dotIndex, 0, dotMeta);
      // platformMeta.controls.getObjects().push(dotMeta.handle);
      return dotMeta;
    },

    updatePointerLabelPosition(x, z) {
      this.pointerLabel.position.set(x, 0.3, z - 0.5);
      const halfWidth = this.order.max_length / 2;
      const halfLength = this.order.max_width / 2;
      const positionX = Math.round((x + halfWidth + Number.EPSILON) * 10) / 10;
      const positionZ = Math.round((z + halfLength + Number.EPSILON) * 10) / 10;
      this.pointerLabel.userData.element.textContent = `${positionX},${positionZ}`;
    },

    deactivateAllOrderItemParts() {
      for (const orderItem of this.orderItems) {
        for (const orderItemPart of orderItem.parts) {
          orderItemPart.active = false;
          for (const detailMesh of orderItemPart.detailMeshes) {
            detailMesh.material = detailMesh.userData.originalMaterial;
          }
        }
      }
    },

    activateOrderItemPart(orderItemPart) {
      orderItemPart.active = true;
      for (const detailMesh of orderItemPart.detailMeshes) {
        detailMesh.material = this.materials.validSafeZoneMarker;
      }
      // const partMarkerBox = new THREE.Box3().setFromObject(
      //   orderItemPart.meshGroup
      // );

      // const partMarkerSize = new THREE.Vector3();
      // partMarkerBox.getSize(partMarkerSize);
      // partMarkerSize.addScalar(0.2);

      // const partMarkerCenter = new THREE.Vector3();
      // partMarkerBox.getCenter(partMarkerCenter);
      // partMarkerBox.setFromCenterAndSize(
      //   partMarkerCenter,
      //   partMarkerSize
      // );
      // const geometry = new THREE.BoxGeometry(
      //   partMarkerSize.x,
      //   partMarkerSize.y,
      //   partMarkerSize.z
      // );
      // orderItemPart.partMarker = new THREE.Mesh(
      //   geometry,
      //   this.materials.validSafeZoneMarker
      // );
      // orderItemPart.meshGroup.add(orderItemPart.partMarker)
    },

    linesIntersect(a, b, c, d, p, q, r, s) {
      let det, gamma, lambda;
      det = (c - a) * (s - q) - (r - p) * (d - b);
      if (det === 0) {
        return false;
      } else {
        lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
        gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
        return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
      }
    },

    setGridVisibleState(state) {
      if (this.grid) {
        this.grid.visible = state;
      }
    },

    hideGrid() {
      this.setGridVisibleState(false);
    },

    showGrid() {
      this.setGridVisibleState(true);
    },

    setOrderItemSafeZoneMarkersVisibleState(state) {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.safeZoneMarker) {
          orderItem.meta.safeZoneMarker.visible = state;
        }
      }
    },

    hideOrderItemSafeZoneMarkers() {
      this.setOrderItemSafeZoneMarkersVisibleState(false);
    },

    showOrderItemSafeZoneMarkers() {
      this.setOrderItemSafeZoneMarkersVisibleState(true);
    },

    setPlatfromDotsVisibleState(state) {
      this.platformsMeta.forEach((platformMeta) => {
        for (const dotKey in platformMeta.dots) {
          platformMeta.dots[dotKey].dot.visible = state;
          platformMeta.dots[dotKey].handle.visible = state;
        }
      });
    },

    hidePlatformDots() {
      this.setPlatfromDotsVisibleState(false);
    },

    showPlatformDots() {
      this.setPlatfromDotsVisibleState(true);
    },

    setPlatfromLabelsVisibleState(state) {
      this.platformsMeta.forEach((platformMeta) => {
        for (const platformLabel of platformMeta.lineLabels) {
          platformLabel.visible = state;
        }
      });
    },

    hidePlatformLabels() {
      this.setPlatfromLabelsVisibleState(false);
    },

    showPlatformLabels() {
      this.setPlatfromLabelsVisibleState(true);
    },

    setControlsState(controlsType, state) {
      if (this.controls[controlsType]) {
        this.controls[controlsType].enabled = state;
      }
    },

    disablePerspectiveControls() {
      this.setControlsState("perspective", false);
    },

    enablePerspectiveControls() {
      this.setControlsState("perspective", true);
    },

    switchToPerspectiveCamera() {
      this.switchCamera("perspective");
    },

    clearCurrentOrderItem() {
      this.deactivateAllOrderItemParts();
      this.currentOrderItemPart = null;
      this.currentOrderItem = null;
    },

    dimOrderItems() {},

    normalizeOrderItems() {},

    setOrderItemsEnabledState(state) {
      for (const orderItem of this.orderItems) {
        if (orderItem.meta.controls.flat) {
          orderItem.meta.controls.flat.enabled = state;
        }
      }
    },

    disableOrderItems() {
      this.setOrderItemsEnabledState(false);
    },

    enableOrderItems() {
      this.setOrderItemsEnabledState(true);
    },

    setOrderItemsEditMode() {
      // this.switchToViewLights();
      this.switchToPerspectiveCamera();
      this.enablePerspectiveControls();
      // this.dimOrderItems();
      // this.disableOrderItems();
      // this.disablePlatformEditControls();
      // this.hideGrid();
      // this.hidePlatformDots();
      // this.hidePlatformLabels();
      // this.hideOrderItemSafeZoneMarkers();
      // this.clearCurrentOrderItem();
    },

    switchCamera(cameraType) {
      this.cameras.active = this.cameras[cameraType];
      this.cameras.active.updateProjectionMatrix();
      // this.settleControls();
    },

    animate() {
      this.animationFrameId = requestAnimationFrame(this.animate);
      this.moveCurrentOrderItemPart();
      // this.cssRenderer.render(this.scene, this.cameras.active);

      this.webGLRenderer.render(this.scene, this.cameras.active);
      if (
        this.el.clientWidth != this.viewportWidth ||
        this.el.clientHeight != this.viewportHeight
      ) {
        this.updateCanvasSize();
      }
    },

    getColorById(id) {
      return getDataObjectById(this.colors, id);
    },

    getColorByRALCode(ralCode) {
      for (const color of this.colors) {
        if (color.ral_code === ralCode) {
          return color;
        }
      }
      return null;
    },

    downloadEquipmentItemModel(orderItem) {
      this.glbLoading = true;
      this.gltfLoader.load(
        orderItem.equipmentItem.model_render,
        (gltf) => {
          this.glbLoading = false;
          orderItem.meta.model = gltf.scene || gltf.scenes[0];
          orderItem.meta.model.userData.orderItem = orderItem;
          this.updateMeshMaterials(orderItem.meta.model.children);
          orderItem.meta.model.position.x =
            orderItem.equipmentItem.scene_position_x;
          orderItem.meta.model.position.y =
            orderItem.equipmentItem.scene_position_y;
          orderItem.meta.model.position.z =
            orderItem.equipmentItem.scene_position_z;
          orderItem.meta.model.rotation.x =
            orderItem.equipmentItem.scene_rotation_x;
          orderItem.meta.model.rotation.y =
            orderItem.equipmentItem.scene_rotation_y;
          orderItem.meta.model.rotation.z =
            orderItem.equipmentItem.scene_rotation_z;
          this.addOrderItemToScene(orderItem);
        }
      );
    },

    getEquipmentPartByCode(equipmentPartCode) {
      for (const equipmentPart of this.equipmentParts) {
        if (equipmentPartCode == equipmentPart.code) {
          return equipmentPart;
        }
      }
      return null;
    },

    initOrderItemPart(orderItem, equipmentPartKey, equipmentPartType) {
      let orderItemPart = this.getOrderItemPartByKey(
        orderItem,
        equipmentPartKey
      );
      if (orderItemPart) {
        return;
      }
      const equipmentPartCode =
        this.getEquipmentPartCodeFromKey(equipmentPartKey);

      orderItemPart = {
        key: equipmentPartKey,
        code: equipmentPartCode,
        equipmentPart: this.getEquipmentPartByCode(equipmentPartCode),
        detailKeys: [],
        detailMeshes: [],
        controls: null,
        meshGroup: new THREE.Group(),
        orderItem: orderItem,
        visible: true,
        active: false,
        type: equipmentPartType,
      };
      orderItem.meta.modelGroup.add(orderItemPart.meshGroup);
      orderItem.parts.push(orderItemPart);
      return orderItemPart;
    },

    updateMeshMaterials(meshes) {
      for (const mesh of meshes) {
        const meshName = mesh.userData.name;
         if (meshName.startsWith("Q-1")) {
          mesh.material.dispose();
          mesh.material = this.materials.cap;
        } else if (meshName.startsWith("X-108")) {
          mesh.material.dispose();
          mesh.material = this.materials.clamp;
        } else if (meshName.startsWith("V-")) {
          mesh.material.dispose();
          mesh.material = this.materials.pillar;
        } else if (meshName.startsWith("E-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("Z-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("R-2000-Tt")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("R-")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("PE-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("BS-1200-Dt")) {
          mesh.material.dispose();
          mesh.material = this.materials.zinc;
        } else if (meshName.startsWith("BS-1200-DBR")) {
          mesh.material.dispose();
          mesh.material = this.materials.zinc;
        } else if (meshName.startsWith("BS-1200-DN")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("BS-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("EE-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("EW")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("F-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("FV-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("FD-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("DE-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("D-")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("B-1500-C")) {
          mesh.material.dispose();
          mesh.material = this.materials.bar;
        } else if (meshName.startsWith("B-1500")) {
          mesh.material.dispose();
          mesh.material = this.materials.wood;
        } else if (meshName.startsWith("BB-1300")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("BB-1500")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("SB-1300")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        } else if (meshName.startsWith("H-2-D5D9")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("H-2-D7")) {
          mesh.material.dispose();
          mesh.material = this.materials.textile;
        } else if (meshName.startsWith("H-2")) {
          mesh.material.dispose();
          mesh.material = this.materials.black;
        }
      }
    },

    downloadEquipmentPartModel(orderItemPart, orderItemPartMeta) {
      this.gltfLoader.load(
        orderItemPart.equipmentPart.model_constructor,
        (gltf) => {
          this.glbLoading = false;
          orderItemPart.model = gltf.scene || gltf.scenes[0];
          orderItemPart.model.userData.orderItemPart = orderItemPart;
          orderItemPart.model.position.x =
            orderItemPart.equipmentPart.scene_position_x;
          orderItemPart.model.position.y =
            orderItemPart.equipmentPart.scene_position_y;
          orderItemPart.model.position.z =
            orderItemPart.equipmentPart.scene_position_z;
          orderItemPart.model.rotation.x =
            orderItemPart.equipmentPart.scene_rotation_x;
          orderItemPart.model.rotation.y =
            orderItemPart.equipmentPart.scene_rotation_y;
          orderItemPart.model.rotation.z =
            orderItemPart.equipmentPart.scene_rotation_z;
          this.updateMeshMaterials(orderItemPart.model.children);
          this.addOrderItemPartToScene(orderItemPart, orderItemPartMeta);
        }
      );
    },

    addOrderItemPartToScene(orderItemPart, orderItemPartMeta) {
      orderItemPart.orderItem.meta.modelGroup.add(orderItemPart.model);
      orderItemPart.orderItem.meta.modelGroup.traverse((obj) => {
        if (obj.isMesh) {
          obj.castShadow = true;
          obj.receiveShadow = true;
        }
      });
      this.initOrderItemPartMeshGroup(
        orderItemPart,
        orderItemPartMeta,
        orderItemPart.model
      );
    },

    changeCurrentOrderItem(orderItem) {
      this.currentOrderItem = orderItem;
      if (!this.currentOrderItem) {
        this.currentOrderItemPart = null;
      }
    },

    changeCurrentOrderItemPart(orderItemPart) {
      // this.deactivateAllOrderItemParts();
      // if (this.currentOrderItemPart) {
      //   this.detachOrderItemPartMeshesFromGroup(orderItemPart);
      // }
      this.currentOrderItemPart = orderItemPart;
      if (orderItemPart) {
        this.activateOrderItemPart(orderItemPart);
      }
      // if (this.currentOrderItemPart) {
      //   this.attachOrderItemPartMeshesToGroup(orderItemPart);
      // }
    },

    moveCurrentOrderItemPart() {
      if (this.mode !== "order-item-edit") {
        return;
      }
      if (!this.currentOrderItemPart) {
        return;
      }
      const offset = this.keyboardModifiers.shift ? 0.1 : 0.01;
      this.currentOrderItemPart.meshGroup.position.x = parseFloat(
        this.currentOrderItemPart.meshGroup.position.x
      );
      this.currentOrderItemPart.meshGroup.position.z = parseFloat(
        this.currentOrderItemPart.meshGroup.position.z
      );
      let updateOrderItemPart = false;
      if (this.keyboardModifiers.alt) {
        if (this.keyboardModifiers.left) {
          this.currentOrderItemPart.meshGroup.rotation.y += 0.1;
          updateOrderItemPart = true;
        }
        if (this.keyboardModifiers.right) {
          this.currentOrderItemPart.meshGroup.rotation.y -= 0.1;
          updateOrderItemPart = true;
        }
      } else {
        if (this.keyboardModifiers.left) {
          this.currentOrderItemPart.meshGroup.position.x -= offset;
          updateOrderItemPart = true;
        }
        if (this.keyboardModifiers.up) {
          this.currentOrderItemPart.meshGroup.position.z += offset;
          updateOrderItemPart = true;
        }
        if (this.keyboardModifiers.right) {
          this.currentOrderItemPart.meshGroup.position.x += offset;
          updateOrderItemPart = true;
        }
        if (this.keyboardModifiers.down) {
          this.currentOrderItemPart.meshGroup.position.z -= offset;
          updateOrderItemPart = true;
        }
        this.currentOrderItemPart.meshGroup.position.x = parseFloat(
          this.currentOrderItemPart.meshGroup.position.x.toFixed(2)
        );
        this.currentOrderItemPart.meshGroup.position.z = parseFloat(
          this.currentOrderItemPart.meshGroup.position.z.toFixed(2)
        );
      }
      if (updateOrderItemPart) {
        this.requestCurrentOrderItemPartUpdate();
      }
    },

    hideOrderItemPart(orderItemPart) {
      this.changeOrderItemPartVisibility(orderItemPart, false);
    },

    changeOrderItemPartVisibility(orderItemPart, state) {
      orderItemPart.visible = state;
      for (const mesh of orderItemPart.detailMeshes) {
        mesh.visible = state;
      }
    },

    changeCurrentOrderItemPartVisibility(state) {
      this.changeOrderItemPartVisibility(this.currentOrderItemPart, state);
      if (state) {
        this.addOrderItemPartMeshesToGlobalMeshCollection(
          this.currentOrderItemPart
        );
      } else {
        this.removeOrderItemPartMeshesFromGlobalMeshCollection(
          this.currentOrderItemPart
        );
      }
      this.requestCurrentOrderItemPartUpdate();
    },

    addEquipmentItemToOrder(equipmentItem) {
      if (!equipmentItem) {
        return;
      }
      const newOrderItem = {
        orderId: this.$route.params.id,
        orderItem: {
          id: null,
          code: this.generateUniqueOrderItemCode(equipmentItem),
          name: equipmentItem.name,
          description: equipmentItem.description,
          position_x: 0,
          position_y: 0,
          position_z: 0,
          rotation_x: 0,
          rotation_y: 0,
          rotation_z: 0,
          item: equipmentItem.id,
          equipment_price: parseFloat(equipmentItem.equipment_price),
          installation_price: parseFloat(equipmentItem.price),
        },
      };
      this["orderItems/create"](newOrderItem);
    },

    addEquipmentPart(equipmentPart) {
      if (!equipmentPart) {
        return;
      }
      const orderItem = this.currentOrderItem;
      const adjustedAdditionalPartKey = this.getNextAvailableOrderPartKey(
        equipmentPart.code,
        orderItem
      );
      orderItemsService
        .addOrUpdatePart(orderItem.id, adjustedAdditionalPartKey, {
          key: adjustedAdditionalPartKey,
          equipment_part: equipmentPart.id,
          type: "additional",
          active: true,
        })
        .then((orderItemPartMeta) => {
          this.glbLoading = true;
          const orderItemPart = this.initOrderItemPart(
            orderItem,
            orderItemPartMeta.key,
            orderItemPartMeta.type
          );
          orderItemPart.equipmentPart = equipmentPart;
          this.downloadEquipmentPartModel(orderItemPart, orderItemPartMeta);
        });
    },

    getNextAvailableOrderPartKey(orderPartCode, orderItem) {
      let keyAvailable = false;
      let currentKeyIndex = 1;
      let currentKey;
      let hasKey;
      while (!keyAvailable) {
        currentKey = `${orderPartCode}_${currentKeyIndex}`;
        hasKey = false;
        for (const orderPart of orderItem.parts) {
          if (currentKey === orderPart.key) {
            hasKey = true;
            break;
          }
        }
        if (!hasKey) {
          keyAvailable = true;
          break;
        }
        currentKeyIndex++;
      }
      return currentKey;
    },

    removeOrderItemFromScene(orderItem) {
      orderItem.meta.safeZoneMarker.geometry.dispose();
      this.scene.remove(orderItem.meta.modelGroup);
      this.scene.remove(orderItem.meta.label);
      this.checkIntersections();
    },

    removeOrderItem(orderItem) {
      this.removeOrderItemFromScene(orderItem);
      this["orderItems/delete"]({
        orderId: this.$route.params.id,
        orderItemId: orderItem.id,
      });
    },

    addPlatform(platform) {
      const platformMeta = {
        area: 0.0,
        dots: [],
        plane: null,
        lineLabels: [],
        controls: null,
        orderPlatformId: platform.id,
      };

      // platformMeta.controls.enabled = false;
      for (const dotIndex in platform.dots) {
        const dot = platform.dots[dotIndex];
        this.addPlatformDot(platformMeta, dot.x, dot.y);
      }
      this.addPlatformPlane(platformMeta);
      this.platformsMeta.push(platformMeta);
    },

    updatePlatformPlane(platformMeta) {
      this.removePlatformPlane(platformMeta);
      this.addPlatformPlane(platformMeta);
      this.requestOrderPlatformUpdate(platformMeta);
    },

    removePlatformPlane(platformMeta) {
      const plane = platformMeta.plane;
      plane.geometry.dispose();
      while (platformMeta.lineLabels.length > 0) {
        const lineLabel = platformMeta.lineLabels.pop();
        lineLabel.userData.element.remove();
        this.scene.remove(lineLabel);
      }
      this.scene.remove(platformMeta.plane);
    },

    addPlatformPlane(platformMeta) {
      const platformShape = new THREE.Shape();
      let i = 0;
      let firstDot = null;
      let previousX;
      let previousZ;
      for (const dotIndex in platformMeta.dots) {
        const dot = platformMeta.dots[dotIndex];
        if (i === 0) {
          firstDot = dot;
          platformShape.moveTo(dot.x, dot.y);
        } else {
          platformShape.lineTo(dot.x, dot.y);
        }
        i++;
      }
      platformShape.lineTo(firstDot.x, firstDot.y);

      const geometry = new THREE.ShapeGeometry(platformShape);
      platformMeta.plane = new THREE.Mesh(geometry, this.materials.platform);
      platformMeta.plane.receiveShadow = true;
      platformMeta.plane.rotation.x = Math.PI / 2;
      platformMeta.area =
        Math.round(
          (THREE.ShapeUtils.area(platformMeta.dots) + Number.EPSILON) * 100
        ) / 100;
      this.scene.add(platformMeta.plane);
    },

    addBoxHelperToOrderItem(orderItem) {
      const safeZoneMarkerBox = new THREE.Box3().setFromObject(
        orderItem.meta.model
      );

      const safeZoneMarkerSize = new THREE.Vector3();
      safeZoneMarkerBox.getSize(safeZoneMarkerSize);

      const safeZoneTop = parseFloat(orderItem.equipmentItem.safe_zone_top);
      const safeZoneRight = parseFloat(orderItem.equipmentItem.safe_zone_right);
      const safeZoneBottom = parseFloat(
        orderItem.equipmentItem.safe_zone_bottom
      );
      const safeZoneLeft = parseFloat(orderItem.equipmentItem.safe_zone_left);

      const offsetX = -((safeZoneLeft - safeZoneRight) / 2);
      const offsetY = (safeZoneMarkerSize.y + 0.5) / 2;
      const offsetZ = -((safeZoneTop - safeZoneBottom) / 2);

      const x = safeZoneLeft + safeZoneRight + safeZoneMarkerSize.x;
      const y = safeZoneMarkerSize.y + 0.5;
      const z = safeZoneTop + safeZoneBottom + safeZoneMarkerSize.z;
      const geometry = new THREE.BoxGeometry(x, y, z);
      orderItem.meta.safeZoneMarker = new THREE.Mesh(
        geometry,
        this.materials.validSafeZoneMarker
      );
      orderItem.meta.safeZoneMarker.position.x = offsetX;
      orderItem.meta.safeZoneMarker.position.y = offsetY;
      orderItem.meta.safeZoneMarker.position.z = offsetZ;

      const adjustedSafeZoneMarkerBox = new THREE.Box3().setFromObject(
        orderItem.meta.safeZoneMarker
      );
      const adjustedSafeZoneMarkerSize = new THREE.Vector3();
      adjustedSafeZoneMarkerBox.getSize(adjustedSafeZoneMarkerSize);

      geometry.userData.obb = new OBB();
      geometry.userData.obb.halfSize
        .copy(adjustedSafeZoneMarkerSize)
        .multiplyScalar(0.5);
      orderItem.meta.safeZoneMarker.userData.obb = new OBB();
      orderItem.meta.safeZoneMarker.userData.orderItem = orderItem;
      orderItem.meta.modelGroup.add(orderItem.meta.safeZoneMarker);
    },

    getOrderItemPartByKey(orderItem, equipmentPartKey) {
      for (const orderItemPart of orderItem.parts) {
        if (orderItemPart.key === equipmentPartKey) {
          return orderItemPart;
        }
      }
      return null;
    },

    getMeshesByKeys(orderItemPart) {
      const resultMeshes = [];
      const meshKeys =
        orderItemPart.orderItem.equipmentItem.parts_map[orderItemPart.key];
      if (orderItemPart.orderItem.meta.model.children && meshKeys) {
        for (const modelMesh of orderItemPart.orderItem.meta.model.children) {
          for (const meshKey of meshKeys) {
            if (meshKey === modelMesh.userData.name) {
              resultMeshes.push(modelMesh);
            }
          }
        }
      }
      return resultMeshes;
    },

    initOrderItemPartMeshGroup(orderItemPart, orderItemPartMeta, model = null) {
      if (model) {
        orderItemPart.detailMeshes = [...model.children];
      } else {
        orderItemPart.detailMeshes = this.getMeshesByKeys(orderItemPart);
      }
      for (const mesh of orderItemPart.detailMeshes) {
        orderItemPart.meshGroup.attach(mesh);
        mesh.userData.originalMaterial = mesh.material;
        mesh.userData.orderItemPart = orderItemPart;
        if (orderItemPart.visible) {
          this.addMeshToGlobalMeshCollection(mesh);
        }
      }
      if (!orderItemPart.visible) {
        this.hideOrderItemPart(orderItemPart);
      }
      orderItemPart.meshGroup.position.x = orderItemPartMeta.position_x;
      orderItemPart.meshGroup.position.y = orderItemPartMeta.position_y;
      orderItemPart.meshGroup.position.z = orderItemPartMeta.position_z;
      orderItemPart.meshGroup.rotation.x = orderItemPartMeta.rotation_x;
      orderItemPart.meshGroup.rotation.y = orderItemPartMeta.rotation_y;
      orderItemPart.meshGroup.rotation.z = orderItemPartMeta.rotation_z;
    },

    getEquipmentPartCodeFromKey(equipmentPartKey) {
      return equipmentPartKey.rsplit("_", 1)[0];
    },

    initOrderItemParts(orderItem, orderItemParts) {
      for (const orderItemPartMeta of orderItemParts) {
        const orderItemPart = this.initOrderItemPart(
          orderItem,
          orderItemPartMeta.key,
          orderItemPartMeta.type
        );
        if (orderItemPartMeta.type === "own") {
          orderItemPart.visible = orderItemPartMeta.active;
          this.initOrderItemPartMeshGroup(orderItemPart, orderItemPartMeta);
        } else {
          this.downloadEquipmentPartModel(orderItemPart, orderItemPartMeta);
        }
      }
    },

    centerCameraOnEquipmentItem(equipmentItem) {
      const camera = this.cameras.perspective;

      const boundingBox = new THREE.Box3();
      boundingBox.setFromObject(equipmentItem.modelGroup);
      const buffer = new THREE.Vector3();
      const size = boundingBox.getSize(buffer);
      const maxDim = Math.max(size.x, size.y, size.z);

      this.controls.perspective.target.copy(equipmentItem.modelGroup.position);
      camera.updateProjectionMatrix();
      this.controls.perspective.update();
    },

    switchEquimentItemModeView(equipmentItem) {
      this.detachFlatDragControlsFromEquipmentItemParts(equipmentItem);
      for (const equipmentItemPartName in equipmentItem.parts) {
        const equipmentItemPart = equipmentItem.parts[equipmentItemPartName];
        for (const equipmentItemDetailMesh of equipmentItemPart.detailMeshes) {
          equipmentItem.modelGroup.attach(equipmentItemDetailMesh);
        }
      }
      this.attachFlatDragControlsToEquipmentItem(equipmentItem);
    },

    switchEquimentItemModeEdit(equipmentItem) {
      for (const equipmentItemName in this.equipmentItemsMeta) {
        const equipmentItemTest = this.equipmentItemsMeta[equipmentItemName];
        if (equipmentItemTest != equipmentItem) {
          this.switchEquimentItemModeView(equipmentItemTest, false);
          equipmentItemTest.edit = false;
        }
      }
      this.detachFlatDragControlsFromEquipmentItem(equipmentItem);
      this.attachFlatDragControlsToEquipmentItemParts(equipmentItem);
      this.centerCameraOnEquipmentItem(equipmentItem);
    },

    switchEquimentItemMode(equipmentItem) {
      if (equipmentItem.edit) {
        this.switchEquimentItemModeEdit(equipmentItem);
      } else {
        this.switchEquimentItemModeView(equipmentItem);
      }
      // this.settleControls();
    },

    generateUniqueOrderItemCode(equipmentItem) {
      let orderItemCodeIndex = 1;
      let orderItemCode = equipmentItem.code + " " + orderItemCodeIndex;
      let orderItemCodeUnique = false;
      while (!orderItemCodeUnique) {
        orderItemCodeUnique = true;
        for (const testOrderItem of this.orderItems) {
          if (testOrderItem.code === orderItemCode) {
            orderItemCodeUnique = false;
            break;
          }
        }
        if (!orderItemCodeUnique) {
          orderItemCodeIndex++;
          orderItemCode = equipmentItem.code + " " + orderItemCodeIndex;
        }
      }
      return orderItemCode;
    },

    initOrderItem(orderItem) {
      orderItem.meta = {
        model: null,
        label: null,
        modelGroup: new THREE.Group(),
        controls: {
          flat: null,
          perspective: null,
        },
        materials: {
          cap: null,
          clamp: null,
          bar: null,
        },
        edit: false,
        safeZoneMarker: null,
        currentPart: null,
      };
      orderItem.meta.modelGroup.userData.orderItem = orderItem;
      orderItem.parts = [];
    },

    addOrderItemModelToScene(orderItem) {
      orderItem.meta.modelGroup.position.x = orderItem.position_x;
      orderItem.meta.modelGroup.position.y = orderItem.position_y;
      orderItem.meta.modelGroup.position.z = orderItem.position_z;
      orderItem.meta.modelGroup.rotation.x = orderItem.rotation_x;
      orderItem.meta.modelGroup.rotation.y = orderItem.rotation_y;
      orderItem.meta.modelGroup.rotation.z = orderItem.rotation_z;

      orderItem.meta.modelGroup.traverse((obj) => {
        if (obj.isMesh) {
          obj.castShadow = true;
          obj.receiveShadow = true;
        }
      });
      this.scene.add(orderItem.meta.modelGroup);
      // const saveThis = this;
      // setTimeout(() => {
      //   saveThis.checkIntersections();
      // }, 100);
    },

    updateOrderItemLabelPosition(orderItem) {
      const boundingBox = new THREE.Box3().setFromObject(
        orderItem.meta.modelGroup
      );
      orderItem.meta.label.position.set(
        boundingBox.min.x,
        boundingBox.max.y,
        boundingBox.min.z
      );
    },

    addOrderItemLabelToScene(orderItem) {
      this.updateOrderItemLabelPosition(orderItem);
      this.scene.add(orderItem.meta.label);
    },

    meshExistsInGlobalCollection(mesh) {
      for (const detailMeshIndex in this.detailMeshes) {
        const detailMesh = this.detailMeshes[detailMeshIndex];
        if (detailMesh.uuid === mesh.uuid) {
          return detailMeshIndex;
        }
      }
      return -1;
    },

    addOrderItemMeshesToGlobalMeshCollection(orderItem) {
      for (const detailMesh of orderItem.meta.model.children) {
        if (this.meshExistsInGlobalCollection(detailMesh) === -1) {
          this.detailMeshes.push(detailMesh);
        }
      }
    },

    removeOrderItemMeshesFromGlobalMeshCollection(orderItem) {
      for (const detailMesh of orderItem.meta.model.children) {
        const detailMeshIndex = this.meshExistsInGlobalCollection(detailMesh);
        if (detailMeshIndex !== -1) {
          this.detailMeshes.splice(detailMeshIndex, 1);
        }
      }
    },

    addOrderItemPartMeshesToGlobalMeshCollection(orderItemPart) {
      for (const detailMesh of orderItemPart.detailMeshes) {
        if (this.meshExistsInGlobalCollection(detailMesh) === -1) {
          this.detailMeshes.push(detailMesh);
        }
      }
    },

    removeOrderItemPartMeshesFromGlobalMeshCollection(orderItemPart) {
      for (const detailMesh of orderItemPart.detailMeshes) {
        const detailMeshIndex = this.meshExistsInGlobalCollection(detailMesh);
        if (detailMeshIndex !== -1) {
          this.detailMeshes.splice(detailMeshIndex, 1);
        }
      }
    },

    addMeshToGlobalMeshCollection(mesh) {
      if (this.meshExistsInGlobalCollection(mesh) === -1) {
        this.detailMeshes.push(mesh);
      }
    },

    removeMeshFromGlobalMeshCollection(mesh) {
      const detailMeshIndex = this.meshExistsInGlobalCollection(mesh);
      if (detailMeshIndex !== -1) {
        this.detailMeshes.splice(detailMeshIndex, 1);
      }
    },

    loadOrderItemParts(orderItem) {
      orderItemsService.getParts(orderItem.id).then((parts) => {
        this.initOrderItemParts(orderItem, parts);
      });
    },

    addOrderItemToScene(orderItem) {

      orderItem.meta.modelGroup.add(orderItem.meta.model);
      // this.addBoxHelperToOrderItem(orderItem);
      this.addOrderItemModelToScene(orderItem);
      // this.addOrderItemLabelToScene(orderItem);
      this.loadOrderItemParts(orderItem);
      this.addOrderItemMeshesToGlobalMeshCollection(orderItem);
      // this.settleControls();
    },

    getOrderMountType() {
      let mountTypeId = 1;
      if (this.order.mount_type) {
        mountTypeId = this.order.mount_type;
      }
      return getDataObjectById(this.mountTypes, mountTypeId);
    },

    changeOrderMountType(mountType) {
      let mountTypeId = 1;
      if (mountTypeId) {
        mountTypeId = mountType.id;
      }
      if (this.order.mount_type != mountTypeId) {
        this.order.mount_type = mountTypeId;
        this.requestOrderUpdate();
      }
    },

    getOrderSputtering() {
      let sputteringId = 1;
      if (this.order.sputtering) {
        sputteringId = this.order.sputtering;
      }
      return getDataObjectById(this.sputterings, sputteringId);
    },

    applyZincColors() {
      const zincColor = this.getColorByRALCode("RAL 9003");
      const blackColor = this.getColorByRALCode("RAL 9005");
      this.order.bar_color = blackColor.id;
      this.order.pillar_color = zincColor.id;
      this.order.cap_color = blackColor.id;
      this.order.clamp_color = blackColor.id;
      this.materials.bar.color.setHex(this.colorToHex(blackColor));
      this.materials.cap.color.setHex(this.colorToHex(blackColor));
      this.materials.clamp.color.setHex(this.colorToHex(blackColor));
      this.materials.pillar.color.setHex(this.colorToHex(zincColor));
    },

    applyDefaultColors() {
      const mainColor = this.getColorByRALCode("RAL 7016");
      const clampColor = this.getColorByRALCode("RAL 5018");
      this.order.bar_color = mainColor.id;
      this.order.pillar_color = mainColor.id;
      this.order.cap_color = mainColor.id;
      this.order.clamp_color = clampColor.id;
      this.materials.bar.color.setHex(this.colorToHex(mainColor));
      this.materials.cap.color.setHex(this.colorToHex(mainColor));
      this.materials.clamp.color.setHex(this.colorToHex(clampColor));
      this.materials.pillar.color.setHex(this.colorToHex(mainColor));
    },

    changeOrderSputtering(sputtering) {
      let sputteringId = 1;
      if (sputteringId) {
        sputteringId = sputtering.id;
      }
      if (this.order.sputtering != sputteringId) {
        const currentSputtering = this.getOrderSputtering();
        if (currentSputtering.code === "zinc") {
          this.applyDefaultColors();
        }
        this.order.sputtering = sputteringId;
        if (sputtering.code === "zinc") {
          this.applyZincColors();
        }
        this.requestOrderUpdate();
      }
    },

    getOrderCovering() {
      let coveringId = 3;
      if (this.order.platform_covering) {
        coveringId = this.order.platform_covering;
      }
      return getDataObjectById(this.coverings, coveringId);
    },

    changeOrderCovering(covering) {
      let coveringId = 3;
      if (coveringId) {
        coveringId = covering.id;
      }
      if (this.order.platform_covering != coveringId) {
        this.order.platform_covering = coveringId;
        this.requestOrderUpdate();
      }
    },

    getOrderFoundation() {
      let foundationId = 1;
      if (this.order.foundation) {
        foundationId = this.order.foundation;
      }
      return getDataObjectById(this.foundations, foundationId);
    },

    changeOrderFoundation(foundation) {
      let foundationId = 1;
      if (foundationId) {
        foundationId = foundation.id;
      }
      if (this.order.foundation != foundationId) {
        this.order.foundation = foundationId;
        this.requestOrderUpdate();
      }
    },

    getOrderColor(tag) {
      const colorPropertyName = `${tag}_color`;
      return this.getColorById(this.order[colorPropertyName]);
    },

    getOrderBarColor() {
      return this.getOrderColor("bar");
    },

    getOrderClampColor() {
      return this.getOrderColor("clamp");
    },

    getOrderBarColorRALCode() {
      const color = this.getOrderBarColor();
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    getOrderCapColorRALCode() {
      const color = this.getOrderItemCapColor();
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    getOrderClampColorRALCode() {
      const color = this.getOrderClampColor();
      if (color) {
        return color.ral_code;
      }
      return null;
    },

    colorToHex(color) {
      return parseInt(color.hex_code, 16);
    },

    changeOrderColor(tag, color) {
      this.order[`${tag}_color`] = color.id;
      this.palette[tag].setHex(this.colorToHex(this.getColorById(color.id)));
      this.materials[tag].color = this.palette[tag];
    },

    changeOrderBarColor(color) {
      if (!color) {
        color = this.getColorByRALCode("RAL 7016");
      }
      this.changeOrderColor("bar", color);
      this.changeOrderColor("pillar", color);
      this.changeOrderColor("cap", color);
      this.requestOrderUpdate();
    },

    changeOrderClampColor(color) {
      if (!color) {
        color = this.getColorByRALCode("RAL 5018");
      }
      this.changeOrderColor("clamp", color);
      this.requestOrderUpdate();
    },

    getOrderItemById(orderItemId) {
      if (this.orderItems && this.orderItems.length) {
        this.orderItems.forEach((orderItem) => {
          if (orderItem.id === orderItemId) {
            return orderItem;
          }
        });
      }
      return null;
    },

    updateOrderItem(orderItem) {
      orderItem.position_x = orderItem.meta.modelGroup.position.x;
      orderItem.position_y = orderItem.meta.modelGroup.position.y;
      orderItem.position_z = orderItem.meta.modelGroup.position.z;
      orderItem.rotation_x = orderItem.meta.modelGroup.rotation.x;
      orderItem.rotation_y = orderItem.meta.modelGroup.rotation.y;
      orderItem.rotation_z = orderItem.meta.modelGroup.rotation.z;
      const payload = {
        orderId: this.$route.params.id,
        orderItem: orderItem,
      };
      this["orderItems/update"](payload);
    },

    updateOrderItemPart(orderItemPart) {
      if (!orderItemPart.visible) {
        orderItemsService.deletePart(
          orderItemPart.orderItem.id,
          orderItemPart.key
        );
      } else {
        orderItemsService.addOrUpdatePart(
          orderItemPart.orderItem.id,
          orderItemPart.key,
          {
            position_x: orderItemPart.meshGroup.position.x,
            position_y: orderItemPart.meshGroup.position.y,
            position_z: orderItemPart.meshGroup.position.z,
            rotation_x: orderItemPart.meshGroup.rotation.x,
            rotation_y: orderItemPart.meshGroup.rotation.y,
            rotation_z: orderItemPart.meshGroup.rotation.z,
            active: orderItemPart.visible,
          }
        );
      }
    },

    updateOrderPlatform(platformMeta) {
      if (this.orderPlatforms && this.orderPlatforms.length) {
        this.orderPlatforms.forEach((orderPlatform) => {
          if (platformMeta.orderPlatformId === orderPlatform.id) {
            const orderPlatformData = {
              dots: [],
              area: 0,
              id: orderPlatform.id,
            };
            for (const dot of platformMeta.dots) {
              orderPlatformData.dots.push({
                x: dot.x,
                y: dot.y,
              });
            }
            orderPlatformData.area = platformMeta.area;
            const payload = {
              orderId: this.$route.params.id,
              orderPlatform: orderPlatformData,
            };
            this["orderPlatforms/update"](payload);
          }
        });
      }
    },

    updateOrder() {
      this["orders/update"](this.order);
    },

    removeCurrentOrderItem() {
      if (this.currentOrderItem) {
        this.removeOrderItem(this.currentOrderItem);
        this.clearCurrentOrderItem();
      }
    },

    initOrderItems() {
      if (this.loadedFlags.orderItems) {
        return;
      }
      for (const orderItem of this.orderItems) {
        if (this.orderItemId && this.orderItemId !== orderItem.id) {
          continue;
        }
        this.initOrderItem(orderItem);
        this.downloadEquipmentItemModel(orderItem);
      }
      this.loadedFlags.orderItems = true;
    },

    updateOrderItems() {
      for (const newOrderItemId of this.newOrderItems) {
        for (const orderItem of this.orderItems) {
          if (orderItem.id === newOrderItemId) {
            this.initOrderItem(orderItem);
            this.downloadEquipmentItemModel(orderItem);
            break;
          }
        }
      }
      this.newOrderItems = [];
    },

    initOrderPlatforms() {
      this.orderPlatforms.forEach((orderPlatform) => {
        this.addPlatform(orderPlatform);
      });
    },

    show() {
      this.visible = true;
    },

    handleSnapshot() {
      this.visible = false;
      this.$nextTick(() => {
        // Рендерим в большом разрешении (соотношение 16:10)
        const width = this.webGLRenderer.domElement.offsetWidth
        const height = this.webGLRenderer.domElement.offsetHeight
        let renderWidth = width
        let renderHeight = height
        if (width > height) {
          renderWidth = 2400
          renderHeight = (renderWidth * height) / width
        } else {
          renderHeight = 1500
          renderWidth = (renderHeight * width) / height
        }
        this.webGLRenderer.setSize(renderWidth, renderHeight);
        this.webGLRenderer.render(this.scene, this.cameras.active);

        const dataUrl = this.webGLRenderer.domElement.toDataURL();
        this.$emit("complete", dataUrl);
      });
    },
  },

  mounted() {
    this.init();
    if (!this.orderItemId) {
      this.initOrderPlatforms();
    }
    this.initOrderItems();
    this.setOrderItemsEditMode();
    this.animate();
  },

  destroyed() {
    cancelAnimationFrame(this.animationFrameId);
  },
};
</script>

<style>
  .modal-xl {
    max-width: 95% !important;
  }
</style>
