<template>
  <div class="angle-controls">
    <CButton
        color="dark"
        size="sm"
        :disabled="!!intervalId"
        @click="handleRotate(-90)"
    >
      <CIcon name="cil-reload"/>
    </CButton>
    <vue-numeric-input
        :value="degValue"
        :step="5"
        align="center"
        width="100px"
        class="ml-2 mr-2"
        @input="handleInput"
    />

    <CButton
        color="dark"
        size="sm"
        :disabled="!!intervalId"
        @click="handleRotate(90)"
    >
      <CIcon name="cil-reload" class="icon-mirrored"/>
    </CButton>
  </div>
</template>

<script>
/**
 * Поле ввода и кнопки для удобства управления углом поворота
 * Отображает значение в градусах, преобразует в радианы
 * При нажатии на стрелочки угол меняется плавно
 */

const ANIM_DURATION = 300

export default {
  props: {
    value: Number
  },
  emits: ['input', 'change'],
  data() {
    return {
      intervalId: null
    }
  },
  computed: {
    degValue() {
      const degValue = Math.round(this.value / Math.PI * 180)
      // Приводим к диапазону 0..360°
      return (degValue + 360) % 360
    }
  },
  mounted() {
  },
  destroyed() {
    clearInterval(this.intervalId)
  },
  methods: {
    // Запускаем простую анимацию, чтобы привести текущий угол к целевому значению
    startAnimation(targetAngle) {
      const startTime = Date.now()
      const startAngle = this.degValue

      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        const phase = Math.min(1, (Date.now() - startTime) / ANIM_DURATION)
        if (phase === 1) {
          clearInterval(this.intervalId)
          this.intervalId = null
        }
        this.handleInput(startAngle + (targetAngle - startAngle) * phase)
      })
    },
    handleInput(value) {
      this.$emit('input', value / 180 * Math.PI)
    },
    handleRotate(change) {
      this.startAnimation(this.degValue + change)
    }
  }
}
</script>

<style lang="scss">
.angle-controls {
  > .btn {
    padding: 0.15rem 0.5rem;
    vertical-align: top;
  }

  .icon-mirrored {
    transform: scaleX(-1);
  }
}
</style>
